import { types } from 'mobx-state-tree';
import variables from 'styles/_variables.module.scss';

import { COLORS } from '../colors/Colors';

const defaultLoad = (transport, query, fieldInCircle, fieldOutCircle) => {
  return new Promise((resolve, reject) => {
    const onReject = (response, errors) => {
      reject(
        ApiLoadError.create({
          code: response.status,
          status: response.statusText,
          details: errors,
        })
      );
    };

    transport.get({
      url: `/i/api/v1/record/grouping`,
      query: {
        query: query,
        fields: [fieldInCircle, fieldOutCircle],
      },
      onSuccess: (response, responseData) => resolve({ response: response, responseData: responseData }),
      onFailure: onReject,
      onCrash: onReject,
    });
  });
};

const defaultProcessor = ({ responseData, labelModifier }) => {
  const inCircle = [];
  const outCircle = [];

  responseData.data.groups.forEach((el, index) => {
    inCircle.push({
      name: labelModifier(el.value),
      value: el.total,
      fill: COLORS[index],
    });
    el.groups.forEach((outEl) => {
      outCircle.push({
        name: outEl.value,
        value: outEl.total,
        fill: STATUS_TO_COLOR[outEl.value],
      });
    });
  });

  return PieData.create({
    inCircle: inCircle,
    outCircle: outCircle,
  });
};

export const ApiLoadError = types.model('TableLoadError', {
  code: types.integer,
  status: types.string,
  details: types.array(types.string),
});

const PieDataItem = types.model('PieDataItem', {
  name: types.string,
  value: types.integer,
  fill: types.string,
});

const PieData = types.model('PieData', {
  inCircle: types.array(PieDataItem),
  outCircle: types.array(PieDataItem),
});

const STATUS_TO_COLOR = {
  ok: variables.chartGreen,
  critical: variables.chartRed,
  warning: variables.chartOrange,
  unknown: variables.chartGray,
  deleted: variables.chartGray2,
};

export const PieModel = types
  .model('PieModel', {
    label: types.string,
    query: types.string,
    fieldInCircle: types.string,
    fieldOutCircle: types.string,
    error: types.optional(types.maybeNull(ApiLoadError), null),
    loading: types.optional(types.boolean, true),
    data: types.optional(PieData, {}),
  })
  .views((self) => ({
    get dataInCircle() {
      return self.data.inCircle.toJSON();
    },
    get dataOutCircle() {
      return self.data.outCircle.toJSON();
    },
  }))
  .volatile(() => ({
    loadData: defaultLoad,
    processor: defaultProcessor,
    labelModifier: (value) => value,
    transport: null,
  }))
  .actions((self) => ({
    setUp({ transport, processor, labelModifier }) {
      self.transport = transport;
      if (processor) {
        self.processor = processor;
      }
      if (labelModifier) {
        self.labelModifier = labelModifier;
      }
      self.load();
    },
    setLoading: (loading) => {
      self.loading = loading;
    },
    setError: (error) => {
      self.error = error;
    },
    setData: (data) => {
      self.data = data;
    },
    load: async () => {
      self.setLoading(true);
      try {
        self.setData(
          await self.processor({
            labelModifier: self.labelModifier,
            ...(await self.loadData(self.transport, self.query, self.fieldInCircle, self.fieldOutCircle)),
          })
        );
      } catch (e) {
        self.setError(e);
      } finally {
        self.setLoading(false);
      }
    },
  }));
