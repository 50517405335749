import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import * as queryString from 'lib/query-string';

import Spinner from 'components/Loader';
import Agreement from 'pages/Auth/components/Agreement';
import PageLayout from 'pages/Auth/components/PageLayout';
import { OAuth2CallbackForm } from 'stores/Auth';

const JustMessage = (props) => (
  <div className="form-box">
    <form>
      <div className="message-placeholder">{props.children}</div>
    </form>
  </div>
);

@withRouter
@inject('profile')
@observer
export default class OAuth2Callback extends React.Component {
  constructor(props) {
    super(props);

    const query = queryString.parse(this.props.location.search);
    let error = null;
    if (!this.props.match.params.provider || !query.code) {
      error = 'Something is wrong. Please contact ITLook administrator.';
    }
    this.form = OAuth2CallbackForm.create({
      provider: this.props.match.params.provider || null,
      code: query.code,
      redirectUrl: query.state,
      error: error,
    });
    this.form.setUpdateURLPath(this.props.history.push);
    this.form.registerSetToken(this.props.profile.setToken);
  }

  componentDidMount() {
    if (!this.form.error) {
      this.form.login();
    }
  }

  render() {
    if (this.form.agreement !== null) {
      return <Agreement store={this.form.agreement} />;
    }
    let item;

    if (this.form.error) {
      item = this.form.error;
    } else if (this.form.notRegisteredYet) {
      item = 'You do not have registered account at ITLook yet.';
    } else {
      item = <Spinner />;
    }

    return (
      <PageLayout title="OAuth2 callback">
        <JustMessage>{item}</JustMessage>
      </PageLayout>
    );
  }
}
