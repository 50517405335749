import React from 'react';

import Item from 'components/Item';
import PropTypes from 'prop-types';

export const ConfirmationAboutDeleteWindow = (props) => (
  <div className="modal">
    <div className="modal-box">
      <div className="modal-header">
        <Item>Are your sure?</Item>
        <Item className="btn-close" onClick={() => !props.deleteInProgress && props.onClose()} />
      </div>
      <div className="modal-body">
        <p>This operation is irreversible. Are you sure you want to continue?</p>
      </div>
      <div className="modal-footer">
        <button
          key="cancel"
          className="btn-default"
          type="button"
          onClick={props.onCancel}
          disabled={props.deleteInProgress}
        >
          Cancel
        </button>
        <button
          key="delete"
          className="btn-danger"
          type="button"
          onClick={props.onDelete}
          disabled={props.deleteInProgress}
        >
          Delete
        </button>
      </div>
    </div>
  </div>
);

ConfirmationAboutDeleteWindow.propTypes = {
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  deleteInProgress: PropTypes.bool,
};

ConfirmationAboutDeleteWindow.defaultProps = {
  deleteInProgress: false,
};
