import { getParentOfType, types } from 'mobx-state-tree';
import { UUID4 } from 'lib/uuid-utils';

import { NotificationTypes } from 'Constants';

const Notification = types
  .model('Notification', {
    id: types.string,
    messages: types.array(types.string),
    type: types.enumeration(Object.keys(NotificationTypes)),
  })
  .actions((self) => ({
    forget() {
      getParentOfType(self, Notifications).deleteItem(self.id);
    },
  }));

const Notifications = types
  .model('Notifications', {
    items: types.array(Notification),
  })
  .actions((self) => ({
    notify(messages, type) {
      if (!Array.isArray(messages)) {
        messages = [messages];
      }
      self.items.push({ messages: messages, type: type, id: UUID4() });
    },
    error(messages) {
      self.notify(messages, NotificationTypes.error);
    },
    success(messages) {
      self.notify(messages, NotificationTypes.success);
    },
    warn(messages) {
      self.notify(messages, NotificationTypes.warning);
    },
    info(messages) {
      self.notify(messages, NotificationTypes.info);
    },
    deleteItem(notificationID) {
      self.items = self.items.filter((n) => n.id !== notificationID);
    },
  }));

export default Notifications;
