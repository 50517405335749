import React from 'react';

import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import Checkbox from 'components/Checkbox';
import PageLayout from 'pages/Auth/components/PageLayout';

export const TermsAndConditionsCheckbox = (props) => {
  let termsAndConditionsTag = 'terms and conditions';
  if (props.withLink) {
    termsAndConditionsTag = (
      <Link to="/terms-and-coditions" target="_blank" rel="noopener noreferrer">
        {termsAndConditionsTag}
      </Link>
    );
  }
  const label = <>I have read and agree to {termsAndConditionsTag}.</>;

  return (
    <div className="form-box">
      <fieldset className="fieldset">
        <Checkbox
          label={label}
          checked={props.store.checkboxIsSelected}
          disabled={props.store.processing}
          onChange={props.store.toggleCheckBox}
        />
      </fieldset>
    </div>
  );
};

export default observer((props) => {
  return (
    <PageLayout title="Terms and Conditions agreement" createAccountLink={false}>
      <div className="license-container">{props.store.text}</div>
      <TermsAndConditionsCheckbox store={props.store} />
      <form>
        <div className="form-box-line">
          <div className="form-item">
            <div className="item-container">
              <button
                key="agree"
                type="button"
                className="btn btn-primary"
                onClick={props.store.onSubmit}
                disabled={!props.store.checkboxIsSelected || props.store.processing}
              >
                Submit
              </button>
            </div>
          </div>
          <div className="form-item">
            <div className="item-container">
              <button
                key="abort"
                type="button"
                className="btn btn-default"
                onClick={props.store.onCancel}
                disabled={props.store.processing}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </PageLayout>
  );
});
