import { types } from 'mobx-state-tree';

import { Terminal } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';
import { SearchAddon } from 'xterm-addon-search';
import { WebLinksAddon } from 'xterm-addon-web-links';

import UploaderInputS from 'stores/Terminal/UploaderInputS';

class ITLookTerminalAddon {
  constructor(appConnection) {
    this._appConnection = appConnection;
    this._disposables = [];
    this.socket = null;
  }

  async connect({ modelId, width, height }) {
    const socketName = 'terminal';

    this.socket = await this._appConnection.createSocket(socketName);
    await this.socket.send_socket_rpc('init', {
      id: modelId,
      window: { width: width, height: height },
    });
    this._disposables.push({
      dispose: () => this.socket.dispose(),
    });
  }

  sendData(data) {
    this.socket.send_stream(data);
  }

  sendBinary(data) {
    const buffer = new Uint8Array(data.length);
    for (let i = 0; i < data.length; ++i) {
      buffer[i] = data.charCodeAt(i) & 255;
    }
    this.sendData(buffer);
  }

  activate(terminal) {
    this._disposables.push(terminal.onData((data) => this.sendData(data)));
    this._disposables.push(terminal.onBinary((data) => this.sendBinary(data)));

    this.socket._onMessage = (message) => {
      terminal.write(message);
    };
  }

  // call this when term is disposed
  dispose() {
    this._disposables.forEach((d) => d.dispose());
    this._disposables.length = 0;
  }
}

export const XTermSession = types
  .model('XTermSession', {
    id: types.identifier,
    title: types.optional(types.maybeNull(types.string), null),
    applicationId: types.string,
    isActive: false,
    instance: types.string,
    hostname: types.string,
    accessUser: types.string,
    fileUploader: types.optional(UploaderInputS, () => UploaderInputS.create({})),
    modelId: types.optional(types.maybeNull(types.string), null),
  })
  .volatile(() => ({
    appConnection: null,
    term: null,
    addons: {},
    record: null,
    instanceObj: null,
  }))
  .actions((self) => ({
    setAppConnection(appConn) {
      self.appConnection = appConn;
    },
    setSocket(socket) {
      self.socket = socket;
    },
    setTerminal(term) {
      self.term = term;
    },
    setRecord(record) {
      self.record = record;
    },
    setInstance(instanceObj) {
      self.instanceObj = instanceObj;
    },
    setActive() {
      self.isActive = true;
    },
    getSearchAddon() {
      let searchAddon = self.addons.search;
      if (!searchAddon) {
        searchAddon = new SearchAddon();
        self.addons.search = searchAddon;
      }
      return searchAddon;
    },
    getFitAddon() {
      let fitAddon = self.addons.fit;
      if (!fitAddon) {
        fitAddon = new FitAddon();
        self.addons.fit = fitAddon;
      }
      return fitAddon;
    },
    getWebLinksAddon() {
      let linksAddon = self.addons.links;
      if (!linksAddon) {
        linksAddon = new WebLinksAddon();
        self.addons.links = linksAddon;
      }
      return linksAddon;
    },
    getAttachAddon() {
      let attachAddon = self.addons.attach;
      if (!attachAddon) {
        attachAddon = new ITLookTerminalAddon(self.appConnection);
        self.addons.attach = attachAddon;
      }
      return attachAddon;
    },
    getTerminalAddon() {
      return self.addons.attach;
    },
    autoResize() {
      const fitAddon = self.getFitAddon();
      const propDim = fitAddon.proposeDimensions();
      fitAddon.fit();
      self.term.resize(propDim.cols, propDim.rows - 2);
    },
    updateTitle(title) {
      self.title = title;
    },
    init(callback) {
      self.socket.afterOpen(async () => {
        let fitAddon = null;
        if (!self.isActive) {
          const searchAddon = self.getSearchAddon();
          fitAddon = self.getFitAddon();
          self.term.loadAddon(fitAddon);
          self.term.loadAddon(self.getWebLinksAddon());
          self.term.loadAddon(searchAddon);
        }
        const elementId = self.id;
        const elem = document.getElementById(elementId);

        // NOTE (e0ne): temporary workaround for xterm re-init issue to not show two terminals in the same container
        elem.innerHTML = '';

        self.term.open(elem);

        if (!self.isActive) {
          const propDim = fitAddon.proposeDimensions();
          fitAddon.fit();
          self.term.resize(propDim.cols, propDim.rows - 2);

          const attachAddon = self.getAttachAddon();
          await attachAddon.connect({
            modelId: self.modelId,
            width: propDim.cols,
            height: propDim.rows - 2,
          });
          self.term.loadAddon(attachAddon);
        }
        self.setActive();
        if (callback) {
          callback();
        }
      });
    },
  }));

export const XTermSessionsManager = types
  .model('XTermSessionsManager', {
    sessions: types.map(XTermSession),
    activeSessionId: types.maybeNull(types.string),
    showTerms: types.optional(types.boolean, false),
    toBeDeleted: types.maybeNull(types.string),
  })
  .volatile(() => ({
    socket: null,
  }))
  .views((self) => ({
    hasSession(sessionId) {
      return self.sessions.get(sessionId) !== undefined;
    },
    getActiveSession() {
      return self.sessions.get(self.activeSessionId);
    },
  }))
  .actions((self) => ({
    setSocket(socket) {
      self.socket = socket;
    },
    setActiveSession(sessionId) {
      self.activeSessionId = sessionId;
    },
    showTerminals() {
      self.showTerms = true;
    },
    hideTerminals() {
      self.showTerms = false;
    },
    setToBeDeleted(session) {
      self.toBeDeleted = session;
    },
    stopSession() {
      const id = self.toBeDeleted;
      const session = self.sessions.get(id);
      session.addons.attach.socket.dispose();
      self.sessions.delete(id);
      if (self.activeSessionId === id) {
        if (self.sessions.size !== 0) {
          const newActiveSessionId = Array.from(self.sessions.keys())[0];
          self.setActiveSession(newActiveSessionId);
        } else {
          self.setActiveSession(null);
          self.hideTerminals();
        }
      }
      self.setToBeDeleted(null);
    },
    ensureAppConnection(instance, app, sessionId, modelId, hostname, accessUser, record, instanceObj) {
      let xtermSession = self.sessions.get(sessionId);
      if (xtermSession === undefined) {
        const term = new Terminal({
          convertEol: true,
          cols: 120,
          rows: 30,
          scrollback: 100000,
          allowTransparency: true,
          fontWeight: 400,
          fontFamily: 'Roboto Mono,Martian Mono,Menlo,Monaco,Consolas,monospace',
          fontSize: 16,
          theme: {
            // black with transparency 100% , i.e. no color
            background: 'rgba(0, 0, 0, 0)',
          },
        });

        const appConnection = self.socket.ensureAppConnection(app);
        xtermSession = XTermSession.create({
          id: sessionId,
          modelId: modelId,
          applicationId: app,
          instance: instance,
          hostname: hostname,
          accessUser: accessUser,
        });
        xtermSession.setAppConnection(appConnection);
        xtermSession.setSocket(self.socket);
        xtermSession.setTerminal(term);
        xtermSession.setRecord(record);
        xtermSession.setInstance(instanceObj);
        self.sessions.set(sessionId, xtermSession);
        self.setActiveSession(sessionId);

        // Operating System Command (OSC) 0 is 'Set window title'
        term.parser.registerOscHandler(0, (data) => {
          xtermSession.updateTitle(data.slice(data.indexOf(' ') + 1));
        });
      }
      return xtermSession;
    },
  }));
