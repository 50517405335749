import React from 'react';
import { Redirect } from 'react-router-dom';

export const getLoginURL = (loginAction, includeRedirectURL) => {
  const query = new URLSearchParams({});
  if (loginAction) {
    query.set('action', loginAction.urlValue);
  }
  if (includeRedirectURL) {
    query.set('redirect_url', window.location.href);
  }
  return `/?${query.toString()}`;
};

const RedirectToLogin = () => <Redirect to={getLoginURL(undefined, true)} />;

export default RedirectToLogin;
