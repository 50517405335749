import React from 'react';
import { observer, inject } from 'mobx-react';

import Input from 'components/forms/Input';
import { ContainerBox, PageHeader } from 'components/Page';
import { PasswordChangeForm } from 'stores/Profile';
import { LeftPanel, SidedPanel } from 'components/layout/Panels';

@inject('profile')
@observer
class UserPassword extends React.Component {
  constructor(props) {
    super(props);
    this.form = PasswordChangeForm.create({});
  }

  render() {
    const user = this.props.profile.user;
    return (
      <div className="user-profile-container">
        <div className="header">
          <div className="avatar" />
          <div className="name">
            <span>{user.fullName}</span>
            <span>{user.role}</span>
          </div>
        </div>
        <form>
          <div className="form-box">
            <Input key="current" store={this.form.current} />
            <Input key="new-password1" store={this.form.newPassword1} />
            <Input key="new-password2" store={this.form.newPassword2} />

            <div className="form-item">
              <button type="button" className="btn btn-primary" disabled={!this.form.filled}>
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default inject('profile')(
  observer((props) => {
    const { profile } = props;
    const user = profile.user;
    if (user == null) {
      return '';
    }
    return (
      <ContainerBox>
        <PageHeader title="Profile" />
        <SidedPanel>
          <LeftPanel>
            <UserPassword />
          </LeftPanel>
        </SidedPanel>
      </ContainerBox>
    );
  })
);
