import React from 'react';

import {
  BarChart as BarChartRe,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import variables from 'styles/_variables.module.scss';

import { observer } from 'mobx-react';

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="tooltip">
        {payload.map((el, i) => {
          return (
            <p key={i} className="label">
              {el.payload.labels[el.name]} ({el.value})
            </p>
          );
        })}
      </div>
    );
  }

  return null;
};

export const BarChart = observer((props) => {
  return (
    <ResponsiveContainer width="100%" height={300} className="chart">
      <BarChartRe
        width={600}
        height={300}
        data={props.store.dataOverTime}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false} stroke={variables.contentSub} />
        <XAxis dataKey="date" stroke={variables.textSub} />
        <YAxis yAxisId="left" orientation="left" stroke={variables.chartBlue} />
        <YAxis yAxisId="right" orientation="right" stroke={variables.chartBread1} />
        <Legend
          iconType="circle"
          payload={[
            { value: 'API Requests', type: 'circle', id: 'ID01', color: variables.chartBlue },
            { value: 'Activity', type: 'circle', id: 'ID02', color: variables.chartBread1 },
          ]}
        />
        <Bar yAxisId="left" dataKey="left" stackId="left" fill={variables.chartBlue} maxBarSize={25} />
        <Bar yAxisId="right" dataKey="right" stackId="right" fill={variables.chartBread2} maxBarSize={25} />
        <Tooltip cursor={false} wrapperStyle={{ outline: 'none' }} content={<CustomTooltip />} />
      </BarChartRe>
    </ResponsiveContainer>
  );
});
