import React from 'react';
import { inject, observer } from 'mobx-react';

import Item from 'components/Item';

const TYPE_TO_CLASS_MAP = {
  error: 'ntf-danger',
  warning: 'ntf-warning',
  info: 'ntf-info',
  success: 'ntf-success',
};

const DEFAULT_EXPIRATION_TIME = 20 * 1000;

class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.timer = undefined;
  }

  componentDidMount = () => {
    this.initTimer();
  };

  initTimer = () => {
    if (!this.timer) {
      this.timer = setTimeout(this.onClose, DEFAULT_EXPIRATION_TIME);
    }
  };

  stopTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
  };

  onClose = () => {
    this.stopTimer();
    this.props.notification.forget();
  };

  render() {
    return (
      <div
        className={`notification ${TYPE_TO_CLASS_MAP[this.props.notification.type]} close`}
        onMouseOver={this.stopTimer}
        onFocus={this.stopTimer}
        onMouseOut={this.initTimer}
        onBlur={this.initTimer}
      >
        <Item key="messages">
          {this.props.notification.messages.map((msg, index) => (
            <p key={index}>{msg}</p>
          ))}
        </Item>
        <Item className="btn-close" onClick={this.onClose} key="close" />
      </div>
    );
  }
}

export default inject('store')(
  observer((props) => (
    <div className="float-message-placeholder">
      {props.store.Notifications.items.map((n) => (
        <Notification key={n.id} notification={n} />
      ))}
    </div>
  ))
);
