import React from 'react';
import { observer, inject } from 'mobx-react';

import TextEditor from 'components/input/TextEditor';

@inject('profile')
@inject('instance')
@observer
export default class extends React.Component {
  render() {
    return (
      <div className="form-box">
        <TextEditor label="Instance API token" value={this.props.instance.token} readOnly />
        <TextEditor label="Organization API token" value={this.props.profile.token} readOnly />
      </div>
    );
  }
}
