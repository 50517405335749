import { types } from 'mobx-state-tree';

import InputStore from 'components/forms/Input/InputS';

const findTags = (rawValue, enterIsPressed, separators = ' ,;') => {
  const foundTags = [];
  const trimmedValue = rawValue.trim();
  let newValue = trimmedValue;

  // prepare for parsing
  let startWindow = 0;
  let pointer = -1;
  let quoteStartedWithChar;
  let quotedTag = false;

  while (pointer < trimmedValue.length + 1) {
    let currentChar;
    if (pointer === trimmedValue.length) {
      currentChar = enterIsPressed || separators.includes(rawValue[rawValue.length - 1]) ? separators[0] : null;
    } else {
      currentChar = trimmedValue[pointer];
    }

    if (separators.includes(currentChar)) {
      if (!quoteStartedWithChar) {
        let maybeTag;
        if (quotedTag) {
          // cut quotes
          maybeTag = trimmedValue.slice(startWindow + 1, pointer - 1);
          quotedTag = false;
        } else {
          maybeTag = trimmedValue.slice(startWindow, pointer);
        }

        if (maybeTag) {
          foundTags.push(maybeTag);
        }

        startWindow = pointer + 1;
        newValue = trimmedValue.slice(startWindow);
      } else {
        // quote is not ended yet
      }
    } else if (currentChar === '"' || currentChar === "'") {
      if (!quoteStartedWithChar) {
        quoteStartedWithChar = currentChar;
      } else if (quoteStartedWithChar === currentChar) {
        quoteStartedWithChar = undefined;
        quotedTag = true;
      }
    }

    pointer += 1;
  }

  return [foundTags, newValue];
};

export default InputStore.named('TagsInputStore')
  .props({
    items: types.array(types.string),
    inputType: 'search',
    label: 'Tags',
    description: 'Use space, comma or semicolon char to separate different items.',
    placeholder: 'Add new tag',
    onChangeHandlingDelay: 0,
    doNotShowErrors: true,
    doNotShowDescription: true,
  })
  .volatile((self) => ({
    inputParser: findTags,
    onTagsChangeHandler: () => {},
    onChange: (value) => self.handleRawValueChange(value, false),
    onEnterPress: (value) => self.handleRawValueChange(value, true),
  }))
  .views((self) => ({
    isDone() {
      return !self.value && (!self.required || self.items.length > 0);
    },
  }))
  .actions((self) => ({
    setItems(items) {
      self.items = items;
      self.onTagsChangeHandler(self.items);
    },
    removeItem(tag) {
      self.setItems(self.items.filter((t) => t !== tag));
    },
    setInputParser(func) {
      self.inputParser = func;
    },
    registerOnTagsChangeHandler(func) {
      self.onTagsChangeHandler = func;
    },
    handleRawValueChange(value, enterIsPressed) {
      const [tagsFromValue, newInputValue] = self.inputParser(value, enterIsPressed);
      self.setValue(newInputValue);
      const tagsToAdd = tagsFromValue.filter((tag) => self.items.indexOf(tag) < 0);
      if (tagsToAdd.length > 0) {
        self.setItems(self.items.concat(tagsToAdd));
      }
      if (enterIsPressed && newInputValue) {
        self.setError('Invalid tag');
      }
    },
    handleOnFocusOut(e) {
      self.handleRawValueChange(self.inputRef.current.value, true);
      self.focused = false;
      self.onFocusOut(e);
    },
  }));
