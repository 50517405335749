import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import * as ContentHeader from 'components/ContentHeader';
import { ConfirmationAboutDeleteWindow } from 'components/ModalWindow';
import Icon from 'components/Icon';
import PropTypes from 'prop-types';

const ManagementColumn = inject('notifications')(
  observer((props) => {
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteInProgress, setDeleting] = useState(false);

    const history = useHistory();

    const deleteRecord = () => {
      setDeleting(true);
      const errorOrPromise = props.deleteHandler(props.record.id);
      Promise.resolve(errorOrPromise).then((error) => {
        if (!error) {
          props.notifications.success(`Record ${props.record.id} is deleted.`);
          // small delay to make sure backend finishes updating caches
          setTimeout(() => history.push(props.redirectURLAfterDelete), 500);
        } else {
          props.notifications.error(`Failed to delete record: ${error}`);
        }
      });
    };

    return (
      <ContentHeader.Column>
        <ContentHeader.Item>
          <div className="actions-box">
            {props.deleteHandler && (
              <Icon key="delete" className="delete" onClick={() => setShowDeleteConfirmation(true)} />
            )}
            {showDeleteConfirmation && (
              <ConfirmationAboutDeleteWindow
                onClose={() => setShowDeleteConfirmation(false)}
                onCancel={() => setShowDeleteConfirmation(false)}
                onDelete={deleteRecord}
                deleteInProgress={deleteInProgress}
              />
            )}
          </div>
        </ContentHeader.Item>
      </ContentHeader.Column>
    );
  })
);

ManagementColumn.propTypes = {
  record: PropTypes.any.isRequired,
  deleteHandler: PropTypes.func.isRequired,
  redirectURLAfterDelete: PropTypes.string,
};

ManagementColumn.defaultProps = {
  redirectURLAfterDelete: '/',
};

export default ManagementColumn;

// TODO: delete this once all delete actions will be moved to a header
export const ManagementColumnThrowApp = inject('instance')(
  observer((props) => {
    const deleteRecord = () => {
      const socket = props.instance.getSocket();
      socket.afterOpen(async () => {
        const app = socket.ensureAppConnection(props.record.root_1.app);
        const result = await app.rpc(props.deleteThrowAppCommand, { id: props.record.id });
        return result.ok ? null : result.error;
      });
    };

    return <ManagementColumn record={props.record} deleteHandler={deleteRecord} />;
  })
);

ManagementColumnThrowApp.propTypes = {
  record: PropTypes.any.isRequired,
  deleteThrowAppCommand: PropTypes.string,
  redirectURLAfterDelete: PropTypes.string,
};

ManagementColumnThrowApp.defaultProps = {
  redirectURLAfterDelete: '/',
};
