import React from 'react';
import { observer } from 'mobx-react';

import BaseInput from 'components/forms/Input/Input';
import Icon from 'components/Icon';

export default observer((props) => (
  <BaseInput store={props.store}>
    <button type="button" className="btn btn-link btn-copy">
      <Icon className={`eye-${props.store.showPassword ? 'off' : 'on'}`} onClick={props.store.toggleShowPassword} />
    </button>
    {props.children}
  </BaseInput>
));
