import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { FormItemForInput } from 'components/forms/FormItem';

import { UUID4 } from 'lib/uuid-utils';

const defaultPlaceHolder = '-- select an option --';

const DropdownSelector = (props) => {
  // special ID to associate <label /> with <select />
  const someId = UUID4();

  const selectRef = useRef();

  let options;
  if (!Array.isArray(props.options)) {
    options = Object.entries(props.options);
  } else {
    options = props.options;
  }

  let placeholder;
  if (props.placeholder) {
    placeholder = props.placeholder;
  } else if (props.placeholder !== false && options.length > 1) {
    placeholder = defaultPlaceHolder;
  }

  let defaultValue;
  if (props.selected === undefined || props.selected === null) {
    // placeholder uses this value
    defaultValue = someId;
  } else {
    defaultValue = props.selected;
  }

  useEffect(() => {
    selectRef.current.value = defaultValue;
  }, [defaultValue]);

  return (
    <FormItemForInput
      onClick={(e) => !props.disabled && e.stopPropagation()}
      label={props.label}
      error={props.error}
      description={props.description}
      itemID={someId}
    >
      <select
        id={someId}
        name={props.name}
        onChange={(e) => props.onSelect(e.target.value)}
        defaultValue={defaultValue}
        disabled={props.disabled}
        ref={selectRef}
      >
        {placeholder !== undefined && (
          <option key="placeholder" disabled value={someId}>
            {placeholder}
          </option>
        )}
        {options.map(([value, title]) => (
          <option key={value} value={value}>
            {title}
          </option>
        ))}
      </select>
    </FormItemForInput>
  );
};

const StringOrNumber = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

DropdownSelector.propTypes = {
  // the label of Dropdown selector
  label: PropTypes.string,
  // the name of Dropdown selector
  name: PropTypes.string,
  // value of selected item
  selected: StringOrNumber,
  // whether dropdown is enabled or disabled
  disabled: PropTypes.bool,
  // placeholder that will be displayed if nothing is selected.
  // if it is boolean false, default placeholder will not be added
  placeholder: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  // available options. key is data, value is title
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.arrayOf(StringOrNumber)),
    PropTypes.objectOf(StringOrNumber),
  ]).isRequired,
  // description that is places after dropdown
  description: PropTypes.string,
  // an error message
  error: PropTypes.string,
  // events handlers
  onSelect: PropTypes.func.isRequired,
};

export default DropdownSelector;
