import 'regenerator-runtime/runtime';

export class ITLookWebSocket {
  constructor(connection_uri, onOpen) {
    this._socket = new WebSocket(connection_uri);
    this._socket.binaryType = 'arraybuffer';
    this._apps = {};
    this._refreshInt = null;
    this._onOpenHandlers = [];

    this._socket.addEventListener('message', (ev) => this.onMessage(ev));
    this._socket.addEventListener('open', () => {
      if (onOpen) {
        this.afterOpen(onOpen);
      }
      this.onOpen();
    });
    this._socket.addEventListener('close', (e) => this.onClose(e));
    this._socket.addEventListener('error', (e) => this.onError(e));
  }

  onMessage(event) {
    // const data: ArrayBuffer | string = ev.data;
    // terminal.write(typeof data === 'string' ? data : new Uint8Array(data));
    if (event.data === 'pong') {
      return;
    }

    let data = '';
    try {
      data = JSON.parse(event.data);
    } catch {
      console.log(`Cant't handle message ${event.data}`);
      return;
    }

    if (data.from && this._apps[data.from]) {
      // handle here errors that data.from is not in _apps
      this._apps[data.from].onMessage(data);
    } else {
      // means that it is traffic between user and backend
    }
  }

  onOpen() {
    this._refreshInt = setInterval(() => this.send('ping'), 10000);
    this._onOpenHandlers.forEach((callback) => callback());
  }

  afterOpen(callback) {
    // this._onOpenHandlers.push(callback);
    // Socket has been created. The connection is not yet open
    if (this._socket.readyState === 0) {
      this._onOpenHandlers.push(callback);
    }
    // The connection is open and ready to communicate
    else if (this._socket.readyState === 1) {
      callback();
    } else {
      console.log('Error: socket is about to been closed');
      throw new Error('Socket is about to been closed');
    }
  }

  onClose() {
    if (this._refreshInt !== null) {
      clearInterval(this._refreshInt);
    }
    Object.values(this._apps).forEach((app) => app.dispose());
  }

  onError(event) {
    // handle errors better
    console.log(`error in socket ${event}`);
  }

  send(data) {
    // just sends data as is to socket
    this._socket.send(data);
  }

  ensureAppConnection(targetAppUUID) {
    if (Object.keys(this._apps).indexOf(targetAppUUID) === -1) {
      this._apps[targetAppUUID] = new AppConnection(this, targetAppUUID);
    }
    return this._apps[targetAppUUID];
  }

  async closeAppConnection(appUUID) {
    await this._apps[appUUID].dispose();
    delete this._apps[appUUID];
  }
}

export class AppConnection {
  constructor(webSocket, targetAppUUID) {
    this._socket = webSocket;
    this._appUUID = targetAppUUID;
    this._requestsToHandler = {};
    this._sockets = {};

    this._establishConnection();
  }

  _establishConnection() {
    // create secure end-to-end encrypted connection
    // for now do nothing
  }

  _encrypt(data) {
    // in future we will encrypt data before sending it
    // until than we will just do json serialize
    return JSON.stringify(data);
  }

  _decrypt(data) {
    // in future we will encrypt data before sending it
    // until than we will just do json deserialization
    return JSON.parse(data);
  }

  async send_no_wait(payload) {
    this._socket.send(
      JSON.stringify({
        to: this._appUUID,
        payload: this._encrypt(payload),
      })
    );
  }

  async send(reqId, payload) {
    const promise = new Promise((resolutionFunc) => {
      this._requestsToHandler[reqId] = resolutionFunc;
    });

    this._socket.send(
      JSON.stringify({
        to: this._appUUID,
        payload: this._encrypt(payload),
      })
    );

    return promise;
  }

  async rpc(cmd, args) {
    const reqId = crypto.randomUUID();

    args = args || {};
    return this.send(reqId, {
      rpc: {
        request_id: reqId,
        request: {
          cmd,
          args,
        },
      },
    });
  }

  onMessage(message) {
    const payload = this._decrypt(message.payload);

    if (payload.rpc) {
      this._requestsToHandler[payload.rpc.request_id](payload.rpc.response);
    } else if (payload.socket_stream) {
      const socket = this._sockets[payload.socket_stream.socket_id];
      socket.onMessage(payload.socket_stream.data);
    } else if (payload.socket_rpc) {
      this._requestsToHandler[payload.socket_rpc.request_id](payload.socket_rpc.response);
    } else if (payload.error) {
      // request handler should be able to handle error
      this._requestsToHandler[payload.error.request_id](payload.error);
    } else {
      console.log(`received non supported payload ${JSON.stringify(message, null, 2)}`);
    }
  }

  async createSocket(socketType, onMessage) {
    const socketId = (await this.rpc('socket.open', { type: socketType, timeout: 600 })).opened;
    this._sockets[socketId] = new AppSocket(this, socketId, onMessage);
    return this._sockets[socketId];
  }

  dispose() {
    Object.values(this._sockets).forEach((el) => el.dispose());
    this._sockets.length = 0;
  }
}

export class AppSocket {
  /* Handled multipart commands that require context in between calls

    For example: terminal, uploading files, ...
    */
  constructor(appConnection, socketId, onMessage) {
    this._connection = appConnection;
    this._socketId = socketId;
    this._onMessage = onMessage || (() => {});
  }

  async send_stream(data) {
    return this._connection.send_no_wait({
      socket_stream: {
        socket_id: this._socketId,
        data: data,
      },
    });
  }

  async send_socket_rpc(cmd, args) {
    const reqId = crypto.randomUUID();
    return this._connection.send(reqId, {
      socket_rpc: {
        request_id: reqId,
        socket_id: this._socketId,
        request: {
          cmd: cmd,
          args: args || {},
        },
      },
    });
  }

  onMessage(message) {
    this._onMessage(message);
  }

  async dispose() {
    await this._connection.rpc('socket.close', { socket_id: this._socketId });
  }
}
