import React from 'react';
import { observer, inject } from 'mobx-react';
import { getRoot } from 'mobx-state-tree';
import { withRouter } from 'react-router-dom';

import FormItem, { FormItemForInput } from 'components/forms/FormItem';
import Input from 'components/forms/Input';
import DropdownSelector from 'components/input/DropdownSelector';
import TextEditor from 'components/input/TextEditor';
import { ContainerBox, PageHeader } from 'components/Page';
import { SidedPanel, LeftPanel } from 'components/layout/Panels';

import NewScriptStore from 'stores/Instances/ScriptNew';
import * as queryString from 'lib/query-string';

const CreateButton = inject('notifications')(
  observer((props) => {
    return (
      <div className="form-box">
        <FormItem>
          <button
            type="button"
            className="btn btn-success"
            disabled={!props.store.isReady()}
            onClick={(e) => props.store.onSubmit(e, props.onSuccess)}
          >
            Create
          </button>
        </FormItem>
      </div>
    );
  })
);

@withRouter
@inject('instance')
@inject('store')
@observer
export default class extends React.Component {
  DROPDOWN_OPTIONS_FOR_SYNTAX = TextEditor.supportedSyntaxes.map((syntax) => [syntax.name, syntax.title]);

  constructor(props) {
    super(props);
    this.store = NewScriptStore.create({});
    this.store.linkInstanceStore(this.props.instance);
  }

  componentDidMount() {
    // it is needed to enable create button
    if (!this.props.instance.Applications.loading) {
      this.props.instance.Applications.fetch();
    }

    const query = queryString.parse(this.props.location.search);
    if (query.rootDir) {
      this.store.name.setValue(query.rootDir);
    }
  }

  render() {
    const props = this.props;
    const onSuccess = (scriptId, scriptPath) => {
      getRoot(props.store).Notifications.success(`Script ${scriptPath} is created.`);
      props.history.push(`/i/${props.instance.name}/records/${scriptId}`);
    };

    return (
      <ContainerBox>
        <PageHeader to="/scripts" title="Scripts" subTitle="Create new" documentTitle="Scripts" instanceLink />

        <SidedPanel>
          <LeftPanel>
            <form>
              <div className="form-box">
                <Input key="name" store={this.store.name} />
              </div>
              <div className="form-box form-box-line-stretch">
                <Input key="command" store={this.store.command} />
                <DropdownSelector
                  key="syntax"
                  label="Syntax"
                  options={this.DROPDOWN_OPTIONS_FOR_SYNTAX}
                  onSelect={(syntax) => this.store.setSyntax(syntax)}
                  selected={this.store.syntax}
                />
              </div>
              <div className="form-box">
                <Input key="labels" store={this.store.labels} />
              </div>
              <div className="form-box">
                <Input key="description" store={this.store.description} />
              </div>
            </form>
            <form>
              <div className="form-box">
                <FormItemForInput error={this.store.globalError || ''} />
              </div>
            </form>
            <form>
              <div className="form-box">
                <TextEditor
                  label="Code"
                  value={this.store.code}
                  onChange={this.store.setCode}
                  syntax={this.store.syntax}
                  readOnly={this.store.creating}
                  displayLineNumbers
                />
              </div>
            </form>
            <CreateButton store={this.store} onSuccess={onSuccess} />
          </LeftPanel>
        </SidedPanel>
      </ContainerBox>
    );
  }
}
