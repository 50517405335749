import { types } from 'mobx-state-tree';

import * as queryString from 'lib/query-string';
import { UserRoles, UserStates } from 'Constants';
import { EmailInputStore, PasswordInputStore } from 'components/forms/Input';

export default types
  .model('UsersPage', {
    filters: types.optional(types.map(types.boolean), { active: true, suspended: true, invited: true }),
    sortBy: 'fullName',
    sortBack: false,
    query: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
  })
  .actions((self) => ({
    loadFromQuery(rawQueryString) {
      const query = queryString.parse(rawQueryString);
      if (query.search) {
        self.query = query.search;
      }
      if (query.sort_by) {
        self.sort_by = query.sort_by;
      }
      if (query.sort_back === 'true') {
        self.sortBack = true;
      }
      self.email = query.selected || null;
      ['active', 'invited', 'suspended'].forEach((f) => {
        if (query[f] === 'false') {
          self.filters.set(f, false);
        }
      });
    },
    toggleFilter(filter) {
      self.filters.set(filter, !self.filters.get(filter));
    },
    onSortChange(value, sortBack) {
      self.sortBy = value;
      self.sortBack = sortBack;
    },
    search(query) {
      if (query === '') {
        self.query = null;
      } else {
        self.query = query;
      }
    },
    setUserToShow(email) {
      if (self.email === email) {
        self.email = null;
      } else {
        self.email = email;
      }
    },
  }))
  .views((self) => ({
    get showActive() {
      return self.filters.get('active');
    },
    get showSuspended() {
      return self.filters.get('suspended');
    },
    get showInvited() {
      return self.filters.get('invited');
    },
    get usersListURL() {
      const query = Object.fromEntries(self.filters);
      query.sort_back = self.sortBack;
      query.sort_by = self.sortBy;
      if (self.query !== null) {
        query.q = self.query;
      }
      if (self.email !== null) {
        query.selected = self.email;
      }
      return `/organization/users?${queryString.stringify(query)}`;
    },
  }));

export const UserCreateForm = types
  .model('UserCreateForm', {
    email: types.optional(EmailInputStore, () => EmailInputStore.create()),
    password: types.optional(PasswordInputStore, () => PasswordInputStore.create({ schema: {} })),
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    role: UserRoles.USER,
    errors: types.array(types.string),
    // the process of creating an user
    creating: false,
  })
  .volatile(() => ({
    usersStore: null,
  }))
  .actions((self) => ({
    linkUsersStore(store) {
      self.usersStore = store;
    },
    setFirstName(name) {
      self.firstName = name;
    },
    setLastName(name) {
      self.lastName = name;
    },
    setRole(role) {
      self.role = role;
    },
    setCreatingFlag(value) {
      self.creating = value;
      self.email.setDisabled(true);
      self.password.setDisabled(true);
    },
    setCreateError(errors) {
      self.errors = errors;
      self.creating = false;
    },
    createNewUser(onSuccess) {
      self.setCreatingFlag(true);

      self.usersStore.createNew(
        self.email.value,
        self.firstName,
        self.lastName,
        self.password.value,
        self.role,
        onSuccess,
        (resp, errors) => {
          self.setCreatingFlag(false);
          self.setCreateError(errors);
        }
      );
    },
    validateEmailExistenceInTheSystem(value) {
      if (!value) {
        return false;
      }

      const newPromise = new Promise((resolve) => {
        const onFetchComplete = (user) => {
          if (user !== undefined) {
            if (user.state === UserStates.INVITED) {
              resolve(
                'The invitation to user with such email has been sent. If you want ' +
                  'to create a record manually instead, you should revoke invitation first.'
              );
            } else {
              resolve('The user with such email already exists.');
            }
          }
          resolve(false);
        };

        self.usersStore.getFromAPI(value, onFetchComplete);
      });

      return newPromise;
    },
    afterCreate() {
      self.email.addInputValidator(self.validateEmailExistenceInTheSystem);
    },
  }))
  .views((self) => ({
    get filled() {
      return self.email.isDone() && self.password.isDone() && self.firstName !== null && self.lastName !== null;
    },
  }));

export const UserInviteForm = types
  .model('UserInviteForm', {
    email: types.optional(EmailInputStore, () => EmailInputStore.create()),
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    role: UserRoles.USER,
    errors: types.array(types.string),
    // the process of creating an user
    requesting: false,
  })
  .volatile(() => ({
    usersStore: null,
  }))
  .actions((self) => ({
    linkUsersStore(store) {
      self.usersStore = store;
    },
    setFirstName(name) {
      self.firstName = name;
    },
    setLastName(name) {
      self.lastName = name;
    },
    setRole(role) {
      self.role = role;
    },
    setRequestingFlag(value) {
      self.requesting = value;
      self.email.setDisabled(value);
    },
    setRequestError(errors) {
      self.errors = errors;
      self.requesting = false;
    },
    sendInvitation(onSuccess) {
      self.setRequestingFlag(true);
      self.usersStore.invite(self.email.value, self.firstName, self.lastName, self.role, onSuccess, (resp, errors) => {
        self.setCreateError(errors);
        self.setRequestingFlag(false);
      });
    },
    validateEmailExistenceInTheSystem(value) {
      if (!value) {
        return false;
      }
      const newPromise = new Promise((resolve) => {
        const onFetchComplete = (user) => {
          if (user !== undefined) {
            if (user.state === UserStates.INVITED) {
              resolve('The user with such email already invited.');
            } else {
              resolve('The user with such email already exists.');
            }
          }
          resolve(false);
        };

        self.usersStore.getFromAPI(value, onFetchComplete);
      });

      return newPromise;
    },
    afterCreate() {
      self.email.addInputValidator(self.validateEmailExistenceInTheSystem);
    },
  }))
  .views((self) => ({
    get filled() {
      return self.email.isDone() && self.firstName !== null && self.lastName !== null;
    },
  }));
