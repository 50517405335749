import React from 'react';

import Doc from 'experiments/fullPages/ExpDoc';
import Login from 'experiments/fullPages/ExpLogin';
import OrgSettings from 'experiments/fullPages/ExpOrgSettings';
import Scripts from 'experiments/fullPages/ExpScripts';
import Terminal from 'experiments/fullPages/ExpTerminal';
import Hosts from 'experiments/fullPages/ExpHosts';
import HostsDetails from 'experiments/fullPages/ExpHostsDetails';
import NewHost from 'experiments/fullPages/ExpNewHost';
import History from 'experiments/fullPages/ExpHistory';
import TwoPanels from 'experiments/fullPages/ExpTwopanels';
import GlobalModal from 'experiments/fullPages/ExpGlobalModal';
import TerminalContainer from 'experiments/fullPages/ExpTerminalContainer';

export class ExpPage extends React.Component {
  render() {
    const pages = {
      Doc: <Doc />,
      Login: <Login />,
      OrgSettings: <OrgSettings />,
      Scripts: <Scripts />,
      Terminal: <Terminal />,
      TerminalContainer: <TerminalContainer />,
      Hosts: <Hosts />,
      HostsDetails: <HostsDetails />,
      NewHost: <NewHost />,
      History: <History />,
      TwoPanels: <TwoPanels />,
      GlobalModal: <GlobalModal />,
    };

    if (!this.props.match.params.page) {
      return (
        <div>
          <h3>Available pages:</h3>
          <ul>
            {Object.keys(pages).map((page) => (
              <li key={page}>
                <a href={`/exp/${page}`}> {page}</a>
              </li>
            ))}
          </ul>
        </div>
      );
    }
    return pages[this.props.match.params.page] || <p>Page not found {this.props.match.params.page}</p>;
  }
}
export default ExpPage;
