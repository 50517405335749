import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

export const SidedPanel = observer((props) => {
  const fill = props.fill ? `${props.fill}-filling` : '';

  return <div className={`two-panels ${fill}`}>{props.children}</div>;
});
SidedPanel.propTypes = {
  fill: PropTypes.oneOf(['left', 'right', null]),
};
SidedPanel.defaultProps = {
  fill: null,
};

export const LeftPanel = observer((props) => {
  return <div className="left"> {props.children} </div>;
});

export const RightPanel = observer((props) => {
  return <div className="right"> {props.children} </div>;
});
