/* eslint-disable */

import React from 'react';

export class Page extends React.Component {
  render() {
    return (
      <div className="grid home">
        <div className="header">
          <div className="headerbar">
            <div className="headerbar-container">
              <a href="home.html" className="icon home" />
              <a href="#" className="success active">
                Active instance success
              </a>

              <div className="global-search">
                <div className="dynamic-dropdown" style={{ display: 'none' }}>
                  <div className="form-box">
                    <div className="form-item">
                      <select>
                        <option selected>Select</option>
                        <option value="1">Item 1</option>
                        <option value="2">Item 2</option>
                        <option value="3">Item 3</option>
                        <option value="4">Item 4</option>
                        <option value="5">Item 5</option>
                        <option value="6">Item 6</option>
                        <option value="7">Item 7</option>
                        <option value="8">Item 8</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="dynamic-search">
                  <div className="form-box">
                    <div className="form-item search-button header-search">
                      <div className="item-container">
                        <input type="text" id="form-field" />
                        <div className="button-container">
                          <div className="button-container-item">
                            <button type="submit" className="search" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="autocomplete-container" style={{ display: 'none' }}>
                    <div className="autocomplete">
                      <form>
                        <div className="form-box">
                          <fieldset className="fieldset">
                            <div className="checkbox bounce">
                              <input type="checkbox" id="check-option-11" value="1" />
                              <svg viewBox="0 0 21 21">
                                <polyline points="5 10.75 8.5 14.25 16 6" />
                              </svg>
                              <label htmlFor="check-option-11">Hide Deleted Items</label>
                            </div>
                          </fieldset>
                        </div>
                      </form>
                      <form>
                        <div className="form-box-line">
                          <div className="form-item">
                            <div className="item-container">
                              <button type="button" className="btn btn-primary btn-tag">
                                All k8s Nodes
                              </button>
                            </div>
                          </div>
                          <div className="form-item">
                            <div className="item-container">
                              <button type="button" className="btn btn-primary btn-tag">
                                My Resources
                              </button>
                            </div>
                          </div>
                          <div className="form-item">
                            <div className="item-container">
                              <button type="button" className="btn btn-primary btn-tag">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>

                      <h4>Resent Search Query</h4>
                      <ul className="autocomplete-results">
                        <li>Boris</li>
                        <li>Boris Bullet Dodger</li>
                        <li>Boris Brejcha</li>
                        <li>Boris Pavlovich</li>
                        <li>Boris Johnson</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="icon helper">
                <div className="hint" style={{ display: 'none' }}>
                  <div className="body">
                    <b>Comparison Operators:</b>
                    <p> &gt;, &lt;, &gt;=, &lt;=, ==, != (or &gt;&gt;)</p>
                    <b>Special Operators:</b>
                    <p> %= (fuzzy search), ~= (regex match)</p>
                    <b>Array values operators:</b>
                    <p>IN and CONTAINS</p>
                    <b>Value Types</b>
                    <b>Strings:</b>
                    <p>'abcdef'</p>
                    <b>Integers:</b>
                    <p>1, 5, 1050</p>
                    <b>Floats:</b>
                    <p>1.5, 20.05, 0.33</p>
                    <b>Arrays:</b>
                    <p>[1, 2, 3, 10.5]</p>
                    <b>Functions-Values:</b>
                    <p>
                      id('some_value') returns arrays of @id(s) of records that match std::types/Inventory:1.identity ==
                      'some_value'
                    </p>
                    <b>Function Statements:</b>
                    <p>
                      inherits('some-model-name') filter results to return only records that inherit 'some-model-name'
                    </p>
                    <p>
                      isSet('some-attr-name')/isNotSet('some-attr-name') filter results to return only records that has
                      or hasn't 'some-attr-name' attribute
                    </p>
                    <b>Complex queries:</b>
                    <p>One can use AND and OR to connect multiple statements</p>
                    <b>Brackets:</b>
                    <p>( ) - first executed in brackets</p>
                  </div>
                  <div className="footer">
                    <button type="button" className="btn btn-default">
                      Close
                    </button>
                  </div>
                </div>
              </div>

              <a className="spacer" />

              <div className="text">
                Simple Text
              </div>

              <a href="#">
                Organization Name
              </a>
              <div className="hint-container">
                <a href="#" className="icon terminal-sessions" title="Active Terminal Sessions">
                  <div className="badge">
                    <span>13</span>
                  </div>
                </a>
                <div className="hint">
                  <div className="header">
                    Everything is working
                  </div>
                  <div className="body">
                    <p>Just insert the html correctly!</p>
                  </div>
                  <div className="footer">
                    <button type="button" className="btn btn-default">Close</button>
                  </div>
                </div>
              </div>
              <a href="models.html" className="icon models" title="Models" />
              <span className="divider" />
              <a href="#" className="icon notification" title="Notifications">
                <div className="badge">
                  <span>66</span>
                </div>
              </a>
              <a href="#" className="icon user" title="User Profile" />
              <a href="login.html" className="icon logout" title="Logout" />
            </div>
          </div>
        </div>

        <div className="content">

          <div className="container-box">
            <div className="doc-grid">
              <div className="doc-toc">
                <ul>
                  <li>
                    <a href="#forms">Forms</a>
                  </li>
                  <li>
                    <a href="#input">Input</a>
                  </li>
                </ul>
              </div>

              <div className="doc-content">
                <div className="page-headline">
                  <div className="title">
                    <span>Documentation</span>
                  </div>
                </div>
                <div className="doc-content-item">
                  <h3 id="forms">Forms</h3>

                  <h4>Form container</h4>
                  <p>Mandatory container for form block.</p>
                  <code>
                    <h4>Example</h4>
                    <p>&lt;form&gt;</p>
                    <p>&emsp;&lt;div class="form-box"&gt;</p>
                    <p>&emsp;...</p>
                    <p>&emsp;&lt;/div&gt;</p>
                    <p>&lt;/form&gt;</p>
                  </code>
                  <p>The standard input code will look like this.</p>
                  <p>
                    Input and any other input field is always equal to 100%. You cannot directly set the size. Only the
                    outer container can limit the size of the fields.
                  </p>

                  <h4 id="input">Input</h4>

                  <code>
                    <h4>Sample</h4>
                    <p>&lt;form&gt;</p>
                    <p>&emsp;&lt;div class="form-box"&gt;</p>
                    <p>&emsp;&emsp;&lt;div class="form-item"&gt;</p>
                    <p>&emsp;&emsp;&emsp;&lt;input type="text" id="form-field" placeholder="Example"&gt;</p>
                    <p>&emsp;&emsp;&emsp;&lt;label for="form-item"&gt;Example&lt;/label&gt;</p>
                    <p>&emsp;&emsp;&emsp;&lt;span class="help-text"&gt;Example&lt;/span&gt;</p>
                    <p>&emsp;&emsp;&lt;/div&gt;</p>
                    <p>&emsp;&lt;/div&gt;</p>
                    <p>&lt;/form&gt;</p>
                  </code>

                  <form>
                    <div className="form-box">
                      <div className="form-item">
                        <div className="item-container">
                          <input type="text" id="form-field" placeholder="Example" />
                          <label htmlFor="form-item">Example</label>
                        </div>
                        <span className="help-text">Example</span>
                      </div>
                    </div>
                  </form>

                  <h4>Form in one line</h4>
                  <form>
                    <div className="form-box-line">
                      <div className="form-item">
                        <div className="item-container">
                          <input type="text" id="form-field" placeholder="Label" />
                          <label htmlFor="form-item">Label</label>
                        </div>
                      </div>
                      <div className="form-item">
                        <div className="item-container">
                          <input type="text" id="form-field" placeholder="Label" />
                          <label htmlFor="form-item">Label</label>
                          <button type="button" className="btn-only-icon plus" />
                          <button type="button" className="btn-only-icon minus" />
                        </div>
                        <span className="help-text">Sample text</span>
                      </div>
                      <div className="form-item">
                        <div className="item-container">
                          <input type="text" id="form-field" placeholder="Label" />
                          <label htmlFor="form-item">Label</label>
                        </div>
                      </div>
                    </div>
                  </form>

                  <h4>Form in one line stretch</h4>
                  <form>
                    <div className="form-box-line-stretch">
                      <div className="form-item">
                        <div className="item-container">
                          <input type="text" id="form-field" placeholder="Label" />
                          <label htmlFor="form-item">Label</label>
                        </div>
                      </div>
                      <div className="form-item">
                        <div className="item-container">
                          <input type="text" id="form-field" placeholder="Label" />
                          <label htmlFor="form-item">Label</label>
                          <button type="button" className="btn-only-icon plus" />
                          <button type="button" className="btn-only-icon minus" />
                        </div>
                        <span className="help-text">Sample text</span>
                      </div>
                      <div className="form-item">
                        <div className="item-container">
                          <input type="text" id="form-field" placeholder="Label" />
                          <label htmlFor="form-item">Label</label>
                        </div>
                      </div>
                    </div>
                  </form>

                  <h4>Valid and Invalid</h4>
                  <form>
                    <div className="form-box">
                      <div className="form-item">
                        <div className="item-container">
                          <input type="text" id="form-field" className="valid" placeholder="Valid" />
                          <label htmlFor="form-item">Valid</label>
                        </div>
                        <span className="help-text">Sample text</span>
                      </div>
                    </div>
                    <div className="form-box">
                      <div className="form-item">
                        <div className="item-container">
                          <input type="text" id="form-field" className="invalid" placeholder="Invalid" />
                          <label htmlFor="form-item">Invalid</label>
                        </div>
                        <span className="help-text">Sample text</span>
                      </div>
                    </div>
                  </form>

                  <h4>Password</h4>
                  <form>
                    <div className="form-box">
                      <div className="form-item">
                        <div className="item-container">
                          <input type="text" id="form-field" className="password" placeholder="Password" />
                          <label htmlFor="form-item">Password</label>
                          <button className="btn btn-link btn-copy">
                            <icon className="eye-off" />
                          </button>
                        </div>
                        <span className="help-text">Sample text</span>
                      </div>
                      <div className="form-item">
                        <div className="item-container">
                          <input type="text" id="form-field" className="password-show" placeholder="Password" />
                          <label htmlFor="form-item">Password</label>
                          <button className="btn btn-link btn-copy">
                            <icon className="eye-on" />
                          </button>
                        </div>
                        <span className="help-text">Sample text</span>
                      </div>
                    </div>
                  </form>

                  <h4>Loading</h4>
                  <form>
                    <div className="form-box">
                      <div className="form-item">
                        <div className="item-container">
                          <input type="text" id="form-field" className="loading" placeholder="Loading Process" />
                          <label htmlFor="form-item">Loading Process</label>
                          <button type="button" className="btn btn-default">
                            Download
                          </button>
                        </div>
                        <span className="help-text">Sample text</span>
                      </div>
                    </div>
                  </form>

                  <h4>Input with progress</h4>
                  <form>
                    <div className="form-box">
                      <div className="form-item">
                        <div className="item-container">
                          <input
                            type="text"
                            id="form-field"
                            className="progress"
                            placeholder="Progress Input"
                            style={{ background: 'linear-gradient(to right, #bed1c5 0%, #bed1c5 95%, #334155 0%)' }}
                          />
                          <label htmlFor="form-item">Progress Input</label>
                          <button type="button" className="btn btn-default">
                            Download
                          </button>
                        </div>
                        <span className="help-text">Sample text</span>
                      </div>
                    </div>
                  </form>

                  <h4>Read only & Disable</h4>
                  <form>
                    <div className="form-box">
                      <div className="form-item">
                        <div className="item-container">
                          <input type="text" id="form-field" value="Read Only" readOnly />
                          <label htmlFor="form-item">Read Only</label>
                        </div>
                        <span className="help-text">Sample text</span>
                      </div>
                    </div>
                    <div className="form-box">
                      <div className="form-item">
                        <div className="item-container">
                          <input type="text" id="form-field" disabled />
                          <label htmlFor="form-item">Disabled</label>
                        </div>
                        <span className="help-text">Sample text</span>
                      </div>
                    </div>
                  </form>

                  <h4 id="search">Search</h4>
                  <form>
                    <div className="form-box">
                      <div className="form-item search">
                        <div className="item-container">
                          <input type="text" id="form-field" placeholder="Search" />
                          <label htmlFor="form-item">Search</label>
                          <div className="button-container">
                            <div className="button-container-item">
                              <button type="submit" className="btn btn-default search">Search</button>
                            </div>
                          </div>
                          <button type="button" className="btn btn-success">
                            Button
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="form-box">
                      <div className="form-item search-button">
                        <div className="item-container">
                          <input type="text" id="form-field" placeholder="Search with button" />
                          <label htmlFor="form-item">Search with button</label>
                          <div className="button-container">
                            <div className="button-container-item">
                              <button type="submit" className="search" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-box">
                      <div className="form-item search-zoom">
                        <div className="item-container">
                          <input type="text" id="form-field" placeholder="Search with zooming" />
                          <label htmlFor="form-item">Search with zooming</label>
                          <div className="button-container">
                            <div className="button-container-item">
                              <button type="submit" className="search" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-box">
                      <div className="form-item search-button search-zoom">
                        <div className="item-container">
                          <input type="text" id="form-field" placeholder="Search with zooming and button" />
                          <label htmlFor="form-item">Search with zooming and button</label>
                          <div className="button-container">
                            <div className="button-container-item">
                              <button type="submit" className="search" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>

                  <h4>Terminal Search</h4>
                  <form>
                    <div className="form-box">
                      <div className="form-item terminal-search">
                        <div className="item-container">
                          <input type="text" id="form-field" placeholder="Search" />
                          <label htmlFor="form-item">Search</label>
                          <div className="button-container">
                            <div className="button-container-item">
                              <button type="submit" className="btn btn-default search">Search</button>
                            </div>
                          </div>
                          <button type="button" className="btn btn-success">
                            Button
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>

                  <h4>Select</h4>
                  <form>
                    <div className="form-box">
                      <div className="form-item">
                        <div className="item-container">
                          <select>
                            <option selected>Selected Item</option>
                            <option value="1">Item 1</option>
                            <option value="2">Item 2</option>
                            <option value="3">Item 3</option>
                            <option value="4">Item 4</option>
                            <option value="5">Item 5</option>
                            <option value="6">Item 6</option>
                            <option value="7">Item 7</option>
                            <option value="8">Item 8</option>
                          </select>
                          <label>Label</label>
                        </div>
                        <span className="help-text">Description</span>
                      </div>
                    </div>
                  </form>

                  <h4>File + Progress</h4>
                  <form>
                    <div className="form-box">
                      <div className="item-container">
                        <div
                          className="file-upload-select"
                          style={{
                            background: 'linear-gradient(to right, #bed1c5 0%, #bed1c5 65%, #334155 0%)',
                          }}
                        >
                          <div className="file-select-button">Choose File or Drag and Drop Here</div>
                          <div className="file-select-name" style={{ display: 'none' }}>
                            No file chosen...
                          </div>
                          <input type="file" name="file-upload-input" id="file-upload-input" />
                        </div>
                      </div>
                    </div>
                  </form>

                  <h4>Inputs with Tags</h4>
                  <div className="multipleselect-container">
                    <div className="multipleselect">
                      <div className="filtered-items">
                        <div className="item">
                          <div>Jean</div>
                          <div className="close-icon">+</div>
                        </div>
                        <div className="item">
                          <div>Claude</div>
                          <div className="close-icon">+</div>
                        </div>
                        <div className="item">
                          <div>Van</div>
                          <div className="close-icon">+</div>
                        </div>
                        <div className="item">
                          <div>Damme</div>
                          <div className="close-icon">+</div>
                        </div>
                      </div>
                      <form className="form-box multipleselect-input">
                        <div className="form-item">
                          <div className="item-container">
                            <input type="search" id="form-field" placeholder="Example" />
                            <label htmlFor="form-item">Example</label>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <h4>Checkboxes</h4>
                  <form>
                    <div className="form-box">
                      <fieldset className="fieldset">
                        <div className="checkbox bounce">
                          <input type="checkbox" id="check-option-11" value="1" />
                          <svg viewBox="0 0 21 21">
                            <polyline points="5 10.75 8.5 14.25 16 6" />
                          </svg>
                          <label htmlFor="check-option-11">Checkbox Item 1</label>
                        </div>
                        <div className="checkbox bounce">
                          <input type="checkbox" id="check-option-12" value="2" />
                          <svg viewBox="0 0 21 21">
                            <polyline points="5 10.75 8.5 14.25 16 6" />
                          </svg>
                          <label htmlFor="check-option-12">Checkbox Item 2</label>
                        </div>
                        <div className="checkbox bounce">
                          <input type="checkbox" id="check-option-13" value="3" disabled />
                          <svg viewBox="0 0 21 21">
                            <polyline points="5 10.75 8.5 14.25 16 6" />
                          </svg>
                          <label htmlFor="check-option-13">Checkbox Item Disabled</label>
                        </div>
                        <div className="checkbox bounce">
                          <input type="checkbox" id="check-option-14" value="4" checked disabled />
                          <svg viewBox="0 0 21 21">
                            <polyline points="5 10.75 8.5 14.25 16 6" />
                          </svg>
                          <label htmlFor="check-option-14">Checkbox Item Checked and Disabled</label>
                        </div>
                      </fieldset>
                    </div>
                  </form>

                  <h4>Checkboxes in one line</h4>
                  <form>
                    <div className="form-box">
                      <fieldset className="fieldset-on-line">
                        <div className="checkbox bounce">
                          <input type="checkbox" id="check-option-21" value="1" />
                          <svg viewBox="0 0 21 21">
                            <polyline points="5 10.75 8.5 14.25 16 6" />
                          </svg>
                          <label htmlFor="check-option-21">Checkbox Item 1</label>
                        </div>
                        <div className="checkbox bounce">
                          <input type="checkbox" id="check-option-22" value="2" />
                          <svg viewBox="0 0 21 21">
                            <polyline points="5 10.75 8.5 14.25 16 6" />
                          </svg>
                          <label htmlFor="check-option-22">Checkbox Item 2</label>
                        </div>
                        <div className="checkbox bounce">
                          <input type="checkbox" id="check-option-23" value="3" disabled />
                          <svg viewBox="0 0 21 21">
                            <polyline points="5 10.75 8.5 14.25 16 6" />
                          </svg>
                          <label htmlFor="check-option-23">Checkbox Item Disabled</label>
                        </div>
                        <div className="checkbox bounce">
                          <input type="checkbox" id="check-option-24" value="4" checked disabled />
                          <svg viewBox="0 0 21 21">
                            <polyline points="5 10.75 8.5 14.25 16 6" />
                          </svg>
                          <label htmlFor="check-option-24">Checkbox Item Checked and Disabled</label>
                        </div>
                      </fieldset>
                    </div>
                  </form>

                  <h4>Radio</h4>
                  <form>
                    <div className="form-box">
                      <fieldset className="fieldset">
                        <div className="radio bounce">
                          <input type="radio" name="radio-demo" id="radio-option-11" value="1" checked />
                          <svg viewBox="0 0 18 18">
                            <circle cx="9" cy="9" r="4" />
                          </svg>
                          <label htmlFor="radio-option-11">Radio Item 1</label>
                        </div>
                        <div className="radio bounce">
                          <input type="radio" name="radio-demo" id="radio-option-12" value="2" />
                          <svg viewBox="0 0 18 18">
                            <circle cx="9" cy="9" r="4" />
                          </svg>
                          <label htmlFor="radio-option-12">Radio Item 2</label>
                        </div>
                        <div className="radio bounce">
                          <input type="radio" name="radio-demo" id="radio-option-13" value="3" disabled />
                          <svg viewBox="0 0 18 18">
                            <circle cx="9" cy="9" r="4" />
                          </svg>
                          <label htmlFor="radio-option-13">Radio Item Disabled</label>
                        </div>
                        <div className="radio bounce">
                          <input type="radio" name="radio-demo" id="radio-option-14" value="4" checked disabled />
                          <svg viewBox="0 0 18 18">
                            <circle cx="9" cy="9" r="4" />
                          </svg>
                          <label htmlFor="radio-option-14">Radio Item Checked and Disabled</label>
                        </div>
                      </fieldset>
                    </div>
                  </form>

                  <h4>Radio in one line</h4>
                  <form>
                    <div className="form-box">
                      <fieldset className="fieldset-on-line">
                        <div className="radio bounce">
                          <input type="radio" name="radio-demo-2" id="radio-option-21" value="5" checked />
                          <svg viewBox="0 0 18 18">
                            <circle cx="9" cy="9" r="4" />
                          </svg>
                          <label htmlFor="radio-option-21">Radio Item 1</label>
                        </div>
                        <div className="radio bounce">
                          <input type="radio" name="radio-demo-2" id="radio-option-22" value="6" />
                          <svg viewBox="0 0 18 18">
                            <circle cx="9" cy="9" r="4" />
                          </svg>
                          <label htmlFor="radio-option-22">Radio Item 2</label>
                        </div>
                        <div className="radio bounce">
                          <input type="radio" name="radio-demo-2" id="radio-option-23" value="7" disabled />
                          <svg viewBox="0 0 18 18">
                            <circle cx="9" cy="9" r="4" />
                          </svg>
                          <label htmlFor="radio-option-23">Radio Item Disabled</label>
                        </div>
                        <div className="radio bounce">
                          <input type="radio" name="radio-demo-2" id="radio-option-24" value="8" checked disabled />
                          <svg viewBox="0 0 18 18">
                            <circle cx="9" cy="9" r="4" />
                          </svg>
                          <label htmlFor="radio-option-24">Radio Item Checked and Disabled</label>
                        </div>
                      </fieldset>
                    </div>
                  </form>

                  <h4>Checkboxes and Radio in different statuses</h4>
                  <form>
                    <div className="form-box">
                      <fieldset className="fieldset">
                        <div className="checkbox success bounce">
                          <input type="checkbox" id="check-option-31" value="1" checked />
                          <svg viewBox="0 0 21 21">
                            <polyline points="5 10.75 8.5 14.25 16 6" />
                          </svg>
                          <label htmlFor="check-option-31">Checkbox Success</label>
                        </div>
                        <div className="checkbox warning bounce">
                          <input type="checkbox" id="check-option-32" value="2" checked />
                          <svg viewBox="0 0 21 21">
                            <polyline points="5 10.75 8.5 14.25 16 6" />
                          </svg>
                          <label htmlFor="check-option-32">Checkbox Warning</label>
                        </div>
                        <div className="checkbox danger bounce">
                          <input type="checkbox" id="check-option-33" value="3" checked />
                          <svg viewBox="0 0 21 21">
                            <polyline points="5 10.75 8.5 14.25 16 6" />
                          </svg>
                          <label htmlFor="check-option-33">Checkbox Danger</label>
                        </div>
                        <div className="radio success bounce">
                          <input type="radio" name="radio-demo-3" id="radio-option-31" value="1" checked />
                          <svg viewBox="0 0 18 18">
                            <circle cx="9" cy="9" r="4" />
                          </svg>
                          <label htmlFor="radio-option-31">Radio Success</label>
                        </div>
                        <div className="radio warning bounce">
                          <input type="radio" name="radio-demo-3" id="radio-option-32" value="2" />
                          <svg viewBox="0 0 18 18">
                            <circle cx="9" cy="9" r="4" />
                          </svg>
                          <label htmlFor="radio-option-32">Radio Warning</label>
                        </div>
                        <div className="radio danger bounce">
                          <input type="radio" name="radio-demo-3" id="radio-option-33" value="3" />
                          <svg viewBox="0 0 18 18">
                            <circle cx="9" cy="9" r="4" />
                          </svg>
                          <label htmlFor="radio-option-33">Radio Danger</label>
                        </div>
                      </fieldset>
                    </div>
                  </form>

                  <h4>Buttons</h4>
                  <form>
                    <div className="form-box-line">
                      <div className="form-item">
                        <div className="item-container">
                          <button type="button" className="btn btn-default">
                            Button Default
                          </button>
                        </div>
                      </div>
                      <div className="form-item">
                        <div className="item-container">
                          <button type="button" className="btn btn-primary">
                            Button Primary
                          </button>
                        </div>
                      </div>
                      <div className="form-item">
                        <div className="item-container">
                          <button type="button" className="btn btn-success">
                            Button Success
                          </button>
                        </div>
                      </div>
                      <div className="form-item">
                        <div className="item-container">
                          <button type="button" className="btn btn-warning">
                            Button Warning
                          </button>
                        </div>
                      </div>
                      <div className="form-item">
                        <div className="item-container">
                          <button type="button" className="btn btn-danger">
                            Button Danger
                          </button>
                        </div>
                      </div>
                      <div className="form-item">
                        <div className="item-container">
                          <button type="button" className="btn btn-link">
                            Button Link
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  
                  <h4>Buttons Disabled</h4>
                  <form>
                    <div className="form-box-line">
                      <div className="form-item">
                        <div className="item-container">
                          <button type="button" className="btn btn-default" disabled>
                            Button Disabled
                          </button>
                        </div>
                      </div>
                      <div className="form-item">
                        <div className="item-container">
                          <button type="button" className="btn btn-primary" disabled>
                            Button Disabled
                          </button>
                        </div>
                      </div>
                      <div className="form-item">
                        <div className="item-container">
                          <button type="button" className="btn btn-success" disabled>
                            Button Disabled
                          </button>
                        </div>
                      </div>
                      <div className="form-item">
                        <div className="item-container">
                          <button type="button" className="btn btn-warning" disabled>
                            Button Disabled
                          </button>
                        </div>
                      </div>
                      <div className="form-item">
                        <div className="item-container">
                          <button type="button" className="btn btn-danger" disabled>
                            Button Disabled
                          </button>
                        </div>
                      </div>
                      <div className="form-item">
                        <div className="item-container">
                          <button type="button" className="btn btn-link" disabled>
                            Button Disabled
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>

                  <h4>Button 100% width</h4>
                  <form>
                    <div className="form-box">
                      <div className="form-item">
                        <div className="item-container">
                          <button type="button" className="btn btn-primary btn-block">
                            Button Block
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>

                  <h4>Buttons with icon</h4>
                  <form>
                    <div className="form-box-line">
                      <div className="form-item">
                        <div className="item-container">
                          <button type="button" className="btn btn-default">
                            <icon className="icon edit" />
                            Button with icon
                          </button>
                        </div>
                      </div>
                      <div className="form-item">
                        <div className="item-container">
                          <button type="button" className="btn btn-primary">
                            <icon className="icon delete" />
                            Button with icon
                          </button>
                        </div>
                      </div>
                      <div className="form-item">
                        <div className="item-container">
                          <button type="button" className="btn btn-success">
                            <icon className="icon eye-on" />
                            Button with icon
                          </button>
                        </div>
                      </div>
                      <div className="form-item">
                        <div className="item-container">
                          <button type="button" className="btn btn-warning">
                            <icon className="icon eye-off" />
                            Button with icon
                          </button>
                        </div>
                      </div>
                      <div className="form-item">
                        <div className="item-container">
                          <button type="button" className="btn btn-danger">
                            <icon className="icon edit" />
                            Button with icon
                          </button>
                        </div>
                      </div>
                      <div className="form-item">
                        <div className="item-container">
                          <button type="button" className="btn btn-link">
                            <icon className="icon edit" />
                            Text button with icon
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>

                    <h4>Filters block</h4>
                    <div className="filters-box">
                      <div className="form-box">
                        <div className="form-item search">
                          <div className="item-container">
                            <input type="text" id="form-field" placeholder="Search" />
                            <label htmlFor="form-item">Search</label>
                            <div className="button-container">
                              <div className="button-container-item">
                                <button type="submit" className="search" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <form className="form-box">
                        <div className="btn-group">
                          <div className="btn-group-item">
                            <input type="checkbox" className="check" name="active" id="active" checked="" />
                            <label className="btn" htmlFor="active">
                              Item One
                            </label>
                          </div>

                          <div className="btn-group-item">
                            <input type="checkbox" className="check" name="suspended" id="suspended" checked="" />
                            <label className="btn" htmlFor="suspended">
                              Item Two
                            </label>
                          </div>

                          <div className="btn-group-item">
                            <input type="checkbox" className="check" name="invited" id="invited" checked="" />
                            <label className="btn" htmlFor="invited">
                              Item Three
                            </label>
                          </div>
                        </div>
                      </form>

                      <form className="form-box-line">
                        <div className="form-item">
                          <div className="item-container">
                            <button type="button" className="btn btn-default">
                              Button One
                            </button>
                          </div>
                        </div>
                        <div className="form-item">
                          <div className="item-container">
                            <button type="button" className="btn btn-default">
                              Button Two
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>

                  <h4>Уear selection</h4>
                  <div className="years-select-container">
                    <li className='active'>2023</li>
                    <li>2022</li>
                    <li>2021</li>
                    <li>2020</li>
                    <li>2019</li>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Page;
