import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { ConfirmationAboutDeleteWindow } from 'components/ModalWindow';

export const DeleteRecord = inject('notifications')(
  observer((props) => {
    if (props.hidden) {
      return null;
    }
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteInProgress, setDeleteInProgress] = useState(false);
    const history = useHistory();

    const onClick = () => {
      setShowDeleteConfirmation(true);
    };

    const deleteHandler = () => {
      setDeleteInProgress(true);
      const errorOrPromise = deleteRecord();
      Promise.resolve(errorOrPromise).then((error) => {
        if (!error) {
          props.notifications.success(`Record ${props.record.id} is deleted.`);
          // small delay to make sure backend finishes updating caches
          setTimeout(() => history.push(props.redirectURLAfterDelete), 500);
        } else {
          props.notifications.error(`Failed to delete record: ${error}`);
        }
      });
    };

    const deleteRecord = () => {
      const socket = props.instance.getSocket();
      socket.afterOpen(async () => {
        const app = socket.ensureAppConnection(props.record.root_1.app);
        const result = await app.rpc(props.deleteThrowAppCommand, { id: props.record.id });
        return result.ok ? null : result.error;
      });
    };

    return (
      <>
        <button type="button" className="btn btn-danger" onClick={onClick}>
          {props.title || 'Delete'}
        </button>
        {showDeleteConfirmation && (
          <ConfirmationAboutDeleteWindow
            onClose={() => setShowDeleteConfirmation(false)}
            onCancel={() => setShowDeleteConfirmation(false)}
            onDelete={deleteHandler}
            deleteInProgress={deleteInProgress}
          />
        )}
      </>
    );
  })
);

export const DeleteHost = observer((props) => {
  return (
    <DeleteRecord
      instance={props.instance}
      record={props.record}
      deleteThrowAppCommand="host.delete"
      redirectURLAfterDelete={`/i/${props.instance.name}/hosts`}
    />
  );
});

DeleteHost.suites = (record) => record.data.has('std::host/Host:1');

export const DeleteScript = observer((props) => {
  return (
    <DeleteRecord
      instance={props.instance}
      record={props.record}
      deleteThrowAppCommand="script.delete"
      redirectURLAfterDelete={`/i/${props.instance.name}/scripts`}
    />
  );
});

DeleteScript.suites = (record) => record.script_1;
