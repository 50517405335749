import React from 'react';
import PropTypes from 'prop-types';

export const ColumnWithIconItem = (props) => (
  <div className="details-item-box icon-box">
    <div className="icon" style={{ backgroundImage: `url(${props.url})` }} />
  </div>
);

ColumnWithIconItem.propTypes = {
  url: PropTypes.string.isRequired,
};

export const Item = (props) => <div>{props.children}</div>;

export const Column = (props) => (
  <div className={`details-item-box${props.large ? ' description-box' : ''}`}>{props.children}</div>
);

Column.propTypes = {
  large: PropTypes.bool,
  children: PropTypes.any,
};

Column.defaultProps = {
  large: false,
};

export class Container extends React.Component {
  TIMEOUT_TO_EXPAND = 0.5 * 1000;

  TIMEOUT_TO_CLOSE = 0.2 * 1000;

  constructor(props) {
    super(props);
    this.state = { closed: true, auto: Boolean(this.props.expandable) };
    this.timer = undefined;
  }

  componentWillUnmount() {
    this.stopTimer();
  }

  stopTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
  };

  toggleClosed = () => {
    this.setState((prevState) => ({ closed: !prevState.closed, auto: false }));
  };

  expand = () => {
    this.stopTimer();
    if (this.state.closed) {
      this.setState({ closed: false, auto: true });
    }
  };

  onMouseOver = () => {
    this.stopTimer();
    if (this.state.closed) {
      this.timer = setTimeout(this.expand, this.TIMEOUT_TO_EXPAND);
    }
  };

  close = () => {
    this.stopTimer();
    if (this.state.auto && !this.state.closed) {
      this.setState({ closed: true, auto: true });
    }
  };

  onMouseOut = () => {
    this.stopTimer();
    if (!this.state.closed) {
      this.timer = setTimeout(this.close, this.TIMEOUT_TO_CLOSE);
    }
  };

  render() {
    return (
      <div
        className={`details-container${this.state.closed ? '' : ' details-container-open'}`}
        onMouseOver={this.onMouseOver}
        onFocus={this.onMouseOver}
        onMouseOut={this.onMouseOut}
        onBlur={this.onMouseOut}
      >
        {this.props.children}
        {this.props.expandable && (
          <div className="expander">
            <div className="caret" onClick={this.toggleClosed} />
          </div>
        )}
      </div>
    );
  }
}
