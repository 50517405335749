import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';

import { parse as qsParse } from 'lib/query-string';
import Input from 'components/forms/Input';
import PageLayout from 'pages/Auth/components/PageLayout';
import { PasswordRecoveryForm, PasswordUpdateForm } from 'stores/Auth';

const PasswordResetComplete = (props) => (
  <PageLayout title="You've got mail!" createAccountLink={false}>
    <div className="description">Check your inbox. We've sent a link to reset your password to: {props.email}</div>
  </PageLayout>
);

@observer
export class PasswordRecoveryRequest extends React.Component {
  constructor(props) {
    super(props);
    this.form = PasswordRecoveryForm.create({});
  }

  render() {
    if (this.form.completed) {
      return <PasswordResetComplete email={this.form.email} />;
    }

    const query = qsParse(window.location.search);
    let loginQuery = '';
    if (query.redirect_url) {
      loginQuery = `?redirect_url=${encodeURIComponent(query.redirect_url)}`;
    }

    return (
      <PageLayout title="Forgot password?" createAccountLink={false}>
        <div className="description">
          Enter the email address and organization name you used when you joined and we'll send you instructions to
          reset your password.
        </div>

        <form
          className="form-box"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Input key="email" store={this.form.email} />
          <Input key="org" store={this.form.organization} />
          <div className="message-placeholder text-danger">{this.form.error}</div>
          <button
            type="button"
            className="btn-primary btn-block"
            onClick={this.form.submit}
            disabled={!this.form.filled}
          >
            Send Reset Instructions
          </button>
        </form>
        <div className="login-link-container">
          <span>I remembered my password! </span>
          <Link to={`/${loginQuery}`}>Sign In</Link>
        </div>
      </PageLayout>
    );
  }
}

@withRouter
@observer
export class PasswordRecoverySetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.form = PasswordUpdateForm.create({});
  }

  componentDidMount() {
    this.form.setToken(this.props.match.params.token);
  }

  render() {
    return (
      <PageLayout title="Set new password" createAccountLink={false}>
        <div className="description">Enter your new password.</div>

        <form
          className="form-box"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Input key="new-password1" store={this.form.newPassword1} />
          <Input key="new-password2" store={this.form.newPassword2} />
          <div className="message-placeholder text-danger">{this.form.error}</div>
          <button
            type="button"
            className="btn-primary btn-block"
            onClick={this.form.submit}
            disabled={this.form.newPassword1.isDone() && this.form.newPassword2.isDone() && this.form.saving}
          >
            Save new password
          </button>
        </form>
        <div className="login-link-container">
          <span>Leave my old password! </span>
          <Link to="/">Sign In</Link>
        </div>
      </PageLayout>
    );
  }
}
