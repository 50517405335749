import { UUID4 } from 'lib/uuid-utils';
import * as queryString from 'lib/query-string';

const simpleRequest = ({ url, method, query, body, authToken, onFailure, onSuccess, onFinish, onCrash } = {}) => {
  const requestID = UUID4();
  const headers = {
    'ITLook-Request-ID': requestID,
  };

  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }

  const options = {
    method: method,
    headers: headers,
  };
  if (options.method !== 'GET' && body !== undefined) {
    // array is also good for us here...
    if (typeof body === 'object' && body !== null) {
      options.body = JSON.stringify(body);
      options.headers['Content-Type'] = 'application/json';
    } else {
      options.body = body;
    }
  }

  if (query) {
    url = `${url}?${queryString.stringify(query)}`;
  }
  console.debug(`Request '${method} ${url}' [req-id=${requestID}]`);

  fetch(url, options).then((res) => {
    console.log(res);
    res
      .json()
      .then((data) => {
        if (res.status >= 500 && onCrash !== undefined) {
          onCrash(res, data);
        } else if (data.hasOwnProperty('errors') && data.errors !== null && data.errors.length > 0) {
          if (onFailure !== undefined) {
            onFailure(res, data.errors, data);
          }
        } else if (res.status !== 200) {
          if (onFailure !== undefined) {
            onFailure(res, ['Response status code is not 200.']);
          }
        } else if (onSuccess !== undefined) {
          onSuccess(res, data);
        }
      })
      .catch((error) => {
        if (error.stopPropagation !== undefined) {
          error.stopPropagation();
        }
        if (onCrash !== undefined) {
          onCrash(res, error);
        }
      })
      .finally(() => {
        if (onFinish !== undefined) {
          onFinish();
        }
      });
  });
};

const requests = {
  GET: ({ url, query, authToken, onFailure, onSuccess, onFinish, onCrash } = {}) =>
    simpleRequest({
      url: url,
      method: 'GET',
      query: query,
      body: undefined,
      authToken: authToken,
      onFailure: onFailure,
      onSuccess: onSuccess,
      onFinish: onFinish,
      onCrash: onCrash,
    }),
  POST: ({ url, query, body, authToken, onFailure, onSuccess, onFinish, onCrash } = {}) =>
    simpleRequest({
      url: url,
      method: 'POST',
      query: query,
      body: body,
      authToken: authToken,
      onFailure: onFailure,
      onSuccess: onSuccess,
      onFinish: onFinish,
      onCrash: onCrash,
    }),
  PUT: ({ url, query, body, authToken, onFailure, onSuccess, onFinish, onCrash } = {}) =>
    simpleRequest({
      url: url,
      method: 'PUT',
      query: query,
      body: body,
      authToken: authToken,
      onFailure: onFailure,
      onSuccess: onSuccess,
      onFinish: onFinish,
      onCrash: onCrash,
    }),
  DELETE: ({ url, query, body, authToken, onFailure, onSuccess, onFinish, onCrash } = {}) =>
    simpleRequest({
      url: url,
      method: 'DELETE',
      query: query,
      body: body,
      authToken: authToken,
      onFailure: onFailure,
      onSuccess: onSuccess,
      onFinish: onFinish,
      onCrash: onCrash,
    }),
};

export default requests;
