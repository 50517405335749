import React from 'react';
import { inject, observer } from 'mobx-react';

const TerminalsCounter = observer((props) => {
  return (
    <div className="badge">
      <span>{props.activeSessions}</span>
    </div>
  );
});

export const Terminals = inject('store')(
  observer((props) => {
    const activeSessions = props.store.XTermSessionsManager.sessions.size;
    if (activeSessions === 0) {
      return null;
    }

    return (
      <>
        <a
          className="icon terminal-sessions"
          title="Active Terminal Sessions"
          onClick={props.store.XTermSessionsManager.showTerminals}
        >
          <TerminalsCounter activeSessions={activeSessions} />
        </a>
      </>
    );
  })
);
