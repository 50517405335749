import React from 'react';
import { observer } from 'mobx-react';

import { FormItemForInput } from 'components/forms/FormItem';
import Input from 'components/forms/Input/Input';

const TagItem = (props) => (
  <div className="item">
    <div>{props.name}</div>
    <div className="close-icon" onClick={() => props.onClick(props.name)}>
      +
    </div>
  </div>
);

export default observer((props) => (
  <FormItemForInput error={props.store.error} description={props.store.description}>
    <div className="item-container">
      <div className="multipleselect-container">
        <div className="multipleselect">
          <div className="filtered-items">
            {props.store.items.map((tag) => (
              <TagItem key={tag} name={tag} onClick={props.store.removeItem} />
            ))}
          </div>
          <div className="multipleselect-input">
            <Input store={props.store} />
          </div>
        </div>
      </div>
    </div>
  </FormItemForInput>
));
