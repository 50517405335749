import React from 'react';
import { getType } from 'mobx-state-tree';

import BaseInput from 'components/forms/Input/Input';
import InputStore from 'components/forms/Input/InputS';
import EmailInputStore from 'components/forms/Input/EmailInputS';
import FilePathInputStore from 'components/forms/Input/FilePathInputS';
import PasswordInput from 'components/forms/Input/PasswordInput';
import PasswordInputStore from 'components/forms/Input/PasswordInputS';
import TagsInput from 'components/forms/Input/TagsInput';
import TagsInputStore from 'components/forms/Input/TagsInputS';

export { InputStore, EmailInputStore, FilePathInputStore, PasswordInputStore, TagsInputStore };

const ComponentsMap = {
  [PasswordInputStore.name]: PasswordInput,
  [TagsInputStore.name]: TagsInput,
};

export default (props) => React.createElement(ComponentsMap[getType(props.store).name] || BaseInput, props);
