import React from 'react';

import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import moment from 'moment';

import * as ContentHeader from 'components/ContentHeader';
import TextEditor from 'components/input/TextEditor';
import { Spinner } from 'components/Loader';
import { PageHeader } from 'components/Page';
import { TabsGroup, TabView, TabViewsHeader, TabViewsSwitcher } from 'components/TabsPageView';

const ModelPartLink = (props) => <Link to={`/catalog/models/${props.model}`}>{props.model}</Link>;

const Parts = (props) => {
  if (props.parts.length === 0) {
    return null;
  }
  const types = props.parts.filter((p) => p.startsWith('std::types'));
  const stdModels = props.parts.filter((p) => p.startsWith('std::') && !p.startsWith('std::types'));
  const other = props.parts.filter((p) => !p.startsWith('std'));

  return (
    <ContentHeader.Item>
      <span key="title">Parts:</span>
      {types && <span key="types">{types && types.map((m) => <ModelPartLink model={m} key={m} />)}</span>}
      {stdModels && <span key="std">{stdModels && stdModels.map((m) => <ModelPartLink model={m} key={m} />)}</span>}
      {other && <span key="other">{other && other.map((m) => <ModelPartLink model={m} key={m} />)}</span>}
    </ContentHeader.Item>
  );
};

const StatusClassMap = {
  development: 'text-warning',
  stable: 'text-success',
  deprecated: 'text-danger',
};

@observer
class ModelHeader extends React.Component {
  componentDidMount() {
    if (this.props.model.definition.loaded && this.props.model.definition.loading) {
      this.props.model.definition.fetch();
    }
  }

  render() {
    if (!this.props.model.definition.loaded) {
      return null;
    }
    const definition = this.props.model.definition.obj;

    const modelsOrgLink = `/catalog/models?org=${definition.organization}`;
    const modelsGroupLink = `/catalog/models?group=${definition.group}`;
    const inheritsLinks = definition.inherits.map((model) => <ModelPartLink model={model} key={`i-${model}`} />);

    return (
      <ContentHeader.Container>
        <ContentHeader.ColumnWithIconItem url={this.props.model.pictureUrl} />

        <ContentHeader.Column>
          <ContentHeader.Item>
            <span>Status:</span>
            <b className={StatusClassMap[definition.status]}>{definition.status}</b>
          </ContentHeader.Item>

          {definition.inherits.length > 0 && (
            <ContentHeader.Item>
              <span>Inherits:</span>
              {inheritsLinks}
            </ContentHeader.Item>
          )}
        </ContentHeader.Column>

        <ContentHeader.Column large>
          <ContentHeader.Item>
            <span>Description:</span>
            {definition.description}
          </ContentHeader.Item>
          <Parts parts={definition.parts} />
        </ContentHeader.Column>

        <ContentHeader.Column large>
          <ContentHeader.Item>
            <span>Search:</span>
            <span>
              <Link to={modelsOrgLink}>View all models of {definition.organization} organization</Link>
            </span>
            <span>
              <Link to={modelsGroupLink}>View all models of {definition.group} group</Link>
            </span>
          </ContentHeader.Item>
        </ContentHeader.Column>

        <ContentHeader.Column>
          <ContentHeader.Item>
            <span>Created at:</span>
            {moment.unix(definition.created_at).format('MMM D YYYY, HH:mm')}
          </ContentHeader.Item>
          <ContentHeader.Item>
            <span>Updated at:</span>
            {moment.unix(definition.updated_at).format('MMM D YYYY, HH:mm')}
          </ContentHeader.Item>
        </ContentHeader.Column>
      </ContentHeader.Container>
    );
  }
}

@inject('store')
@observer
export class ModelDoc extends React.Component {
  componentDidMount() {
    if (!this.props.store.SchemaDocs.loaded && !this.props.store.SchemaDocs.loading) {
      this.props.store.SchemaDocs.fetch();
    }

    const schema = this.props.model.getSchema(this.props.schema);
    if (!schema.loaded && !schema.loading) {
      schema.fetch();
    }
  }

  render() {
    const schema = this.props.model.getSchema(this.props.schema);

    if (!this.props.store.SchemaDocs.loaded || !schema.loaded) {
      return <Spinner />;
    }

    return <TextEditor syntax="json" value={JSON.stringify(schema.obj, null, 4)} readOnly />;
  }
}

@inject('store')
@observer
export default class ModelPage extends React.Component {
  componentDidMount() {
    // NOTE: the code below was copied as is from Models.jsx
    // TODO(e0ne): load only one model
    if (!this.props.store.Models.loaded && !this.props.store.Models.loading) {
      this.props.store.Models.fetch();
    }
  }

  getModel = () => {
    const modelOrg = this.props.match.params.modelOrg;
    const modelGroup = this.props.match.params.modelGroup;
    const modelIdentifier = this.props.match.params.modelIdentifier;
    const fullName = `${modelOrg}::${modelGroup}/${modelIdentifier}`;

    return this.props.store.Models.getByIdentifier(fullName);
  };

  render() {
    if (!this.props.store.Models.loaded) {
      return <Spinner />;
    }
    const model = this.getModel();

    const subTitle = [
      // FIXME(andreykurilin): the filtering should be done by fullOrgName
      <Link to={`/catalog/models?group=${model.group}`}>
        {model.organization}::{model.group}
      </Link>,
      this.props.match.params.modelIdentifier,
    ];

    return (
      <TabViewsSwitcher>
        <PageHeader to="/catalog/models" title="Models" subTitle={subTitle} documentTitle={model.fullName} />

        <TabViewsHeader>
          <ModelHeader model={model} />
        </TabViewsHeader>

        <TabView title="Definition" url="definition" key="def">
          <ModelDoc key="def" model={model} schema="definition" />
        </TabView>

        <TabView title="Spec" url="spec" key="spec">
          <ModelDoc key="spec" model={model} schema="spec" />
        </TabView>

        <TabsGroup>
          <TabView title="JSONSchema" url="jsonschema" key="jsonschema">
            <ModelDoc key="jsonschema" model={model} schema="jsonschema" />
          </TabView>

          <TabView title="JSONSchema:Create" url="jsonschema-create" key="create">
            <ModelDoc key="create" model={model} schema="jsonschema:create" />
          </TabView>

          <TabView title="JSONSchema:Update" url="jsonschema-update" key="update">
            <ModelDoc key="update" model={model} schema="jsonschema:update" />
          </TabView>

          <TabView title="JSONSchema:Delete" url="jsonschema-delete" key="delete">
            <ModelDoc key="delete" model={model} schema="jsonschema:delete" />
          </TabView>
        </TabsGroup>
      </TabViewsSwitcher>
    );
  }
}
