import React from 'react';
import PropTypes from 'prop-types';

import { UUID4 } from 'lib/uuid-utils';

const Checkbox = (props) => {
  // special ID to associate <label /> with <select />
  const someId = UUID4();

  return (
    <div className="checkbox bounce">
      <input
        type="checkbox"
        id={someId}
        checked={props.checked}
        onClick={props.onChange}
        disabled={props.disabled}
        readOnly
      />
      <svg viewBox="0 0 21 21">
        <polyline points="5 10.75 8.5 14.25 16 6" />
      </svg>
      <label htmlFor={someId}>{props.label}</label>
    </div>
  );
};

Checkbox.propTypes = {
  label: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Checkbox;
