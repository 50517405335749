import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

import * as queryString from 'lib/query-string';

import { RightSideBar, RightSideBarItem } from 'components/RightSideBar';
import { UserRoles, SystemUserRole, UserStates } from 'Constants';

@inject('profile', 'org')
@observer
class AdminControlItem extends React.Component {
  render() {
    if (!this.props.profile.isLogged && this.props.profile.user.role !== UserRoles.ADMIN) {
      return null;
    }
    const opsAreDisabled =
      this.props.profile.user.email === this.props.user.email ||
      !this.props.org.Info.loaded ||
      this.props.user.email === this.props.org.Info.owner ||
      this.props.user.role === SystemUserRole;
    let primaryButton;
    if (this.props.user.state === UserStates.INVITED) {
      primaryButton = (
        <button
          type="button"
          className="btn btn-primary"
          onClick={this.props.user.resendInvitation}
          disabled={this.props.user.processing}
        >
          Re-send invitation
        </button>
      );
    } else {
      primaryButton = (
        <button
          type="button"
          className="btn btn-primary"
          onClick={this.props.user.toggleActiveState}
          disabled={this.props.user.processing}
        >
          {this.props.user.state === UserStates.SUSPENDED ? 'Activate' : 'Suspend'}
        </button>
      );
    }

    return (
      <RightSideBarItem>
        <form className="form-box">
          <div className="form-item">
            {!opsAreDisabled && primaryButton}
            {!opsAreDisabled && (
              <button
                type="button"
                className="btn btn-default"
                onClick={this.props.user.delete}
                disabled={this.props.user.processing}
              >
                Delete
              </button>
            )}
          </div>
        </form>
      </RightSideBarItem>
    );
  }
}

@withRouter
@inject('org')
@observer
export default class extends React.Component {
  componentDidMount() {
    // this sidebar is not expected to be called outside the users list page
    //  and it already implements loading of users, so not check here is needed
    if (!this.props.org.Info.loaded && !this.props.org.Info.loading) {
      this.props.org.Info.fetch();
    }
  }

  render() {
    const query = queryString.parse(this.props.location.search);
    if (!query.selected || !this.props.org.Users.loaded) {
      return null;
    }
    const user = this.props.org.Users.getLoadedUser(query.selected);
    if (!user || user.isDeleted) {
      return null;
    }
    const queryToCloseSidebar = Object.assign({}, query);
    delete queryToCloseSidebar.selected;

    let redirectToAfterClose = this.props.location.pathname;
    if (queryToCloseSidebar) {
      redirectToAfterClose = `${redirectToAfterClose}?${queryString.stringify(queryToCloseSidebar)}`;
    }
    const isOwner = this.props.org.Info.owner === user.email;

    return (
      <RightSideBar>
        <RightSideBarItem>
          <div className="form-box">
            <form>
              <Link to={redirectToAfterClose} type="btn" className="btn-primary">
                Close
              </Link>
            </form>
          </div>
        </RightSideBarItem>
        <RightSideBarItem className="header">
          <div className="avatar" />
          <div className="name">
            <div>{user.fullName}</div>
            <div>{user.role}</div>
            {isOwner && <div>[Owner of the Organization]</div>}
          </div>
        </RightSideBarItem>
        <AdminControlItem user={user} />
      </RightSideBar>
    );
  }
}
