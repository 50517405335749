import pathValidatorFromLib from 'valid-path';

import InputStore from 'components/forms/Input/InputS';

const pathValidator = (value) => {
  if (!value.startsWith('/')) {
    return 'Path should start from /';
  }

  const res = pathValidatorFromLib(value, {
    // we do not want to accept Drive letters
    allowDriveLetter: false,
    // glob patterns look like 'a/*/*.js'. we do not need this
    allowGlobPatterns: false,
    // we do not need such restriction
    allowForbiddenWindowsNames: true,
    // forbidden chars are: /, \, <, >, :, ", *, ?, |
    // note: yes, typo is done intentionally in correlation to docs :)
    allowFobiddenWindowsChars: false,
    // forbidden chars are: \0 (NULL byte), /
    allowForbiddenUnixChars: false,
  });
  return res.valid ? null : res.error;
};

const fileNameValidator = (value) => (value.endsWith('/') ? 'File name should not be empty' : null);

export default InputStore.named('FilePathInputStore').actions((self) => ({
  afterCreate() {
    self.addInputValidator(pathValidator);
    self.addInputValidator(fileNameValidator);
    if (self.value) {
      self.validate();
    }
  },
}));
