import PropTypes from 'prop-types';
import React from 'react';

import { UUID4 } from 'lib/uuid-utils';

const ItemDefinition = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

const Radio = (props) => {
  // special ID to associate <input /> with <label />
  const someId = UUID4();

  return (
    <div className="radio bounce">
      <input
        type="radio"
        id={someId}
        value={props.value}
        disabled={props.disabled}
        checked={props.selected}
        onClick={() => props.onSelect && props.onSelect(props.value)}
        // add dummy onChange handler to suppress warning at browser console
        onChange={() => {}}
      />
      <svg viewBox="0 0 18 18">
        <circle cx="9" cy="9" r="4" />
      </svg>
      <label htmlFor={someId}>{props.label}</label>
    </div>
  );
};

Radio.propTypes = {
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  ...ItemDefinition,
};

Radio.defaultProps = {
  selected: false,
};

const FieldSet = (props) => {
  let className;
  if (props.horizontalView) {
    className = 'fieldset-on-line';
  } else {
    className = 'fieldset';
  }

  let selected = props.selected;
  if (selected === undefined) {
    selected = props.items[0].value;
    if (props.onSelect) {
      props.onSelect(selected);
    }
  }

  return (
    <fieldset className={className}>
      {props.items.map((item) => (
        <Radio key={item.value} {...item} selected={item.value === selected} onSelect={props.onSelect} />
      ))}
    </fieldset>
  );
};

FieldSet.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(ItemDefinition)),
  horizontalView: PropTypes.bool,
  onSelect: PropTypes.func,
  selected: PropTypes.string,
};

FieldSet.defaultProps = {
  horizontalView: false,
};

export default FieldSet;
