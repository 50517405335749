import { types } from 'mobx-state-tree';

import { InputStore } from 'components/forms/Input';
import isUUID from 'validator/lib/isUUID';

export default types
  .model('GlobalSearch', {
    defaultQuery: "inherits('std::types/Versionable:1')",
    input: types.optional(InputStore, () =>
      InputStore.create({
        formItemClassName: 'search-button header-search',
        placeholder: 'Search all entries',
        releaseFocusOnEnterPress: true,
      })
    ),
    hideDeleted: true,
    showTooltip: false,
  })
  .actions((self) => ({
    afterCreate() {
      self.input.registerOnFocusHandler(self.onFocus);
      self.input.registerOnFocusOutHandler(self.onFocusOut);
    },
    onFocus() {
      self.showTooltip = true;
    },
    onFocusOut() {
      self.showTooltip = false;
    },
    toggleHideDeleted() {
      self.hideDeleted = !self.hideDeleted;
    },
    setValue(value) {
      self.input.setValue(value);
    },
  }))
  .views((self) => ({
    get value() {
      return self.input.value || self.defaultQuery;
    },
    get enteredValueIsJustAValue() {
      return (
        self.input.value !== null &&
        !["'", 'AND', 'and', 'IN', 'CONTAINS', 'IsSet', 'IsNotSet', '<', '>', '='].some((v) =>
          self.input.value.includes(v)
        )
      );
    },
    get query() {
      const queryParts = [];

      if (self.hideDeleted) {
        queryParts.push("isNotSet('std::types/Versionable:1.deletedAt')");
      }

      if (self.enteredValueIsJustAValue) {
        if (isUUID(self.value)) {
          queryParts.push(`std::types/Root:1.id == '${self.value}'`);
        } else {
          queryParts.push(`search('${self.value}')`);
        }
      } else {
        queryParts.push(self.value);
      }
      return queryParts.join(' AND ');
    },
  }));
