import React, { useEffect, useState } from 'react';

import {
  VerTableStore,
  ColumnText,
  ColumnRecordLink,
  ColumnReferences,
  ColumnTimestamp,
  ColumnCustom,
  ColumnModel,
} from 'components/table/TableS';
import { Table } from 'components/table/Table';

import { observer, inject } from 'mobx-react';

const TerminalSessionTab = inject(
  'store',
  'instance'
)(
  observer((props) => {
    const query = `inherits('std::host/Session:1') AND std::types/Auditable:1.what == '${props.record.root_1.id}'`;
    const [store] = useState(
      VerTableStore.create({
        paginationDefaultRowsPerPage: 25,
        paginationRowsPerPageOptions: [25, 50, 100],
        selectable: false,
        query: query,
        columns: [
          ColumnModel.create({}),
          ColumnCustom.create({ name: 'State', opts: { width: '80px' } }).setRender((row) => {
            const state = row.data.get('std::host/Session:1').get('state');

            const cls = {
              connecting: 'success',
              open: 'success',
              'failed-to-connect': 'danger',
              closed: 'unknown',
            }[state];
            return <div className={`entity-status bg-${cls}`} title={state} />;
          }),
          ColumnRecordLink.create({ name: 'Session' }),
          ColumnText.create({ name: 'Traffic In', key: 'std::host/Session:1.trafficIn', unit: 'bytes' }),
          ColumnText.create({ name: 'Traffic Out', key: 'std::host/Session:1.trafficOut', unit: 'bytes' }),
          ColumnReferences.create({ name: 'Initiator', keys: ['std::host/Session:1.user'] }),
          ColumnTimestamp.create({ name: 'Started At', key: 'std::host/Session:1.startedAt' }),
          ColumnTimestamp.create({ name: 'Finished At', key: 'std::host/Session:1.finishedAt' }),
        ],
      })
    );

    useEffect(() => {
      store.setUp({ instance: props.instance, transport: props.store.TransportLayer });
    }, []);

    return <Table store={store} />;
  })
);

TerminalSessionTab.suites = (record) => record.host_1 || record.container_1;
export default TerminalSessionTab;
