import React from 'react';
import PropTypes from 'prop-types';

import ThirdPartyEditor from 'react-simple-code-editor';
import { highlight as highlightWithPrismJS, languages as PrismJSLanguages } from 'prismjs/components/prism-core';
import 'prismjs/themes/prism.css'; // Example style, you can use another
import copy from 'copy-to-clipboard';

import { FormItemForInput } from 'components/forms/FormItem';
import Icon from 'components/Icon';

class TextEditor extends React.Component {
  static supportedSyntaxes = [
    { name: '', title: 'Text', fileTypes: ['txt'] },
    { name: 'bash', title: 'Bash', fileTypes: ['sh'] },
    { name: 'python', title: 'Python', fileTypes: ['py'] },
    { name: 'yaml', title: 'YAML', fileTypes: ['yaml', 'yml'] },
    { name: 'json', title: 'JSON', fileTypes: ['json'] },
  ];

  constructor(props) {
    super(props);
    this.state = { value: props.value || '' };
  }

  highlight = (value) => {
    if (this.props.syntax && PrismJSLanguages.hasOwnProperty(this.props.syntax)) {
      value = highlightWithPrismJS(value, PrismJSLanguages[this.props.syntax]);
    }
    if (this.props.displayLineNumbers) {
      const codeLines = value.split('\n');
      const codeLinesWithNumbers = codeLines.map((line, i) => `<span class='editorLineNumber'>${i + 1}</span>${line}`);
      return codeLinesWithNumbers.join('\n');
    }
    return value;
  };

  onChange = (value) => {
    this.setState(() => ({ value: value }));
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  render() {
    return (
      <FormItemForInput label={this.props.label} description={this.props.description}>
        <div className="item-container">
          <div className={`text-editor ${this.props.displayLineNumbers ? 'text-editor-with-line-numbers' : ''}`}>
            <ThirdPartyEditor
              value={this.state.value}
              onValueChange={this.onChange}
              highlight={this.highlight}
              padding={10}
              readOnly={this.props.readOnly}
              preClassName={
                !this.props.readOnly && !this.props.withoutMinHeight ? 'text-editor-with-min-height' : undefined
              }
            />
            {!this.props.excludeCopyButton && (
              <span key="copy-icon">
                <button type="button" className="btn btn-link btn-copy" onClick={() => copy(this.state.value)}>
                  <Icon className="copy" />
                </button>
              </span>
            )}
          </div>
        </div>
      </FormItemForInput>
    );
  }
}

TextEditor.supportedSyntaxes.map((syntax) => syntax.name && import(`prismjs/components/prism-${syntax.name}`));

TextEditor.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  // enteredValue
  value: PropTypes.string,
  // the syntax type to use for highlighting the ext
  syntax: PropTypes.oneOf(TextEditor.supportedSyntaxes.map((syntax) => syntax.name)),
  // whether to add line numbers to each line
  displayLineNumbers: PropTypes.bool,
  // convert editor into viewer
  readOnly: PropTypes.bool,
  // handler for on change event
  onChange: PropTypes.func,
  //
  excludeCopyButton: PropTypes.bool,
  // min-height is applied by-default for editable forms
  withoutMinHeight: PropTypes.bool,
};

TextEditor.defaultProps = {
  displayLineNumbers: false,
  readOnly: false,
  excludeCopyButton: false,
  withoutMinHeight: false,
};

export default TextEditor;
