import { getParentOfType, types } from 'mobx-state-tree';
import moment from 'moment';
import * as queryString from 'lib/query-string';

import { InputStore } from 'components/forms/Input';
import { Store } from 'stores/Store';

export const SCHEMAS = Object.fromEntries(
  ['definition', 'spec', 'jsonschema', 'jsonschema:create', 'jsonschema:update', 'jsonschema:delete'].map((k) => [k, k])
);

const ModelSchema = types
  .model('ModelSchema', {
    name: types.string,
    obj: types.maybeNull(types.frozen()),
    loaded: false,
    loading: false,
  })
  .views((self) => ({
    get urlSchemaPart() {
      return self.name.replace(':', '?action=');
    },
    get modelFullName() {
      return getParentOfType(self, Model).fullName;
    },
  }))
  .actions((self) => ({
    finishLoading() {
      self.loading = false;
    },
    fetch() {
      self.loading = true;

      getParentOfType(self, Store).TransportLayer.get({
        url: `/m/api/v1/models/${self.modelFullName}/${self.urlSchemaPart}`,
        onSuccess: (response, response_data) => {
          self.setSchema(response_data.data);
        },
        onFinish: self.finishLoading,
      });
    },
    setSchema(schema) {
      self.loaded = true;
      self.obj = schema;
    },
  }));

const Model = types
  .model('Model', {
    organization: types.string,
    group: types.string,
    name: types.string,
    version: types.integer,
    // a container for definition, spec and different jsonschemas
    _schemas: types.optional(types.map(ModelSchema), {}),
  })
  .views((self) => ({
    get fullName() {
      return `${self.organization}::${self.group}/${self.name}:${self.version}`;
    },
    get pictureUrl() {
      return getParentOfType(self, Models).getPicture(self.fullName);
    },
    get definition() {
      return self.getSchema(SCHEMAS.definition);
    },
    get inherits() {
      return self.definition.obj.inherits;
    },
    get parts() {
      return self.definition.obj.parts;
    },
    get mCreatedAt() {
      return moment.unix(self.definition.obj.createdAt);
    },
  }))
  .actions((self) => ({
    getSchema(name) {
      if (!self._schemas.has(name)) {
        self._schemas.set(name, ModelSchema.create({ name: name }));
      }
      return self._schemas.get(name);
    },
  }));

export const ModelsPageFilters = types
  .model('ModelsPageFilters', {
    filter: types.optional(types.map(types.string), { name: '', group: '' }),
    nameFilter: types.optional(InputStore, () =>
      InputStore.create({
        className: 'search',
        type: 'search',
        placeholder: '',
        label: 'Filter by name',
      })
    ),
    groupFilter: types.maybeNull(types.string),
    sortByField: 'fullName',
    reverseOrder: false,
  })
  .volatile(() => ({
    modelsStore: null,
  }))
  .views((self) => ({
    get queryParams() {
      const params = {};
      if (self.nameFilter.value) {
        params.name = self.nameFilter.value;
      }
      if (self.groupFilter) {
        params.group = self.groupFilter;
      }
      return queryString.stringify(params);
    },
    get items() {
      if (self.modelsStore === null) {
        return [];
      }
      return self.modelsStore.items.filter((item) => {
        const groupMatch = self.groupFilter === null || item.group === self.groupFilter;
        const nameMatch = !self.nameFilter.value || item.fullName.toLowerCase().includes(self.nameFilter.value);
        return groupMatch === true && nameMatch === true;
      });
    },
    get allGroups() {
      const items = [
        // first item is value, second is title
        ['', 'All groups'],
      ];
      const groups = new Set(self.modelsStore.items.map((m) => m.group));
      groups.forEach((g) => items.push([g, g]));
      return items;
    },
  }))
  .actions((self) => ({
    setModelsStore(store) {
      self.modelsStore = store;
    },
    sortBy(sortField, reverseOrder) {
      self.sortByField = sortField;
      self.reverseOrder = reverseOrder;
    },
    setGroupFilter(value) {
      self.groupFilter = value || null;
    },
    setNameFilter(value) {
      self.nameFilter.setValue(value ? value.toLowerCase() : '');
    },
  }));

export const Models = types
  .model('Models', {
    items: types.array(Model),
    loaded: false,
    loading: false,
  })
  .views((self) => ({
    getByIdentifier(identifier) {
      return self.items.find((m) => m.fullName === identifier);
    },
    getPicture(fullName) {
      const token = getParentOfType(self, Store).Profile.token;
      return `/m/api/v1/models/${fullName}/picture.svg?token=${token}`;
    },
  }))
  .actions((self) => ({
    fetch() {
      self.loading = true;
      getParentOfType(self, Store).TransportLayer.get({
        url: '/m/api/v1/models/',
        onSuccess: (response, response_data) => {
          self.pushItems(response_data.data);
        },
      });
    },
    pushItems(data) {
      self.loading = false;
      self.items = data.map((definition) => {
        const model = Model.create({
          type: definition.type,
          organization: definition.organization,
          group: definition.group,
          name: definition.name,
          version: definition.version,
        });
        model.getSchema('definition').setSchema(definition);
        return model;
      });
      self.loaded = true;
    },
  }));

const SchemaKeyword = types.model('SchemaKeyword', {
  name: types.string,
  doc: types.string,
  examples: types.array(types.string),
});

const SchemaFieldType = types.model('SchemaFieldType', {
  name: types.string,
  doc: types.string,
  keywords: types.array(types.string),
});

const ModelSchemaField = types.model('ModelSchemaField', {
  name: types.string,
  type: types.string,
  description: types.string,
});

export const SchemaDocs = types
  .model('ModelDocs', {
    keywords: types.array(SchemaKeyword),
    types: types.array(SchemaFieldType),
    fields: types.array(ModelSchemaField),
    loaded: false,
    loading: false,
    selected: '',
  })
  .views((self) => ({
    keyword_names() {
      return self.keywords.map((kw) => kw.name);
    },
    field_names() {
      return self.fields.map((kw) => kw.name);
    },
  }))
  .actions((self) => ({
    fetch() {
      self.loading = true;
      getParentOfType(self, Store).TransportLayer.get({
        url: '/m/api/v1/v0-model-docs/',
        onSuccess: (response, response_data) => {
          self.pushItems(response_data.data);
        },
      });
    },
    pushItems(data) {
      self.loaded = true;
      self.loading = false;
      self.keywords = data.keywords.map((model_kw_def) => SchemaKeyword.create(model_kw_def));
      self.types = data.types.map((model_type_def) => SchemaFieldType.create(model_type_def));

      const model = data.definitions.model;
      Object.keys(model).forEach((k) => self.fields.push({ name: k, ...model[k] }));
    },
    getKeyword(name) {
      return self.keywords.find((kw) => kw.name === name);
    },
  }));

export default Models;
