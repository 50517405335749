import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { InstanceLink } from 'components/Nav';
import { PageHeader, ContainerBox } from 'components/Page';
import {
  VerTableStore,
  ColumnRecordLink,
  ColumnCustom,
  ColumnLabels,
  ColumnModel,
  ColumnReferences,
  ColumnTimestamp,
  ColumnInventoryRecordStatus,
  loadRecodsWithStatusStats,
} from 'components/table/TableS';
import { Table } from 'components/table/Table';

export default inject(
  'store',
  'instance'
)(
  observer((props) => {
    const query = "inherits('std::host/Host:1') AND isNotSet('std::types/Versionable:1.deletedAt')";

    const [store] = useState(
      VerTableStore.create({
        paginationDefaultRowsPerPage: 25,
        paginationRowsPerPageOptions: [25, 50, 100],
        selectable: false,
        query: query,
        columns: [
          ColumnModel.create({}),
          ColumnInventoryRecordStatus.create({ name: 'Status', opts: { width: '100px' } }),
          ColumnRecordLink.create({
            name: 'Name',
            key: 'std::types/Root:1.id',
            sortKey: 'std::types/Inventory:1.displayName',
          }),
          ColumnCustom.create({ name: 'Access' }).setRender((row) => {
            const host = row.data.get('std::host/Host:1');
            return `${host.get('accessUser')}@${host.get('accessIP')}`;
          }),
          ColumnReferences.create({ name: 'Agent', keys: ['std::types/Root:1.app'] }),
          ColumnLabels.create({ name: 'Labels', key: 'std::types/Root:1.labels', isolatedSearch: true }),
          ColumnTimestamp.create({ name: 'Updated', key: 'std::types/Versionable:1.updatedAt' }),
        ],
      })
    );

    useEffect(() => {
      loadRecodsWithStatusStats(store);
      store.setUp({ instance: props.instance, transport: props.store.TransportLayer });
    }, []);

    return (
      <ContainerBox>
        <PageHeader title="Hosts">
          <InstanceLink to="/hosts/register" className="btn btn-primary">
            Register new host
          </InstanceLink>
        </PageHeader>

        <Table store={store} />
      </ContainerBox>
    );
  })
);
