import { types } from 'mobx-state-tree';

import PasswordValidator from 'password-validator';

import InputStore from 'components/forms/Input/InputS';

export const makePasswordEqualityValidator = (primaryPasswordInputStore) => {
  return (value) => {
    if (value !== primaryPasswordInputStore.value) {
      return 'Passwords are not equal.';
    }
  };
};

const ValidationSchema = types
  .model('ValidationSchema', {
    minLength: 8,
    restrictFirstSpaceChar: true,
    restrictLastSpaceChar: true,
    minDigits: 1,
  })
  .volatile(() => ({
    validator: null,
  }))
  .actions((self) => ({
    validate(value) {
      const result = self.validator.validate(value, { details: true });
      if (result.length > 0) {
        return result[0].message.replace('The string should', 'Password should');
      }
    },
    afterCreate() {
      self.validator = new PasswordValidator();
      if (self.minLength) {
        self.validator.is().min(self.minLength);
      }
      if (self.minDigits) {
        self.validator.has().digits(self.minDigits);
      }
      if (self.restrictFirstSpaceChar) {
        self.validator.usingPlugin((value) => !value.startsWith(' '), 'Password should not start with a space char');
      }
      if (self.restrictLastSpaceChar) {
        self.validator.usingPlugin((value) => !value.endsWith(' '), 'Password should not end with a space char');
      }
    },
  }));

export default InputStore.named('PasswordInputStore')
  .props({
    showPassword: false,
    // override parent's default values
    inputType: 'password',
    inputClassName: 'password',
    label: 'Password',
    onChangeHandlingDelay: 0,
    schema: types.optional(types.maybeNull(ValidationSchema), null),
  })
  .actions((self) => ({
    toggleShowPassword(e) {
      e.stopPropagation();
      e.preventDefault();

      self.showPassword = !self.showPassword;
      if (self.showPassword) {
        self.inputType = 'text';
      } else {
        self.inputType = 'password';
      }
    },
    afterCreate() {
      if (self.schema) {
        self.addInputValidator(self.schema.validate);
      }
    },
  }));
