import React from 'react';
import { observer, inject } from 'mobx-react';

import Input from 'components/forms/Input';
import TextEditor from 'components/input/TextEditor';
import Item from 'components/Item';
import { Spinner } from 'components/Loader';
import { ContainerBox, PageHeader } from 'components/Page';
import { LaunchNewForm } from 'stores/Instances/Applications';
import { InstanceLink } from 'components/Nav';

const Step1 = inject('instance')(
  observer((props) => {
    if (props.form.creatingEntity) {
      return <Spinner />;
    }
    return (
      <>
        <form
          className="form-box"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="form-item">
            <div className="item-container">
              <select name="agent-type" disabled>
                <option>std::Hosts:1</option>
              </select>
              <label htmlFor="agent-type">Type</label>
            </div>
          </div>
          <Input key="name" store={props.form.name} />
          <div className="message-placeholder text-danger">{props.form.error}</div>
          <h4> Optional </h4>
          <Input key="external-id" store={props.form.externalID} />
          <Input key="description" store={props.form.description} />
        </form>
        {props.form.creatingEntity && (
          <div className="notification ntf-wait">
            <Item />
          </div>
        )}
        <div className="steps-action">
          <InstanceLink to="/agents" className="btn btn-default">
            Cancel
          </InstanceLink>
          <button
            type="button"
            className="btn-primary"
            onClick={() => {
              props.form.tryCreate(props.instance.Applications);
            }}
            disabled={props.form.creatingEntity || !props.form.name.isDone()}
          >
            Next
          </button>
        </div>
      </>
    );
  })
);

const Step2 = inject('instance')(
  observer((props) => {
    const value = [
      '# ITLook config with access key, keep it safe',
      `export ITLOOK_CONFIG="${props.form.secret}"`,
      '',
      '# Run agent using docker',
      'docker run -d -e ITLOOK_CONFIG=${ITLOOK_CONFIG} registry.itlook.io/app-hosts:master itlook-host-app',
    ].join('\n');

    return (
      <>
        <h4>Run application:</h4>
        <TextEditor syntax="bash" value={value} readOnly />
        <div className="steps-action">
          <InstanceLink className="btn btn-primary" to="/agents?success=A new agent is successfully created.">
            Done
          </InstanceLink>
        </div>
      </>
    );
  })
);

// this step doesn't exist yet
const HeaderStepItem = observer((props) => {
  let className = 'disabled';
  if (props.step < props.form.step) {
    className = 'done';
  } else {
    if (props.step === props.form.step) {
      className = 'current';
    }
  }
  return (
    <div className={className}>
      <div className="sign" />
      <div>{props.children}</div>
    </div>
  );
});

@inject('store')
@observer
export default class extends React.Component {
  constructor(props) {
    super(props);
    this.form = LaunchNewForm.create({ applicationType: 'std::Hosts:1', step: '1' });
  }

  render() {
    return (
      <ContainerBox>
        <PageHeader to="/agents" title="Agents" subTitle="Create new" documentTitle="Agents" instanceLink />
        <div className="steps">
          {/* steps of HeaderStepItem props correspond to global steps that
              are Application global steps. For agents we have less of them,
              but do not want to reduplicate the code of the store.
            */}
          <HeaderStepItem form={this.form} step="1">
            <span>1. Create Agent</span>
          </HeaderStepItem>
          <HeaderStepItem form={this.form} step="2">
            <span>2. Run Agent</span>
          </HeaderStepItem>
        </div>

        {this.form.step === '1' && <Step1 form={this.form} />}
        {this.form.step === '2' && <Step2 form={this.form} />}
      </ContainerBox>
    );
  }
}
