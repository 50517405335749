import React from 'react';
import { observer } from 'mobx-react';

import { PieChart as PieChartRe, Tooltip, Pie, Legend, LabelList, Label, ResponsiveContainer } from 'recharts';
import variables from 'styles/_variables.module.scss';

import { Spinner } from 'components/Loader';

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div className="tooltip">
        <p className="label">
          {data.name}({data.value})
        </p>
      </div>
    );
  }

  return null;
};

export const PieChart = observer((props) => {
  const store = props.store;

  if (store.loading) {
    return <Spinner />;
  }

  return (
    <ResponsiveContainer width="100%" height={300} className="chart">
      <PieChartRe width={290} height={300}>
        <Pie
          data={store.dataInCircle}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius={70}
          outerRadius={90}
          paddingAngle={2}
          stroke="transparent"
        >
          <Label position="center" width={30} fill={variables.textSub} stroke={variables.textSub}>
            {store.label}
          </Label>
          <LabelList dataKey="value" offset={-44} position="outside" />
        </Pie>
        <Pie
          data={store.dataOutCircle}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius={93}
          outerRadius={103}
          paddingAngle={2}
          stroke="transparent"
          fill={variables.chartBlue}
        >
          <LabelList dataKey="value" offset={12} position="outside" />
        </Pie>
        <Tooltip wrapperStyle={{ outline: 'none' }} content={<CustomTooltip />} />
        <Legend
          iconType="circle"
          payload={store.dataInCircle.map((el) => ({
            value: el.name,
            type: 'circle',
            id: '1',
            color: el.fill,
          }))}
        />
      </PieChartRe>
    </ResponsiveContainer>
  );
});
