import React from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

import PropTypesUtils from 'lib/propTypes-utils';
import { TabsBar, TabSelector, TabSelectorWithDropdown } from 'components/TabsBar';
import { PageHeader, ContainerBox } from 'components/Page';

/// ///////////////////////////////////////////////////////////////////////////
//                                                                           //
//                            WARNING!!!                                     //
//                                                                           //
// All components should be used in the following way:                       //
//                                                                           //
//    <TabViewsSwitcher>                                                     //
//                                                                           //
//      // it is a global default header that can be overridden by the tab   //
//      <PageHeader title="The page! O_o" />                                 //
//                                                                           //
//      <TabView title="Info" key="info" url="info">                         //
//        <MyInfo />                                                         //
//      </TabView>                                                           //
//                                                                           //
//      <TabView title="Settings" key="settings" url="settings" onlyAdmin>   //
//        // custom tab specific page header that will override default one  //
//        <PageHeader title="My super settings" />                           //
//        <TabViewContent>                                                   //
//          <MySettings />                                                   //
//        </TabViewContent>                                                  //
//      </TabView>                                                           //
//                                                                           //
//    </TabViewsSwitcher>                                                    //
//                                                                           //
//  Rules:                                                                   //
//                                                                           //
//    1) You cannot put TabView in the separate component, otherwise it will //
//       not by discoverable as a child of <TabViewsSwitcher /> and it will  //
//       be impossible to display it correctly                               //
//    2) If view doesn't override/set page header (Info tab in the example), //
//       all children of TabView component will be considered as content of  //
//       the tab.                                                            //
//       If view overrides/sets page header (Settings tab in the example),   //
//       content of the tab should be wrapped by TabViewContent component    //
//                                                                           //
/// ///////////////////////////////////////////////////////////////////////////

// Global header that is displayed for all tabs before tba selector.
export const TabViewsHeader = (props) => props.children;

export const TabViewContent = (props) => props.children;

export const TabView = (props) => props.children;

TabView.propTypes = {
  // NOTE(andreykurilin): The component itself doesn't use these properties, but
  // they are required for parent class to categorise the views.

  // The 'title' that will be set at the tab bar.
  title: PropTypes.string.isRequired,

  url: PropTypes.string.isRequired,

  // The flag that disables and hides the tab
  hidden: PropTypes.bool,

  // A helper that set hidden=true if the person is not an admin
  onlyAdmin: PropTypes.bool,

  // Put the tab into addition section
  addition: PropTypes.bool,

  // Adds a copy icon at the end (works only for addition tab)
  copyValue: PropTypes.string,

  children: PropTypes.any,
  // children: PropTypes.oneOfType([
  //  PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.instanceOf(PageHeader), PropTypes.instanceOf(TabViewContent)])),
  //  PropTypes.instanceOf(PageHeader),
  //  PropTypes.instanceOf(TabViewContent),
  // ]),
};

export const TabsGroup = (props) => props.children;

TabsGroup.propTypes = {
  // NOTE(andreykurilin): The component itself doesn't use onlyAdmin, but
  //  it is required for parent class to categorise the views.

  onlyAdmin: PropTypes.bool,

  children: PropTypes.arrayOf(PropTypesUtils.elementOfType(TabView)),
};

const TabSelectorFactory = (props) => {
  if (!Array.isArray(props.item)) {
    return (
      <TabSelector key={props.item.title} title={props.item.title} link={props.item.url} active={props.item.isActive} />
    );
  }

  let selected;
  let active;

  props.item.some((item, index) => {
    if (item.isActive) {
      selected = index;
      active = true;
      return true;
    }
    return false;
  });

  return (
    <TabSelectorWithDropdown
      items={props.item.map((view) => ({ title: view.title, link: view.url }))}
      active={active}
      selected={selected}
    />
  );
};

@withRouter
@inject('profile')
@observer
export class TabViewsSwitcher extends React.Component {
  processView = (view) => {
    const fullURL = this.joinURL(this.props.match.url, view.props.url);
    const pURL = this.joinURL(this.props.match.path, view.props.url);

    const viewChildren = React.Children.toArray(view.props.children);

    let pageHeader;
    let content;
    if (viewChildren.some((child) => child.type !== TabViewContent)) {
      // there is something that is not PageHeader or TabViewContent, so all
      // children should be treated as TabViewContent
      content = viewChildren;
    } else {
      content = [];
      viewChildren.forEach((child) => {
        content.push(child);
      });
    }

    return {
      title: view.props.title,
      url: fullURL,
      pURL: pURL,
      pageHeader: pageHeader,
      content: content,
      component: view,
      isActive: this.props.location.pathname === fullURL,
      addition: view.props.addition || false,
    };
  };

  categoriesChildren = () => {
    const isAdmin = this.props.profile.isLogged && this.props.profile.user.isAdmin;

    let defaultPageHeader;
    let beforeTabsContent;
    let addition;
    const views = [];
    const selectors = [];
    const other = [];

    this.props.children.forEach((child) => {
      if (child === null || child === undefined) {
        // some placeholder
      } else if (defaultPageHeader === undefined && child.type === PageHeader) {
        defaultPageHeader = child;
      } else if (beforeTabsContent === undefined && child.type === TabViewsHeader) {
        beforeTabsContent = child;
      } else if (child.type === TabView) {
        if (!child.props.hidden && (!child.props.onlyAdmin || isAdmin)) {
          const view = this.processView(child);
          views.push(view);
          if (view.addition) {
            if (addition) {
              throw new Error('Only one addition tab is supported.');
            }
            addition = view;
          } else {
            selectors.push(view);
          }
        }
      } else if (child.type === TabsGroup) {
        if (!child.props.onlyAdmin || isAdmin) {
          const groupViews = React.Children.toArray(child.props.children).map((groupChild) =>
            this.processView(groupChild)
          );
          views.push(...groupViews);
          selectors.push(groupViews);
        }
      } else {
        other.push(child);
      }
    });

    return { defaultPageHeader, beforeTabsContent, views, selectors, addition, other };
  };

  joinURL(part1, part2) {
    if (part1.endsWith('/')) {
      return `${part1}${part2}`;
    }
    return `${part1}/${part2}`;
  }

  render() {
    const { defaultPageHeader, beforeTabsContent, views, selectors, addition, other } = this.categoriesChildren();

    return (
      <ContainerBox>
        <Switch key="page-header">
          {views.map(
            (view) =>
              view.pageHeader && (
                <Route key={view.title} path={view.pURL} exact>
                  {view.pageHeader}
                </Route>
              )
          )}
          {defaultPageHeader}
        </Switch>

        {beforeTabsContent}
        <TabsBar addition={addition}>
          {selectors.map((item) => (
            <TabSelectorFactory item={item} key={Array.isArray(item) ? item[0].title : item.title} />
          ))}
        </TabsBar>
        <Switch key="page-content">
          {views.map((view) => (
            <Route key={view.title} path={view.pURL} exact>
              {view.content}
            </Route>
          ))}
          {views.length > 0 && <Redirect to={views[0].pURL} />}
        </Switch>
        {other}
      </ContainerBox>
    );
  }
}
