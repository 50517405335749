import React from 'react';

import { inject, observer } from 'mobx-react';

import FormItem from 'components/forms/FormItem';
import Switcher from 'components/forms/Switcher';
import Input from 'components/input/Input';
import { Spinner } from 'components/Loader';
import { UserRoles } from 'Constants';
import { AddNewWildcardDomainForm } from 'stores/Organization/Settings';
import { LeftPanel, SidedPanel } from 'components/layout/Panels';

@inject('org')
@observer
class NewWildcardDomainInputField extends React.Component {
  constructor(props) {
    super(props);
    this.form = AddNewWildcardDomainForm.create({});
  }

  onCreate = () => {
    if (!this.form.domain) {
      this.form.setError('Domain is empty.');
    } else if (this.form.valid) {
      this.props.org.Settings.access.addWildcardDomain(
        this.form.domain,
        this.form.onSuccessCreate,
        this.form.onFailureCreation
      );
    }
  };

  render() {
    return (
      <Input
        enteredValue={this.form.domain}
        onChange={this.form.onChange}
        error={this.form.error ? this.form.error : undefined}
        key={this.form.key}
      >
        <button className="btn-only-icon minus" type="button" onClick={this.form.reset} />
        <button
          key="add"
          className="btn-only-icon plus"
          type="button"
          onClick={this.onCreate}
          disabled={!this.form.valid}
        />
      </Input>
    );
  }
}

const OAuth2WildcardDomainsBox = inject('org')(
  observer((props) => (
    <div className="form-box">
      <FormItem
        label="OAuth2 wildcard domains"
        description="Allow to self-create user account for users with email addresses from the specified domain(s)."
      />
      {props.org.Settings.access.wildcardDomains.map((value, index) => (
        <Input key={`oauth2-wildcard-domain-${value}-${index}`} enteredValue={value} disabled>
          <button
            className="btn-only-icon minus"
            type="button"
            onClick={() => props.org.Settings.access.deleteWildcardDomain(index)}
          />
        </Input>
      ))}
      <NewWildcardDomainInputField
        key={
          /* make key depend on the length of the domain, so onCreate event will clean active input field */
          `oauth2-wildcard-domain-new-${props.org.Settings.access.wildcardDomains.length}`
        }
      />
    </div>
  ))
);

const AccessSettings = inject('org')(
  observer((props) => {
    const settings = props.org.Settings;
    if (!settings.loaded) {
      return <Spinner />;
    }

    return (
      <SidedPanel>
        <LeftPanel>
          <h3>Access</h3>
          <form>
            <div className="form-box">
              <Switcher
                key="oauth2-enabled"
                itemID="oauth2-enabled"
                label="Authentication via OAuth2"
                checked={settings.access.OAuth2Enabled}
                onChange={settings.access.toggleOAuth2Enabled}
              />
            </div>
            <div className="form-box">
              <Switcher
                key="oauth2-only-domain-box"
                itemID="oauth2-only-domain-box"
                label="OAuth2 Only auth"
                description="Restrict authentication via email&password flow."
                checked={settings.access.OAuth2Only}
                onChange={settings.access.toggleOAuth2Only}
                disabled={!settings.access.OAuth2Enabled}
              />
            </div>
            <OAuth2WildcardDomainsBox />
            <div className="form-box">
              <Switcher
                key="oauth2-default-user-role"
                itemID="oauth2-default-user-role"
                label="Assign Admin role to new OAuth2 user."
                description="Whether to assign admin to an unregistered user that logged in via OAuth2 wildcard domain option."
                checked={UserRoles.ADMIN === settings.access.wildcardNewUserRole}
                disabled={!settings.access.OAuth2Enabled}
                onChange={() =>
                  settings.access.setWildcardNewUserRole(
                    UserRoles.ADMIN === settings.access.wildcardNewUserRole ? UserRoles.USER : UserRoles.ADMIN
                  )
                }
              />
            </div>
            <div className="form-box">
              <Input
                description="User creation is allowed to users with email addresses from the specified domain"
                type="text"
                className="domain"
                value={settings.access.wildcardDomains ? settings.access.wildcardDomains[0] : undefined}
                label="Domain"
                onChange={settings.access.setWildcardDomains}
              />
              <div className="item-container">
                <button className="btn btn-primary" type="button" onClick={settings.access.saveWildcardDomains}>
                  Save domain
                </button>
              </div>
            </div>
          </form>
        </LeftPanel>
      </SidedPanel>
    );
  })
);

@inject('org')
@observer
export default class extends React.Component {
  componentDidMount() {
    if (!this.props.org.Settings.loading) {
      this.props.org.Settings.fetch();
    }
  }

  render() {
    return <AccessSettings />;
  }
}
