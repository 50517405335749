import React from 'react';
import { observer } from 'mobx-react';

import * as ContentHeader from 'components/ContentHeader';
import { Description, ID, Labels, Status } from 'pages/Instances/RecordView/Header/CommonItems';

export default observer((props) => (
  <ContentHeader.Container>
    <ContentHeader.Column>
      <Status record={props.record} />
    </ContentHeader.Column>
    <ContentHeader.Column>
      <ID record={props.record} />
    </ContentHeader.Column>
    <ContentHeader.Column>
      <Description description={props.record.inventory_1.description} />
    </ContentHeader.Column>
    <ContentHeader.Column>
      <ContentHeader.Item>
        <span>Access:</span> {props.record.host_1.accessUser}@{props.record.host_1.accessIP}
      </ContentHeader.Item>
    </ContentHeader.Column>
    <ContentHeader.Column>
      <Labels record={props.record} />
    </ContentHeader.Column>
  </ContentHeader.Container>
));
