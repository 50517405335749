import React from 'react';
import PropTypes from 'prop-types';

const ColumnHeader = (props) => {
  if (props.empty) {
    return <th key={props.itemKey} />;
  }

  let onClick;
  let className = '';
  if (props.noselect) {
    onClick = () => {};
  } else {
    onClick = () => props.onClick(props.value);
    if (props.selected) {
      if (!props.reverse) {
        className = 'sortup';
      } else {
        className = 'sortdown';
      }
    }
  }

  return (
    <th>
      <div className={className} key={props.itemKey || props.value} onClick={onClick}>
        {props.title || `${props.value[0].toUpperCase()}${props.value.slice(1)}`}
      </div>
    </th>
  );
};

export const TableRow = (props) => (
  <tr style={props.style} onClick={props.onClick} title={props.title}>
    {props.children}
  </tr>
);

TableRow.propTypes = {
  style: PropTypes.any,
  title: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.any,
};

export const TableCell = (props) => (
  <td rowSpan={props.rowSpan ?? 1} colSpan={props.colSpan ?? undefined} className={props.className}>
    {props.children}
  </td>
);

TableCell.propTypes = {
  className: PropTypes.string,
  rowSpan: PropTypes.number,
  colSpan: PropTypes.number,
  children: PropTypes.any,
};

export default class Table extends React.Component {
  constructor(props) {
    super(props);
    const selectedItem = this.props.selected || this.props.headers[0].value;
    this.state = {
      selectedItem: selectedItem,
      reverse: this.props.reverseOrderByDefault,
    };
  }

  onColumnHeaderClick = (value) => {
    let reverse;
    if (this.state.selectedItem === value) {
      reverse = !this.state.reverse;
    } else {
      reverse = false;
    }
    this.setState({ selectedItem: value, reverse: reverse });
    this.props.onSortChange(value, reverse);
  };

  render() {
    const classNames = ['table', this.props.className, this.props.noHover ? 'table-no-hover' : null];
    const tableClassName = classNames.filter((c) => c).join(' ');
    return (
      <div className={tableClassName}>
        <table>
          <thead>
            <TableRow key="header">
              {this.props.headers.map((header) => (
                <ColumnHeader
                  key={header.itemKey || header.value}
                  onClick={this.onColumnHeaderClick}
                  selected={this.state.selectedItem === header.value}
                  reverse={this.state.reverse}
                  {...header}
                />
              ))}
            </TableRow>
          </thead>
          <tbody>{this.props.children}</tbody>
        </table>
      </div>
    );
  }
}

Table.propTypes = {
  className: PropTypes.string,
  noHover: PropTypes.bool,
  // selected column. should be a value that matched to listed in headers
  selected: PropTypes.string,
  // reverse order of selected column
  reverseOrderByDefault: PropTypes.bool,
  // headers
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      itemKey: PropTypes.string,
      empty: PropTypes.bool,
      noselect: PropTypes.bool,
      value: PropTypes.string,
    })
  ).isRequired,
  onSortChange: PropTypes.func.isRequired,
  children: PropTypes.any,
};
