import InputStore from 'components/forms/Input/InputS';

export const validateEmail = (value) => {
  // prettier-ignore
  // eslint-disable-next-line no-useless-escape,max-len
  const re = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  if (!value.match(re)) {
    return 'Email has invalid format.';
  }
};

export default InputStore.named('EmailInputStore')
  .props({
    // override parent's default values
    inputType: 'email',
    name: 'email',
    placeholder: 'Email',
    label: 'Email',
  })
  .actions((self) => ({
    afterCreate: () => {
      self.addInputValidator(validateEmail);
    },
  }));
