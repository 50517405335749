import { types } from 'mobx-state-tree';

import { jwtDecode } from 'jwt-decode';

import requests from 'lib/requests';

import { EmailInputStore, InputStore, PasswordInputStore } from 'components/forms/Input';
import { makePasswordEqualityValidator } from 'components/forms/Input/PasswordInputS';
import { LoginActions } from 'Constants';

const UserAgreement = types
  .model('UserAgreement', {
    text: types.string,
    token: types.string,
    error: types.maybeNull(types.string),
    processing: false,
    checkboxIsSelected: false,
  })
  .volatile(() => ({
    onSuccess: () => {},
    onCancel: () => {},
  }))
  .actions((self) => ({
    registerOnSuccess(handler) {
      self.onSuccess = handler;
    },
    registerOnCancel(handler) {
      self.onCancel = handler;
    },
    toggleCheckBox() {
      self.checkboxIsSelected = !self.checkboxIsSelected;
    },
    setProcessing(value) {
      self.processing = value;
    },
    onFailure(response, errors) {
      self.error = errors.join('. ');
    },
    onSubmit() {
      self.setProcessing(true);
      requests.POST({
        url: '/m/api/v1/user-agreement',
        authToken: self.token,
        onFailure: self.onFailure,
        onSuccess: () => self.onSuccess(self.token),
        onFinish: () => {
          self.setProcessing(false);
        },
      });
    },
  }));

export const LoginForm = types
  .model('LoginForm', {
    email: types.optional(EmailInputStore, () => EmailInputStore.create({ autoFocus: true })),
    password: types.optional(PasswordInputStore, () => PasswordInputStore.create()),
    error: types.maybeNull(types.string),
    authenticating: false,
    redirectURL: types.maybeNull(types.string),
    agreement: types.maybeNull(UserAgreement),
  })
  .volatile(() => ({
    setToken: () => {},
    updateURLPath: () => {},
  }))
  .actions((self) => ({
    registerSetToken(handler) {
      self.setToken = handler;
    },
    setUpdateURLPath(updateURLPath) {
      self.updateURLPath = updateURLPath;
    },
    onSuccessLogin(token) {
      self.setToken(token, self.email);
      self.updateURLPath(self.redirectURL || '/');
    },
    setError(message) {
      self.error = message || 'Login failed. Please, try again.';
    },
    setRedirectURL(redirectURL) {
      self.redirectURL = redirectURL || null;
    },
    setAuthenticating(value) {
      self.authenticating = value;
      self.email.setDisabled(value);
      self.password.setDisabled(value);
    },
    setAgreementInfo(responseBody) {
      if (responseBody) {
        self.agreement = UserAgreement.create({
          text: responseBody.data.agreement,
          token: responseBody.data.token,
        });
        self.agreement.registerOnSuccess(self.onSuccessLogin);
        self.agreement.registerOnCancel(() => self.setAgreementInfo());
      } else {
        self.agreement = null;
      }
    },
    login() {
      self.setAuthenticating(true);
      self.error = null;

      requests.POST({
        url: '/m/api/v1/auth/tokens/mgmt-token',
        body: {
          email: self.email.value,
          password: self.password.value,
        },
        onFailure: (response, errors, response_body) => {
          if (response.status < 400) {
            console.log(`An error occurred during login: ${errors.join('. ')}`);
            self.setError(errors.join('. '));
          } else if (response.status === 428) {
            self.setAgreementInfo(response_body);
          } else {
            self.setError('Login failed. Please, try again.');
          }
        },
        onSuccess: (response, responseBody) => {
          self.onSuccessLogin(responseBody.data.token);
        },
        onFinish: () => {
          self.setAuthenticating(false);
        },
      });
    },
  }))
  .views((self) => ({
    get filled() {
      return Boolean(self.email.isDone() && self.password.isDone());
    },
  }));

export const PasswordRecoveryForm = types
  .model('PasswordRecoveryForm', {
    email: types.optional(EmailInputStore, () => EmailInputStore.create()),
    error: types.maybeNull(types.string),
    completed: false,
  })
  .actions((self) => ({
    setEmail(email) {
      self.email = email;
      self.error = null;
    },
    setError(message) {
      self.error = message;
    },
    setLoading(value) {
      self.email.setDisabled(value);
    },
    submit(onSuccess, setError) {
      self.setLoading(true);
      requests.POST({
        url: `/m/api/v1/users/${self.email.value}/reset-password`,
        onFailure: (response, errors) => {
          if (setError) {
            setError(errors[1]);
          }
        },
        onSuccess: () => {
          onSuccess();
        },
        onFinish: () => self.setLoading(false),
      });

      self.completed = true;
    },
  }))
  .views((self) => ({
    get filled() {
      return self.email.isDone();
    },
  }));

export const PasswordUpdateForm = types
  .model('PasswordUpdateForm', {
    token: types.maybeNull(types.string),
    newPassword1: types.optional(PasswordInputStore, () =>
      PasswordInputStore.create({ label: 'New password', schema: {} })
    ),
    newPassword2: types.optional(PasswordInputStore, () =>
      PasswordInputStore.create({ label: 'Confirm new password' })
    ),
    error: types.maybeNull(types.string),
    saving: false,
  })
  .volatile(() => ({
    updateURLPath: () => {},
  }))
  .views((self) => ({
    get email() {
      if (self.token) {
        const parsed = jwtDecode(self.token);
        return parsed.sub;
      }
      return null;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.newPassword1.registerOnChangeHandler(self.onPassword1Change);
      self.newPassword2.addInputValidator(makePasswordEqualityValidator(self.newPassword1));
    },
    onPassword1Change() {
      if (self.newPassword2.value !== null) {
        self.newPassword2.validate();
      }
    },
    setToken(token) {
      self.token = token;
    },
    setUpdateURLPath(updateURLPath) {
      self.updateURLPath = updateURLPath;
    },
    setSaving(value) {
      self.saving = value;
      self.newPassword1.setDisabled(value);
      self.newPassword2.setDisabled(value);
    },
    setError(message) {
      self.error = message;
      self.setSaving(false);
    },
    submit() {
      self.setSaving(true);
      requests.POST({
        url: `/m/api/v1/users/${self.email}`,
        authToken: self.token,
        body: { password: self.newPassword1.value },
        onFailure: (response, errors) => {
          self.setError(errors[1]);
        },
        onSuccess: () => {
          self.updateURLPath(`/?action=${LoginActions['password-is-updated'].urlValue}`);
        },
      });
    },
  }));

export const SignUpForm = types
  .model('SignUpForm', {
    organizationName: types.optional(InputStore, () =>
      InputStore.create({ label: 'Organization', description: 'Short name of organization' })
    ),
    organizationDisplayName: types.optional(InputStore, () =>
      InputStore.create({
        label: 'Organization display name',
        description: 'Optional display name of Organization',
      })
    ),
    firstName: types.optional(InputStore, () => InputStore.create({ label: 'First name' })),
    lastName: types.optional(InputStore, () => InputStore.create({ label: 'Last name' })),
    email: types.optional(EmailInputStore, () => EmailInputStore.create({})),
    password: types.optional(PasswordInputStore, () => PasswordInputStore.create({})),

    // the process of creating an organization
    creating: false,
  })
  .actions((self) => ({
    validateOrganizationNameExistenceInTheSystem(value) {
      if (!value) {
        return false;
      }
      const newPromise = new Promise((resolve) => {
        const apiUrl = `/p/api/v1/organizations/check/${value}`;
        requests.GET({
          url: apiUrl,
          onFailure: (response, errors) => resolve(errors[0]),
          onSuccess: () => resolve(false),
        });
      });

      return newPromise;
    },
    afterCreate() {
      self.organizationName.addInputValidator(self.validateOrganizationNameExistenceInTheSystem);
    },
    signup(onSuccess) {
      self.setCreatingFlag(true);
      const body = {
        password: self.password.value,
        email: self.email.value,
        first_name: self.firstName.value,
        last_name: self.lastName.value,
        name: self.organizationName.value,
      };
      if (self.organizationDisplayName.value) {
        body.display_name = self.organizationDisplayName.value;
      }

      requests.POST({
        url: '/p/api/v1/organizations/',
        body: body,
        onSuccess: onSuccess,
        onFailure: (response, errors) => {
          if (errors.length > 1) {
            self.setOrganizationNameError(errors[1]);
          } else {
            self.setOrganizationNameError(errors[0]);
          }
        },
        onFinish: () => self.setCreatingFlag(false),
      });
    },
    setCreatingFlag(value) {
      self.creating = value;
      self.organizationName.setDisabled(value);
      self.organizationDisplayName.setDisabled(value);
      self.firstName.setDisabled(value);
      self.lastName.setDisabled(value);
      self.email.setDisabled(value);
      self.password.setDisabled(value);
    },
  }))
  .views((self) => ({
    get error() {
      if (self.organizationNameFormatError !== null) {
        return self.organizationNameFormatError;
      }
      return self.emailFormatError;
    },
    get filled() {
      return (
        self.email.isDone() &&
        self.password.isDone() &&
        self.firstName.isDone() &&
        self.lastName.isDone() &&
        self.organizationName.isDone()
      );
    },
  }));

export const OAuth2CallbackForm = types
  .model('OAuth2CallbackForm', {
    provider: types.maybeNull(types.string),
    code: types.maybeNull(types.string),
    notRegisteredYet: false,
    redirectUrl: '/',
    error: types.maybeNull(types.string),
    completed: false,
    agreement: types.maybeNull(UserAgreement),
  })
  .volatile(() => ({
    setToken: () => {},
    updateURLPath: () => {},
  }))
  .actions((self) => ({
    registerSetToken(handler) {
      self.setToken = handler;
    },
    setUpdateURLPath(updateURLPath) {
      self.updateURLPath = updateURLPath;
    },
    setError(msg) {
      self.error = msg;
    },
    markAsNotRegistered() {
      self.notRegisteredYet = true;
    },
    setAgreementInfo(responseBody, email) {
      if (responseBody) {
        const agreement = UserAgreement.create({
          text: responseBody.data.agreement,
          token: responseBody.data.token,
        });
        // reauthenticate on success
        agreement.registerOnSuccess((token) => {
          self.onSuccessLogin(token, email);
        });
        agreement.registerOnCancel(() => self.setAgreementInfo());
        self.agreement = agreement;
      } else {
        self.agreement = null;
      }
    },
    onSuccessLogin(token, email) {
      self.setToken(token, email);
      self.updateURLPath(self.redirectUrl || '/');
    },
    login() {
      requests.GET({
        url: `/m/api/v1/auth/oauth2/${self.provider}/callback?code=${self.code}`,
        onSuccess: (resp, responseBody) => {
          self.onSuccessLogin(responseBody.data.token);
        },
        onFailure: (resp, errors, response_data) => {
          if (resp.status === 428) {
            self.setAgreementInfo(response_data);
          } else {
            self.markAsNotRegistered();
          }
        },
      });
    },
  }));
