import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { LazyLog, ScrollFollow } from 'react-lazylog';

const JournalLogs = inject('store')(
  observer((props) => {
    const [logs, setLogs] = useState(undefined);
    const [cursor, setCursor] = useState(0);
    const [iteration, setIteration] = useState(0);

    const loadLogs = () => {
      props.store.TransportLayer.get({
        url: `/i/api/v1/journal`,
        query: {
          inherits: props.inherits,
          owner: props.owner,
          scan_field: 'std::types/Journal:1.order',
          size: 1000,
          scan_cursor: cursor,
        },

        onSuccess: (response, responseData) => {
          const newBatch = responseData.data.results.map((el) => el['std::log/Log:1'].text).join('\r\n');
          setLogs((prevLogs) => {
            if (prevLogs === undefined) {
              return newBatch;
            }
            if (!newBatch) {
              return prevLogs;
            }
            return `${prevLogs}\r\n${newBatch}`;
          });

          if (responseData.data.has_more) {
            setCursor(responseData.data.next_scan_cursor);
          } else if (props.watch) {
            // update cursor with a delay to do not flood the API
            setTimeout(() => {
              if (responseData.data.results.length) {
                setCursor((value) => value + responseData.data.results.length);
              } else {
                // a special flag that makes useEffect to re-run loadLogs even if cursor is not changed.
                setIteration((value) => (value + 1) % 10);
              }
            }, 1000);
          }
        },
      });
    };

    useEffect(loadLogs, [cursor, props.watch, iteration]);

    return (
      <div className="log-container">
        <ScrollFollow
          style={{ padding: '20px' }}
          startFollowing
          render={({ onScroll }) => (
            <>
              <div className="log-header">
                {props.title && (
                  <div className="log-title">
                    <strong>Title:</strong> {props.title}
                  </div>
                )}
                {Object.entries(props.stats).map(([label, value]) => (
                  <div key={label}>
                    <strong>{label}:</strong> {value}
                  </div>
                ))}
              </div>
              <LazyLog
                enableSearch
                text={logs || props.loadingPlaceholder}
                stream
                follow={props.watch}
                onScroll={onScroll}
                selectableLines
              />
            </>
          )}
        />
      </div>
    );
  })
);

JournalLogs.propTypes = {
  inherits: PropTypes.string.isRequired,
  owner: PropTypes.string.isRequired,
  loadingPlaceholder: PropTypes.string,
  title: PropTypes.string,
  watch: PropTypes.bool,
};

JournalLogs.defaultProps = {
  loadingPlaceholder: 'Loading logs...',
  watch: false,
};

export default JournalLogs;
