import React from 'react';
import PropTypes from 'prop-types';

import { UUID4 } from 'lib/uuid-utils';

import FormItem from 'components/forms/FormItem';

const Switcher = (props) => {
  const itemID = UUID4();
  return (
    <FormItem label={props.label} description={props.description}>
      <div className="btn-group switcher">
        <div className="btn-group-item">
          <input
            id={itemID}
            type="checkbox"
            className="check"
            onClick={props.onChange}
            // add dummy onChange handler to suppress warning at browser console
            onChange={() => {}}
            checked={props.checked}
            disabled={props.disabled}
          />
          {
            // eslint-disable-next-line jsx-a11y/label-has-associated-control
            <label className="btn" htmlFor={itemID} />
          }
        </div>
      </div>
    </FormItem>
  );
};

Switcher.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Switcher;
