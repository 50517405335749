import { types } from 'mobx-state-tree';
import { InputStore, PasswordInputStore, TagsInputStore } from 'components/forms/Input';

const HOST_APP_TYPE = 'std::Hosts:1';
const AUTH_HEADER = "'Authorization: Bearer '${ITLOOK_TOKEN}";

export const RegistrationMethods = {
  auto: 'auto',
  manual: 'manual',
};

export const AuthMethods = {
  creds: { label: 'Password authentication', value: 'creds' },
  pubkey: { label: 'Public key authentication', value: 'pubkey' },
};

export const KeyTypes = {
  ssh_key: 'SSH-Key',
  x509: 'X.509',
};

export default types
  .model('NewHost', {
    applicationID: types.maybeNull(types.string),
    applicationsNoSelect: false,

    registrationMethod: types.optional(
      types.enumeration('registrationMethod', Object.values(RegistrationMethods)),
      RegistrationMethods.auto
    ),

    hostName: types.optional(InputStore, () =>
      InputStore.create({
        label: 'Host name',
        required: true,
        onChangeHandlingDelay: 0,
      })
    ),
    hostIP: types.optional(InputStore, () =>
      InputStore.create({
        label: 'Host IP',
        required: true,
        onChangeHandlingDelay: 0,
      })
    ),

    description: types.optional(InputStore, () =>
      InputStore.create({
        label: 'Description',
        onChangeHandlingDelay: 0,
      })
    ),

    labels: types.optional(TagsInputStore, () =>
      TagsInputStore.create({
        label: 'Labels',
        placeholder: 'Add new label',
      })
    ),

    authMethodName: types.optional(types.enumeration('authMethod', Object.keys(AuthMethods)), AuthMethods.creds.value),
    username: types.optional(InputStore, () =>
      InputStore.create({
        label: 'Username',
        required: true,
      })
    ),
    password: types.optional(PasswordInputStore, () =>
      PasswordInputStore.create({
        label: 'Password',
        required: true,
      })
    ),

    keyType: types.optional(types.enumeration('keyType', Object.keys(KeyTypes)), 'ssh_key'),
    key: types.maybeNull(types.string),
    passphrase: types.optional(PasswordInputStore, () =>
      PasswordInputStore.create({
        label: 'Passphrase',
        description: 'An optional passphrase for the private key.',
      })
    ),

    downloader: types.optional(types.enumeration('downloader', ['curl', 'wget']), 'wget'),
    oneLiner: false,

    creating: false,
  })
  .volatile(() => ({
    instance: null,
  }))
  .views((self) => ({
    get applicationOptions() {
      if (self.instance && self.instance.Applications.loaded) {
        const apps = self.instance.Applications.items.filter((app) => app.applicationType === HOST_APP_TYPE);
        return apps.map((app) => [app.id, app.name]);
      }
      return null;
    },
    get authMethod() {
      return AuthMethods[self.authMethodName];
    },
    isReady() {
      let authDataIsFilled = false;
      if (self.authMethodName === AuthMethods.creds.value) {
        authDataIsFilled = self.username.isDone() && self.password.isDone();
      } else {
        authDataIsFilled = self.username.isDone() && self.keyType && self.key;
      }

      return (
        !self.creating &&
        self.applicationID &&
        self.hostName.isDone() &&
        self.hostIP.isDone() &&
        authDataIsFilled &&
        self.labels.isDone()
      );
    },
    get apiURL() {
      const appID = self.oneLiner ? self.applicationID : "'${ITLOOK_APP_ID}'";
      return `i/ws/rpc/${appID}/host.autoregister?format=base64&jq=.`;
    },

    get environmentVariables() {
      const envVars = [
        {
          variable: `ITLOOK_TOKEN='${self.instance.token}'`,
          description: 'ITLook system access token.',
        },
        {
          variable: `ITLOOK_APP_ID="${self.applicationID}"`,
          description: 'The ID of host application that will be used for host communication.',
          excludeIfOneLiner: true,
        },
      ];

      if (self.description.value) {
        envVars.push({
          variable: `ITLOOK_HOST_DESCRIPTION='${self.description.value}'`,
          description: 'The description for the host.',
        });
      }
      if (self.labels.items.length > 0) {
        const labelsStr = encodeURI(JSON.stringify(self.labels.items));
        envVars.push({
          variable: `ITLOOK_HOST_LABELS='${labelsStr}'`,
          description: 'The labels to assign to the host.',
        });
      }

      if (self.oneLiner) {
        return envVars
          .filter((envVar) => !envVar.excludeIfOneLiner)
          .map((envVar) => `export ${envVar.variable}`)
          .join('; ');
      }
      return envVars.map((envVar) => `# ${envVar.description}\nexport ${envVar.variable}`).join('\n\n');
    },

    get autoRegistrationCommand() {
      let script = '';
      if (self.environmentVariables) {
        script += self.environmentVariables + (self.oneLiner ? '; ' : '\n\n');
      }

      let origin;
      if (process.env.NODE_ENV === 'development') {
        origin = 'https://my.itlook.io';
      } else {
        origin = window.location.origin;
      }
      const delimiter = self.oneLiner ? ' ' : ' \\\n  ';

      if (self.downloader === 'curl') {
        script += [
          'curl',
          "-H 'Content-Type: application/json'",
          `-H ${AUTH_HEADER}`,
          '-X POST',
          "-d '{}'",
          `'${origin}/${self.apiURL}'`,
          '| base64 -d',
          '| bash',
        ].join(delimiter);
      } else {
        script += [
          'wget',
          '-O- -q',
          "--header 'Content-Type: application/json'",
          `--header ${AUTH_HEADER}`,
          "--post-data '{}'",
          `'${origin}/${self.apiURL}'`,
          '| base64 -d',
          '| bash',
        ].join(delimiter);
      }
      return script;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      // todo: add validation for IP
    },
    linkInstanceStore(store) {
      self.instance = store;
    },
    setDownloader(tool, e) {
      if (e !== undefined) {
        e.stopPropagation();
        e.preventDefault();
      }
      self.downloader = tool;
    },
    toggleOneLiner() {
      self.oneLiner = !self.oneLiner;
    },
    setRegistrationMethod(value) {
      self.registrationMethod = value;
    },
    setAuthMethod(value) {
      self.authMethodName = value;
    },
    setKeyType(value) {
      self.keyType = value;
    },
    setKey(value) {
      self.key = value;
      self.keyError = null;
    },
    onKeyInputFocusOut() {
      if (!self.key) {
        self.keyError = 'Private Key is required.';
      }
    },
    setApplicationID(value, applicationsNoSelect) {
      self.applicationID = value;
      if (applicationsNoSelect !== undefined) {
        self.applicationsNoSelect = applicationsNoSelect;
      }
    },
    setCreating(value) {
      self.creating = value;
      self.labels.setDisabled(value);
      self.hostName.setDisabled(value);
      self.hostIP.setDisabled(value);
      self.username.setDisabled(value);
      self.password.setDisabled(value);
      self.passphrase.setDisabled(value);
    },
    onSubmit(e, onSuccess) {
      e.stopPropagation();
      if (self.isReady()) {
        self.registerHost(onSuccess);
      }
    },
    registerHost(onSuccess) {
      const socket = self.instance.getSocket();
      socket.afterOpen(async () => {
        const app = socket.ensureAppConnection(self.applicationID);

        const accessOpts = {
          accessUser: self.username.value,
        };
        if (self.authMethodName === AuthMethods.creds.value) {
          accessOpts.accessPassword = self.password.value;
        } else {
          accessOpts.accessKeyType = self.keyType;
          accessOpts.accessKey = self.key;
          accessOpts.accessKeyPassphrase = self.passphrase.value;
        }

        const body = {
          hostname: self.hostName.value,
          description: self.description.value || '',
          labels: self.labels.items,
          ips: [self.hostIP.value],
          accessIP: self.hostIP.value,
          ...accessOpts,
        };

        self.setCreating(true);
        const result = await app.rpc('host.create', { host: body });
        self.handleCreateHost(result, onSuccess);
      });
    },
    handleCreateHost(result, onSuccess) {
      if (!result.ok) {
        // API  does not tell which field is wrong, it may or may be not a name error ;(
        self.nameError = result.message;
      } else {
        onSuccess(result.host['std::types/Root:1'].id);
      }
      self.setCreating(false);
    },
  }));
