import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import { ExistsOnlyForAdmin } from 'pages/NotFound';
import DropdownSelector from 'components/input/DropdownSelector';
import Input from 'components/forms/Input';
import LegacyInput from 'components/input/Input';
import { ContainerBox, PageHeader } from 'components/Page';
import { SidedPanel, LeftPanel, RightPanel } from 'components/layout/Panels';
import { UserRoles } from 'Constants';
import { UserInviteForm } from 'stores/Organization/UsersPage';

@withRouter
@inject('org')
@observer
class UserInvite extends React.Component {
  constructor(props) {
    super(props);
    this.form = UserInviteForm.create({});
    this.form.linkUsersStore(this.props.org.Users);
  }

  render() {
    const onInvitationSentSuccess = () => this.props.history.push('/organization/users');

    return (
      <ContainerBox>
        <PageHeader to="/organization/users" title="Users" subTitle="Invite" documentTitle="Invite user" />
        <SidedPanel>
          <LeftPanel>
            <form
              className="form-box"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Input key="email" store={this.form.email} />
              <LegacyInput
                key="firstName"
                inputType="text"
                label="First name"
                placeholder="First name"
                onChange={this.form.setFirstName}
                disabled={this.form.creating}
              />
              <LegacyInput
                key="lastName"
                inputType="text"
                label="Last name"
                placeholder="Last name"
                onChange={this.form.setLastName}
                disabled={this.form.creating}
              />
              <DropdownSelector
                label="Role"
                key="role"
                onSelect={this.form.setRole}
                selected={this.form.role}
                options={UserRoles}
              />
              <div className="message-placeholder">{this.form.errors}</div>
              <SidedPanel>
                <LeftPanel>
                  <Link className="btn btn-default btn-block" to="/organization/users">
                    Cancel
                  </Link>
                </LeftPanel>
                <RightPanel>
                  <button
                    type="button"
                    className="btn-primary btn-block"
                    onClick={() => this.form.sendInvitation(onInvitationSentSuccess)}
                    disabled={!this.form.filled || this.form.requesting}
                  >
                    Invite
                  </button>
                </RightPanel>
              </SidedPanel>
            </form>
          </LeftPanel>
        </SidedPanel>
      </ContainerBox>
    );
  }
}

export default () => (
  <ExistsOnlyForAdmin>
    <UserInvite />
  </ExistsOnlyForAdmin>
);
