import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Spinner } from 'components/Loader';
import { PageHeader, ContainerBox } from 'components/Page';

import {
  VerTableStore,
  ColumnRecordLink,
  ColumnCustom,
  ColumnLabels,
  ColumnModel,
  ColumnTimestamp,
  ColumnReferences,
} from 'components/table/TableS';
import { Table as TheNewTable } from 'components/table/Table';
import * as queryString from 'lib/query-string';

@withRouter
@inject('instance', 'profile', 'store')
@observer
export class SearchResultsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { query: null };
    this.tableStore = undefined;
  }

  componentDidMount() {
    const query = this.processURLParams();
    this.tableStore = VerTableStore.create({
      paginationDefaultRowsPerPage: 25,
      paginationRowsPerPageOptions: [25, 50, 100],
      selectable: false,
      query: query,
      columns: [
        ColumnModel.create(),
        ColumnRecordLink.create({ name: 'Name' }),
        ColumnCustom.create({ name: 'Description', opts: { grow: 2 } }).setRender((row) => {
          const host = row.data.get('std::host/Host:1');
          if (host) {
            return `Host ${host.get('accessUser')}@${host.get('accessIP')}`;
          }
          const inventory = row.data.get('std::types/Inventory:1');
          if (inventory) {
            return `${inventory.get('description') || ''}`;
          }
          const auditable = row.data.get('std::types/Auditable:1');
          if (auditable) {
            return `${auditable.get('message')}`;
          }
          return '';
        }),
        ColumnReferences.create({
          name: 'Related',
          keys: [
            'std::types/Auditable:1.executor',
            'std::types/Auditable:1.who',
            'std::types/Auditable:1.relatedTo',
            'std::types/Auditable:1.what',
          ],
        }),
        ColumnLabels.create({ name: 'Labels', key: 'std::types/Root:1.labels' }),
        ColumnTimestamp.create({ name: 'Updated', key: 'std::types/Versionable:1.updatedAt' }),
      ],
    });
    this.tableStore.setUp({ instance: this.props.instance, transport: this.props.store.TransportLayer });
  }

  componentDidUpdate() {
    this.processURLParams();
  }

  componentWillUnmount() {
    // reset query as soon as user moves from page with search results
    this.props.instance.globalSearch.setValue(null);
  }

  processURLParams = () => {
    const params = queryString.parse(this.props.location.search);
    if (
      params.q !== this.state.query &&
      (params.q !== null || this.state.query !== this.props.instance.globalSearch.defaultQuery)
    ) {
      const newQuery = params.q || this.props.instance.globalSearch.defaultQuery;
      this.setState({ query: newQuery });
      if (this.tableStore) {
        this.tableStore.setQuery(newQuery);
      }
      this.props.instance.globalSearch.setValue(newQuery);
      return newQuery;
    }
  };

  render() {
    return (
      <ContainerBox>
        <PageHeader title="Search results" note={this.state.query} />
        {!this.state.query && <Spinner />}
        {this.state.query && <TheNewTable store={this.tableStore} />}
      </ContainerBox>
    );
  }
}
