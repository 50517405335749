import React from 'react';
import { inject, observer } from 'mobx-react';
import { ContainerBox, PageHeader } from 'components/Page';

@inject('store')
@observer
export class Welcome extends React.Component {
  render() {
    return (
      <ContainerBox>
        <PageHeader to="/welcome" title="Welcome" />
        <span>Welcome to ITLook</span>
      </ContainerBox>
    );
  }
}
export default Welcome;
