import React, { useEffect, useState } from 'react';

import { inject, observer } from 'mobx-react';
import {
  ColumnCustom,
  ColumnInventoryRecordStatus,
  ColumnLabels,
  ColumnModel,
  ColumnRecordLink,
  ColumnTimestamp,
  loadRecodsWithStatusStats,
  VerTableStore,
} from 'components/table/TableS';
import { InstanceLink } from 'components/Nav';
import { Table } from 'components/table/Table';

const AgentHostsTab = inject(
  'store',
  'instance'
)(
  observer((props) => {
    const query = `inherits('std::host/Host:1') AND std::types/Root:1.app == '${props.record.id}'`;

    const [store] = useState(
      VerTableStore.create({
        paginationDefaultRowsPerPage: 15,
        paginationRowsPerPageOptions: [15, 25, 50, 100],
        selectable: false,
        query: query,
        columns: [
          ColumnModel.create({}),
          ColumnInventoryRecordStatus.create({ name: 'Status', opts: { width: '100px' } }),
          ColumnRecordLink.create({
            name: 'Name',
            key: 'std::types/Root:1.id',
            sortKey: 'std::types/Inventory:1.displayName',
          }),
          ColumnCustom.create({ name: 'Access' }).setRender((row) => {
            const host = row.data.get('std::host/Host:1');
            return `${host.get('accessUser')}@${host.get('accessIP')}`;
          }),
          ColumnLabels.create({ name: 'Labels', key: 'std::types/Root:1.labels' }),
          ColumnTimestamp.create({ name: 'Updated', key: 'std::types/Versionable:1.updatedAt' }),
        ],
      })
    );
    useEffect(() => {
      loadRecodsWithStatusStats(store);
      store.setUp({ instance: props.instance, transport: props.store.TransportLayer });
    }, []);

    return (
      <>
        <div className="title-container">
          <div className="title">
            <span />
          </div>
          <InstanceLink to={`/hosts/register?applicationID=${props.record.id}`} className="btn btn-primary">
            Register new host
          </InstanceLink>
        </div>
        <Table store={store} />
      </>
    );
  })
);

AgentHostsTab.suites = (record) => record.app_1 && record.app_1.applicationType === 'std::Hosts:1';
export default AgentHostsTab;
