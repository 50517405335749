import React from 'react';
import { observer } from 'mobx-react';

import TextEditor from 'components/input/TextEditor';

const K8sSpecTab = observer((props) => {
  const data = JSON.parse(props.record.data.get('std::k8s/Resource:1').get('spec'));
  return (
    <div className="form-box">
      <TextEditor syntax="json" value={JSON.stringify(data, null, 4)} readOnly />
    </div>
  );
});

K8sSpecTab.suites = (record) => record.data.has('std::k8s/Resource:1');

export default K8sSpecTab;
