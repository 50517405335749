import { types } from 'mobx-state-tree';

import * as path from 'path';

import { TagsInputStore, InputStore, FilePathInputStore } from 'components/forms/Input';
import TextEditor from 'components/input/TextEditor.jsx';

const SyntaxFromCommandMap = {
  python: 'python',
  sh: 'bash',
  bash: 'bash',
};

export default types
  .model('NewScript', {
    name: types.optional(FilePathInputStore, () =>
      FilePathInputStore.create({
        inputType: 'text',
        label: 'Name',
        placeholder: 'my-awesome-script.sh',
        autoFocus: true,
      })
    ),
    command: types.optional(InputStore, () =>
      InputStore.create({
        label: 'Executor (command)',
        placeholder: 'Enter an executable for the script',
        onChangeHandlingDelay: 0,
        autocompleteOptions: ['python3', 'bash'],
      })
    ),
    description: types.optional(InputStore, () =>
      InputStore.create({
        label: 'Description',
        placeholder: "For example, it can be something like 'The script that should make me happy'.",
      })
    ),
    userSelectedSyntax: types.maybeNull(types.string),
    syntaxFromFileType: types.maybeNull(types.string),
    syntaxFromCommand: types.maybeNull(types.string),

    labels: types.optional(TagsInputStore, () =>
      TagsInputStore.create({
        label: 'Labels',
        placeholder: 'Add new label',
      })
    ),
    code: types.optional(types.string, ''),
    creating: false,
    globalError: types.maybeNull(types.string),
    createdScriptID: types.maybeNull(types.string),
  })
  .volatile(() => ({
    instance: null,
  }))
  .views((self) => ({
    get applicationID() {
      return '11111111-1111-4111-8111-111111111111';
    },
    get syntax() {
      return self.userSelectedSyntax || self.syntaxFromCommand || self.syntaxFromFileType;
    },
    isReady() {
      return (
        !self.creating &&
        self.applicationID &&
        self.name.isDone() &&
        self.syntax &&
        self.command.isDone() &&
        self.code &&
        self.labels.isDone()
      );
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.name.registerOnFocusOutHandler(self.onScriptNameInputFocusOut);
      self.name.registerOnChangeHandler(self.onNameChange);
      self.command.registerOnChangeHandler(self.onCommandChange);
    },
    linkInstanceStore(store) {
      self.instance = store;
    },
    onNameChange(value) {
      if (self.userSelectedSyntax === null) {
        const fileType = path.extname(value).slice(1);
        const maybeSyntax = TextEditor.supportedSyntaxes.filter((syntax) => syntax.fileTypes.includes(fileType));
        if (maybeSyntax.length === 1) {
          self.syntaxFromFileType = maybeSyntax[0].name;
        } else {
          self.syntaxFromFileType = null;
        }
      }
    },
    onScriptNameInputFocusOut() {
      if (!self.name.value) {
        self.name.setError('Name of the script is required field.');
      }
    },
    setSyntax(syntax) {
      self.userSelectedSyntax = syntax;
    },
    setScriptDescription(value) {
      self.description = value;
    },
    onCommandChange(command) {
      let maybeSyntax = null;
      Object.entries(SyntaxFromCommandMap).some(([c, s]) => {
        if (command.startsWith(c)) {
          maybeSyntax = s;
        }
        return maybeSyntax !== null;
      });
      self.syntaxFromCommand = maybeSyntax;
    },
    // other
    setCode(code) {
      self.code = code;
    },
    setCreating(value) {
      self.creating = value;
      self.name.setDisabled(value);
      self.command.setDisabled(value);
      self.labels.setDisabled(value);
      self.description.setDisabled(value);
    },
    onSubmit(e, onSuccess) {
      e.stopPropagation();
      if (self.isReady()) {
        self.createScript(onSuccess);
      }
    },
    createScript(onSuccess) {
      self.globalError = null;

      self.setCreating(true);
      self.instance.TransportLayer.post({
        url: 'i/api/v1/scripts',
        body: {
          path: path.normalize(self.name.value),
          labels: self.labels.items,
          command: [self.command.value],
          syntax: self.syntax,
          code: self.code.split('\n'),
          config: '{}',
          description: self.description.value || '',
        },
        onFailure: (e, errors) => console.logs(errors),
        onSuccess: (response, responseData) => self.handleCreateScript({ response, responseData, onSuccess }),
      });
    },
    handleCreateScript({ response, responseData, onSuccess }) {
      if (response.status !== 200) {
        // api does not tell which field is wrong, it may or may be not a name error ;(
        self.globalError = responseData.errors[0];
      } else {
        self.createdScriptID = responseData.data['std::types/Root:1'].id;
        if (onSuccess) {
          onSuccess(self.createdScriptID, self.name.value);
        }
      }
      self.setCreating(false);
    },
  }));
