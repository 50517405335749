import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

import { sortObjects } from 'lib/sort-utils';

import { UserStates } from 'Constants';
import Input from 'components/input/Input';
import { Spinner } from 'components/Loader';
import Table, { TableRow, TableCell } from 'components/Table';
import UsersPageStore from 'stores/Organization/UsersPage';

const UserNameFilter = withRouter(
  observer((props) => {
    const onChange = (value) => {
      props.pageState.search(value);
      props.history.push(props.pageState.usersListURL);
    };
    return (
      <form className="form-box">
        <Input
          className="search"
          type="search"
          placeholder="Search"
          label="Search"
          enteredValue={props.pageState.query}
          onChange={onChange}
        />
      </form>
    );
  })
);

const UserStateFilter = withRouter(
  observer((props) => {
    const onFilterChange = (e) => {
      props.pageState.toggleFilter(e.target.id);
      props.history.push(props.pageState.usersListURL);
    };
    return (
      <form className="form-box">
        <div className="btn-group">
          <div className="btn-group-item">
            <input
              type="checkbox"
              className="check"
              name="active"
              id="active"
              defaultChecked={props.pageState.showActive}
              onChange={onFilterChange}
            />
            <label className="btn" htmlFor="active">
              Show Active
            </label>
          </div>
          <div className="btn-group-item">
            <input
              type="checkbox"
              className="check"
              name="suspended"
              id="suspended"
              defaultChecked={props.pageState.showSuspended}
              onChange={onFilterChange}
            />
            <label className="btn" htmlFor="suspended">
              Show Suspended
            </label>
          </div>
          <div className="btn-group-item">
            <input
              type="checkbox"
              className="check"
              name="invited"
              id="invited"
              defaultChecked={props.pageState.showInvited}
              onChange={onFilterChange}
            />
            <label className="btn" htmlFor="invited">
              Show Invited
            </label>
          </div>
        </div>
      </form>
    );
  })
);

const AdminButtons = inject('profile')(
  observer((props) => {
    if (!props.profile.user.isAdmin) {
      return null;
    }
    return (
      <form className="form-box-line">
        <div className="form-item">
          <div className="item-container">
            <Link to="/organization/users/create" className="btn btn-primary">
              Create
            </Link>
          </div>
        </div>
        <div className="form-item">
          <div className="item-container">
            <Link to="/organization/users/invite" className="btn btn-primary">
              Invite
            </Link>
          </div>
        </div>
      </form>
    );
  })
);

const HeaderControls = (props) => (
  <div className="filters-box">
    <UserNameFilter pageState={props.pageState} />
    <UserStateFilter pageState={props.pageState} />
    <AdminButtons />
  </div>
);

@withRouter
@observer
class UserRow extends React.Component {
  isUserInProperState = () => {
    return (
      (this.props.pageState.showActive && this.props.user.state === UserStates.ACTIVE) ||
      (this.props.pageState.showSuspended && this.props.user.state === UserStates.SUSPENDED) ||
      (this.props.pageState.showInvited && this.props.user.state === UserStates.INVITED)
    );
  };

  isUserNamePassesQuery = () => {
    if (this.props.pageState.query !== null) {
      const query = this.props.pageState.query.toLowerCase();
      const fullName = this.props.user.fullName.toLowerCase();
      return fullName.includes(query) || this.props.user.email.includes(query);
    }
    return true;
  };

  onClick = (e) => {
    e.preventDefault();
    this.props.pageState.setUserToShow(this.props.user.email);
    this.props.history.push(this.props.pageState.usersListURL);
  };

  render() {
    if (!this.isUserInProperState() || !this.isUserNamePassesQuery()) {
      return null;
    }

    return (
      <TableRow>
        <TableCell>
          <Link to="#" onClick={this.onClick}>
            {this.props.user.fullName}
          </Link>
        </TableCell>
        <TableCell>{this.props.user.email}</TableCell>
        <TableCell>{this.props.user.role}</TableCell>
        <TableCell>{this.props.user.state}</TableCell>
      </TableRow>
    );
  }
}

@withRouter
@inject('org')
@observer
class UsersTable extends React.Component {
  constructor(props) {
    super(props);
    this.headers = [
      { title: 'Full Name', value: 'fullName' },
      { value: 'email' },
      { value: 'role' },
      { value: 'state' },
    ];
    this.defaultSortFieldsOrder = this.headers.map((h) => h.value);
  }

  componentDidMount() {
    if (!this.props.org.Users.loading) {
      this.props.org.Users.load();
    }
  }

  onSortChange = (sortBy, sortBack) => {
    this.props.pageState.onSortChange(sortBy, sortBack);
    this.props.history.push(this.props.pageState.usersListURL);
  };

  render() {
    const sortedUsers = this.props.org.Users.list();
    if (sortedUsers.length === 0) {
      return <Spinner />;
    }
    sortedUsers.sort((userA, userB) =>
      sortObjects(userA, userB, this.props.pageState.sortBy, this.defaultSortFieldsOrder, this.props.pageState.sortBack)
    );

    return (
      <Table headers={this.headers} onSortChange={this.onSortChange}>
        {sortedUsers.map((u) => (
          <UserRow user={u} key={u.email} pageState={this.props.pageState} />
        ))}
      </Table>
    );
  }
}

@withRouter
@inject('profile')
@observer
export default class extends React.Component {
  constructor(props) {
    super(props);
    this.pageState = UsersPageStore.create({});
  }

  render() {
    if (!this.props.profile.isLogged) {
      return <Spinner />;
    }

    this.pageState.loadFromQuery(this.props.location.search);

    return (
      <>
        <HeaderControls pageState={this.pageState} />
        <UsersTable pageState={this.pageState} />
      </>
    );
  }
}
