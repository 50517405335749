import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';

import {
  ColumnModel,
  ColumnRecordLink,
  ColumnReferences,
  ColumnText,
  ColumnTimestamp,
  VerTableStore,
} from 'components/table/TableS';
import { Table } from 'components/table/Table';

const HostActivity = inject(
  'store',
  'profile',
  'instance'
)(
  observer((props) => {
    const query =
      "inherits('std::types/Auditable:1') " +
      "AND isNotSet('std::types/Versionable:1.deletedAt') " +
      `AND std::types/Auditable:1.what == '${props.host}'`;

    const [store] = useState(
      VerTableStore.create({
        paginationDefaultRowsPerPage: 10,
        paginationRowsPerPageOptions: [10, 25, 50, 100],
        selectable: false,
        query: query,
        columns: [
          ColumnModel.create(),
          ColumnRecordLink.create({ name: 'Activity', key: 'std::types/Root:1.id' }),
          ColumnText.create({ name: 'Description', key: 'std::types/Auditable:1.message', opts: { grow: 3 } }),
          ColumnReferences.create({ name: 'Related To', keys: ['std::types/Auditable:1.relatedTo'] }),
          ColumnReferences.create({ name: 'Agent', keys: ['std::types/Auditable:1.executor'] }),
          ColumnTimestamp.create({
            name: 'Updated At',
            key: 'std::types/Versionable:1.updatedAt',
          }),
        ],
      })
    );

    useEffect(() => {
      store.setUp({ instance: props.instance, transport: props.store.TransportLayer });
    }, []);

    return <Table store={store} />;
  })
);

const HostOrContainerOverviewTab = observer((props) => {
  return <HostActivity host={props.record.id} />;
});

HostOrContainerOverviewTab.suites = (record) =>
  record.data.has('std::host/Host:1') || record.data.has('std::host/Container:1');

export default HostOrContainerOverviewTab;
