import React from 'react';
import { observer } from 'mobx-react';

import * as ContentHeader from 'components/ContentHeader';
import { InstanceLink } from 'components/Nav';
import { Labels } from 'pages/Instances/RecordView/Header/CommonItems';

export default observer((props) => {
  if (!props.record.loaded || !props.record.fs_node_1.isLoaded) {
    return null;
  }
  let name = props.record.script_1.path;
  if (props.itIsScriptRunView) {
    name = <InstanceLink to={`/records/${props.record.id}`}>{name}</InstanceLink>;
  }

  return (
    <ContentHeader.Container>
      <ContentHeader.Column>
        <ContentHeader.Item>
          <span>Script Name:</span>
          {name}
        </ContentHeader.Item>
      </ContentHeader.Column>
      <ContentHeader.Column>
        <ContentHeader.Item>
          <span>Executor / Command:</span> {props.record.script_1.command}
        </ContentHeader.Item>
      </ContentHeader.Column>
      <ContentHeader.Column>
        <ContentHeader.Item>
          <Labels record={props.record} />
        </ContentHeader.Item>
      </ContentHeader.Column>
    </ContentHeader.Container>
  );
});
