import { getParentOfType, types } from 'mobx-state-tree';

import { InputStore } from 'components/forms/Input';
import { XTermSessionsManager, XTermSession } from 'stores/Terminal';

const onFileChange = async (files, session, sessionManager, setProgress) => {
  if (files.length === 0) {
    return;
  }
  const termAddon = session.getTerminalAddon();
  const reader = new FileReader();
  reader.onload = async () => {
    let path = '';
    const info = await termAddon.socket.send_socket_rpc('cwd');
    path = `${info.cwd}/${files[0].name}`;

    sessionManager.socket.afterOpen(async () => {
      const appConn = sessionManager.socket.ensureAppConnection(session.applicationId);

      const socketCommand = 'file:upload';
      const initArgs = {
        id: session.modelId,
        destination: path,
      };
      const fileSocket = await appConn.createSocket(socketCommand);
      await fileSocket.send_socket_rpc('init', initArgs);

      const encodeData = (data) => {
        let binary = '';
        const bytes = new Uint8Array(data);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        return btoa(binary);
      };

      const chunkSize = 63000;
      const size = reader.result.byteLength;
      let offset = 0;
      if (size > chunkSize) {
        do {
          // eslint-disable-next-line no-await-in-loop
          await fileSocket.send_stream(encodeData(reader.result.slice(offset, offset + chunkSize)));
          offset += chunkSize;
          setProgress((offset / size) * 100);
        } while (offset + chunkSize < size);
        await fileSocket.send_stream(encodeData(reader.result.slice(offset)));
      } else {
        await fileSocket.send_stream(encodeData(reader.result));
      }
      // send empty data as EOF
      await fileSocket.send_stream('');
      // At this point this uploads file in future it will be just waiting to upload it
      await fileSocket.send_socket_rpc('commit', {});
      setProgress(100);
    });
  };
  reader.readAsArrayBuffer(files[0]);
};

export default InputStore.named('FileUploadInputStore')
  .props({
    // show progress bar
    progress: types.optional(types.maybeNull(types.integer), null),
    // in ms
    delayBeforeResettingProgressAfterUpload: 1500,

    dragActive: false,
    // override parent's default values
    inputType: 'file',
  })
  .volatile(() => ({
    dragTarget: null,
  }))
  .views((self) => ({
    isInProgress() {
      return self.progress !== null && self.progress !== 100;
    },
    get inputStyle() {
      if (self.isInProgress()) {
        return { background: `linear-gradient(to right, #bed1c5 0%, #bed1c5 ${self.progress}%, #ffffff 0%)` };
      }
      return undefined;
    },
    get session() {
      return getParentOfType(self, XTermSession);
    },
    get sessionManager() {
      return getParentOfType(self, XTermSessionsManager);
    },
    get buttonLabel() {
      if (self.progress === null) {
        return 'Upload';
      }
      return `${self.progress}%`;
    },
  }))
  .volatile((self) => ({
    onChange: (_, e) => {
      self.currentFiles = e.target.files;
      e.preventDefault();
      if (e.target.files && e.target.files[0]) {
        self.handleFileChange(e.target.files);
      }
    },
  }))
  .actions((self) => ({
    onClick() {
      if (!self.isInProgress()) {
        // reset progress
        self.setProgress(null);
        if (self.timer) {
          clearTimeout(self.timer);
          self.timer = null;
        }
        self.inputRef.current.click();
      }
    },
    handleFileChange(files) {
      self.progress = 0;
      return onFileChange(files, self.session, self.sessionManager, self.setProgress);
    },
    // triggers when file is dropped
    handleDrop(e) {
      e.preventDefault();
      e.stopPropagation();
      self.dragActive = false;
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        self.handleFileChange(e.dataTransfer.files);
      }
    },
    onDragOver(e) {
      if (!self.isInProgress()) {
        self.dragTarget = e.target;
        self.dragActive = true;
      }
      e.stopPropagation();
      e.preventDefault();
    },
    onDragLeaveCapture(e) {
      e.stopPropagation();
      e.preventDefault();
      if (e.target === self.dragTarget) {
        self.dragActive = false;
      }
    },

    setProgress(value) {
      self.disabled = true;
      self.progress = value ? Math.ceil(value) : null;
      if (value === 100) {
        self.inputRef.current.value = null;
        // hide progress after 1.5 second
        self.timer = setTimeout(self.setProgress, self.delayBeforeResettingProgressAfterUpload);
      } else {
        self.disabled = value !== null;
      }
    },
  }));
