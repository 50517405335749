import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';

import Icon from 'components/Icon';
import XTerm from 'components/terminal/Xterm';

const getSessionTitle = (session) => {
  let title = `${session.accessUser}@${session.hostname}`;
  if (session.title) {
    title = ` ${title} (${session.title})`;
  }
  return title;
};

const CloseSessionModal = observer((props) => {
  const onCancel = () => {
    props.sessionStore.setToBeDeleted(null);
  };

  const onClose = () => {
    props.sessionStore.stopSession();
  };

  if (!props.sessionStore.toBeDeleted) {
    return null;
  }

  const session = props.sessionStore.sessions.get(props.sessionStore.toBeDeleted);

  return (
    <div className="modal">
      <div className="modal-box">
        <div className="modal-header">
          <div className="title">Close</div>
          <div className="action" onClick={onCancel}>
            <icon className="icon close-dark" />
          </div>
        </div>
        <div className="modal-body">
          <p>
            Your terminal session on host <b>{session.hostname}</b> will be closed.
          </p>
        </div>
        <div className="modal-footer">
          <button className="btn-default" type="button" onClick={onCancel}>
            Cancel
          </button>
          <button className="btn-primary" type="button" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
});

const SessionTabTitle = observer((props) => {
  const activeClass = props.sessionStore.activeSessionId === props.session.id ? '-active' : '';

  const onClose = () => {
    props.sessionStore.setToBeDeleted(props.session.id);
  };
  return (
    <div className={`terminal-tab${activeClass}`}>
      <div
        className="terminal-tab-name"
        title={props.session.hostname}
        onClick={() => {
          props.sessionStore.setActiveSession(props.session.id);
        }}
      >
        {getSessionTitle(props.session)}
      </div>
      <Icon className="terminal-close-session" onClick={onClose} />
      <CloseSessionModal sessionStore={props.sessionStore} />
    </div>
  );
});

const AdditionalSessionsList = observer((props) => {
  const [visible, setVisible] = useState(false);
  if (props.sessions.length === 0) {
    return null;
  }
  const items = props.sessions.map((session) => (
    <a
      onClick={() => {
        props.sessionStore.setActiveSession(session.id);
        setVisible(false);
      }}
    >
      {getSessionTitle(session)}
    </a>
  ));
  return (
    <>
      <Icon
        className="terminal-more-sessions"
        title="Show more sessions"
        onClick={() => {
          setVisible(true);
        }}
      />
      {visible && (
        <div className="hint">
          <div className="body">{items}</div>
        </div>
      )}
    </>
  );
});

const TerminalSessionsList = observer((props) => {
  const SESSIONS_LIST_SIZE = 4;
  const sessions = [];
  const additionalSessions = [];
  // TODO(e0ne): put active session into the 'sessions' list
  Array.from(props.sessionStore.sessions.values()).forEach((session) => {
    if (session.id === props.sessionStore.activeSessionId) {
      sessions.push(<SessionTabTitle key={session.id} session={session} sessionStore={props.sessionStore} />);
    } else {
      if (sessions.length < SESSIONS_LIST_SIZE) {
        sessions.push(<SessionTabTitle key={session.id} session={session} sessionStore={props.sessionStore} />);
      } else {
        additionalSessions.push(session);
      }
    }
  });

  return (
    <>
      {sessions}
      <div className="terminal-tab icon-box hint-container">
        <AdditionalSessionsList sessions={additionalSessions} sessionStore={props.sessionStore} />
      </div>
    </>
  );
});

export const TerminalsModal = inject('store')(
  observer((props) => {
    let activeSession;
    if (!props.store.XTermSessionsManager.activeSessionId) {
      activeSession = Array.from(props.store.XTermSessionsManager.sessions.values())[0];
      props.store.XTermSessionsManager.setActiveSession(activeSession.id);
    } else {
      activeSession = props.store.XTermSessionsManager.sessions.get(props.store.XTermSessionsManager.activeSessionId);
    }

    const record = activeSession.record;
    if (!record.loaded) {
      return null;
    }
    return (
      <div className="global-modal">
        <div className="terminal-grid">
          <div className="terminal-grid-header">
            <TerminalSessionsList sessionStore={props.store.XTermSessionsManager} />
            <div key="start-icon" className="terminal-tab icon-box" style={{ display: 'none' }}>
              <Icon className="terminal-new-session" title="Start new session" />
            </div>
            <div key="spacer" className="terminal-tab-spacer" />
            <div
              key="close-icon"
              className="terminal-tab icon-box"
              onClick={() => {
                props.onClose();
              }}
            >
              <Icon className="terminal-hide" title="Hide terminal window" />
            </div>
          </div>
          <div className="terminal-grid-body">
            <XTerm
              instance={activeSession.instanceObj}
              record={record}
              sessionManager={props.store.XTermSessionsManager}
            />
          </div>
        </div>
      </div>
    );
  })
);
