import React from 'react';
import PropTypes from 'prop-types';

const MainWrapper = (props) => {
  if (props.thin) {
    return <>{props.children}</>;
  }

  const additionalClassName = props.className ? ` ${props.className}` : '';

  return (
    <div onClick={props.onClick} className={`form-item${additionalClassName}`}>
      {props.children}
    </div>
  );
};

const FormItem = (props) => (
  <MainWrapper className={props.className} onClick={props.onClick} thin={false}>
    <div className="item-container">
      {(props.label || props.description) && (
        <div className="name-container">
          {props.label && <div className="name">{props.label}</div>}
          {props.description && <div className="help-text">{props.description}</div>}
        </div>
      )}
      {props.children}
    </div>
  </MainWrapper>
);

FormItem.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
};

export default FormItem;

export const FormItemForInput = (props) => (
  <MainWrapper {...props}>
    {props.label && (
      <label htmlFor={props.itemID}>
        {props.label}
        {props.required && <b />}
        {props.labelDescription !== undefined && <div className="description">{props.labelDescription}</div>}
      </label>
    )}
    {props.children}
    {props.description && <span className="help-text">{props.description}</span>}
    {/*
      if error is Null, it means that this form item is expected to have errors.
      Let's add empty span for that case, so the block has a constant height
      independently of correctness of current value (i.e. reserve a place for an
      error)
   */}
    {props.error !== undefined && <span className="help-text text-danger">{props.error}</span>}
  </MainWrapper>
);

FormItemForInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  itemID: PropTypes.string,
  thin: PropTypes.bool,
  required: PropTypes.bool,
  labelDescription: PropTypes.string,
  children: PropTypes.any,
  description: PropTypes.string,
  error: PropTypes.string,
  onClick: PropTypes.func,
};
