import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import ApplicationInfo from 'pages/Instances/RecordView/Header/ApplicationInfo';
import ContainerInfo from 'pages/Instances/RecordView/Header/ContainerInfo';
import HostInfo from 'pages/Instances/RecordView/Header/HostInfo';
import ScriptInfo from 'pages/Instances/RecordView/Header/ScriptInfo';
import TerminalSessionInfo from 'pages/Instances/RecordView/Header/TerminalSessionInfo';

export default observer((props) => {
  useEffect(() => {
    if (props.record.checkTheNeedForUpdates()) {
      props.record.update();
    }
  }, []);

  if (props.record.app_1) {
    return <ApplicationInfo record={props.record} />;
  }
  if (props.record.script_1) {
    return <ScriptInfo key={`script-${props.record.id}`} record={props.record} instance={props.instance} />;
  }
  if (props.record.script_run_1) {
    return (
      <ScriptInfo
        key={`script-run-${props.record.id}`}
        record={props.record.script_run_1.scriptObj}
        instance={props.instance}
        itIsScriptRunView
      />
    );
  }
  if (props.record.host_1) {
    return <HostInfo record={props.record} instance={props.instance} />;
  }
  if (props.record.container_1) {
    return <ContainerInfo record={props.record} instance={props.instance} />;
  }
  if (props.record.session_1) {
    return <TerminalSessionInfo record={props.record} instance={props.instance} />;
  }

  // record is not loaded yet or the type of record has nothing to display at header
  return null;
});
