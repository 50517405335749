import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';

import * as queryString from 'lib/query-string';

import Icon from 'components/Icon';
import Input from 'components/forms/Input';
import Agreement from 'pages/Auth/components/Agreement';
import PageLayout from 'pages/Auth/components/PageLayout';
import { LoginForm as LoginFormStore } from 'stores/Auth';
import { LoginActions } from 'Constants';

const OAuthProvider = inject('notifications')((props) => {
  const onClick = () => {
    if (process.env.DEMO_MODE) {
      props.notifications.warn(`Sorry ${props.name} OAuth2 provider is disabled in Demo mode.`);
    } else {
      window.location.href = props.link;
    }
  };
  return (
    <button type="button" title={`Use oauth ${props.name}`} className="btn btn-default" onClick={onClick}>
      <Icon className={props.name.toLowerCase()} />
      {props.name}
    </button>
  );
});

const LoginForm = observer((props) => {
  let oAuth2Query = '';
  let resetPasswordQuery = '';
  if (props.store.redirect_url) {
    const encodedRedirectUrl = encodeURIComponent(props.store.redirect_url);
    oAuth2Query = `?start_url=${encodedRedirectUrl}`;
    resetPasswordQuery = `?redirect_url=${encodedRedirectUrl}`;
  }

  useEffect(() => {
    props.store.password.registerOnEnterPressHandler(props.store.login);
  }, []);

  return (
    <form
      className="form-box"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <Input key="email" store={props.store.email} autoFocus />
      <Input key="password" store={props.store.password} onKeyDown={(e) => e.code === 'Enter' && props.store.login()} />
      <div className="message-placeholder text-danger">{props.store.error}</div>
      <button
        type="button"
        className="btn-primary btn-block"
        onClick={props.store.login}
        disabled={!props.store.filled}
      >
        Sign In
      </button>
      <hr />
      <div className="login-services-container">
        <OAuthProvider key="google" name="Google" link={`${process.env.OAUTH2_GOOGLE_URL}${oAuth2Query}`} />
        <OAuthProvider key="github" name="GitHub" link={`${process.env.OAUTH2_GITHUB_URL}${oAuth2Query}`} />
      </div>
      <div className="login-link-container">
        <Link to={`/reset-password${resetPasswordQuery}`}>Forgot password?</Link>
      </div>
    </form>
  );
});

@withRouter
@inject('store')
@observer
export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.form = LoginFormStore.create({});
    this.form.setUpdateURLPath(this.props.history.push);
    this.form.registerSetToken(this.props.store.Profile.setToken);
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    this.form.setRedirectURL(query.redirect_url);
    if (query.action && LoginActions.hasOwnProperty(query.action)) {
      const action = LoginActions[query.action];
      this.props.store.Notifications.notify(action.msg, action.notificationType);
    }
  }

  render() {
    if (this.form.agreement !== null) {
      return <Agreement store={this.form.agreement} />;
    }
    return (
      <PageLayout title="Sign in">
        <LoginForm store={this.form} />
      </PageLayout>
    );
  }
}
