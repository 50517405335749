import { types } from 'mobx-state-tree';

const ScriptEntity = types
  .model('ScriptEntity', {
    recordID: types.string,
    name: types.string,
  })
  .volatile(() => ({
    _record: null,
  }))
  .actions((self) => ({
    setRecord(record) {
      self._record = record;
    },
  }))
  .views((self) => ({
    get language() {
      if (self._record && self._record.loaded) {
        return self._record.script_1.syntax;
      }
      return '';
    },
    get author() {
      return '';
    },
    get updatedAt() {
      if (self._record && self._record.loaded) {
        return self._record.updatedAt;
      }
      return '';
    },
    get scriptType() {
      if (self._record && self._record.loaded) {
        const command = self._record.script_1.command[0];
        const commandWithoutCLIArguments = command.split(' ', 1)[0];
        const commandWithoutFullPath = commandWithoutCLIArguments.split('/').pop();
        return commandWithoutFullPath;
      }
      return null;
    },
  }));

const Dir = types
  .model('Dir', {
    absPath: types.string,
    scripts: types.array(ScriptEntity),
    dirs: types.array(types.string),
  })
  .actions((self) => ({
    saveDirsAndScripts(dirs, scripts) {
      self.dirs = dirs;
      self.scripts = scripts;
    },
  }));

export default types
  .model('ScriptsPage', {
    confirmDeleteForID: types.maybeNull(types.string),
    loaded: false,
    loading: false,
    currentDirPath: '/',
    sortByField: 'name',
    reverseOrder: false,
    tree: types.map(Dir),
  })
  .volatile(() => ({
    instance: null,
  }))
  .views((self) => ({
    get applicationID() {
      return '11111111-1111-4111-8111-111111111111';
    },
    isRootDir() {
      return self.currentDirPath === '/';
    },
    get parentDir() {
      if (!self.isRootDir()) {
        return `${self.currentDirPath.split('/').slice(0, -2).join('/')}/`;
      }
      return self.currentDirPath;
    },
    absPath(dirName) {
      return `${self.currentDirPath}${dirName}/`;
    },
  }))
  .actions((self) => ({
    linkInstanceStore(store) {
      self.instance = store;
    },
    fetchTreeForCurrentDir() {
      self.loading = true;

      if (!self.tree.has(self.currentDirPath)) {
        self.tree.set(self.currentDirPath, Dir.create({ absPath: self.currentDirPath }));
      }

      /* we should do triming probably in backend */
      const trimedCurDir =
        (self.currentDirPath.slice(-1) === '/' && self.currentDirPath.length > 1 && self.currentDirPath.slice(0, -1)) ||
        self.currentDirPath;

      self.instance.TransportLayer.post({
        url: '/i/api/v1/scripts/tree',
        body: {
          root: trimedCurDir,
          depth: 2,
        },
        onFailure: (e, errors) => console.log(errors),
        onSuccess: (response, responseData) => self.parseTree(self.tree.get(self.currentDirPath), responseData.data),
      });
    },
    parseTree(dir, socketResult) {
      self.loading = false;
      const dirs = Object.keys(socketResult.dirs);
      self.instance.InventoryRecords.getMultiByIDs(Object.values(socketResult.scripts));
      const scripts = Object.entries(socketResult.scripts).map(([scriptName, recordId]) => {
        const script = ScriptEntity.create({ name: scriptName, recordID: recordId });
        script.setRecord(self.instance.InventoryRecords.getById(recordId));
        return script;
      });
      dir.saveDirsAndScripts(dirs, scripts);

      Object.values(socketResult.dirs).forEach((dirSpec) => {
        if (!dirSpec.abs_path) {
          return;
        }
        const innerDir = Dir.create({ absPath: dirSpec.abs_path });
        self.parseTree(innerDir, dirSpec);
        self.tree.set(dirSpec.abs_path, innerDir);
      });
    },
    setCurrentDir(curDir) {
      self.currentDirPath = curDir;
      self.fetchTreeForCurrentDir();
    },
    setConfirmDeleteForID(recordID) {
      self.confirmDeleteForID = recordID;
    },
    deleteScript() {
      self.setConfirmDeleteForID(null);
    },
    sortBy(sortField, reverseOrder) {
      self.sortByField = sortField;
      self.reverseOrder = reverseOrder;
    },
  }));
