import React from 'react';
import { observer } from 'mobx-react';

import TextEditor from 'components/input/TextEditor';

export default observer((props) => (
  <div className="form-box">
    <TextEditor syntax="json" value={JSON.stringify(props.record.data, null, 4)} readOnly />
  </div>
));
