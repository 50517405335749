import React from 'react';

import { ContainerBox, PageHeader } from 'components/Page';
import { inject, observer } from 'mobx-react';

const NotFound = (props) => (
  <>
    <PageHeader title={props.title || 'Not found [404]'} />
    <ContainerBox>{props.message || 'Oops. We cannot find this page.'}</ContainerBox>
  </>
);

export const ExistsOnlyForAdmin = inject('profile')(
  observer((props) => {
    if (!props.profile.isLogged) {
      return null;
    }
    if (!props.profile.user.isAdmin) {
      return <NotFound />;
    }
    return props.children;
  })
);

export default NotFound;
