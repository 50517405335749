import React from 'react';

import copy from 'copy-to-clipboard';

import * as ContentHeader from 'components/ContentHeader';
import Icon from 'components/Icon';
import { InventorySearchLink } from 'components/InventoryLinks';
import { StatusToCls } from 'components/charts/StatusChart';

export const Labels = (props) => {
  if (props.record.root_1.labels.length === 0) {
    return null;
  }

  return (
    <ContentHeader.Item>
      <span>Label:</span>
      {props.record.root_1.labels.map((label) => (
        <InventorySearchLink
          key={label}
          query={`std::types/Root:1.labels=='${label}'`}
          className="btn btn-text btn-default btn-small btn-label"
        >
          {label}
        </InventorySearchLink>
      ))}
    </ContentHeader.Item>
  );
};

export const Description = (props) => (
  <ContentHeader.Item>
    <span>Description:</span> {props.description || 'n/a'}
  </ContentHeader.Item>
);

export const Status = (props) => {
  let description;
  if (props.record.inventory_1.statusDescription) {
    description = ` - ${props.record.inventory_1.statusDescription}`;
  }
  return (
    <ContentHeader.Item>
      <span>Status:</span>
      <b className={`text-${StatusToCls[props.record.inventory_1.status]}`}>{props.record.inventory_1.status}</b>
      {description}
    </ContentHeader.Item>
  );
};

export const ID = (props) => {
  return (
    <ContentHeader.Item>
      <span>ID:</span> {props.record.id.slice(0, 8)}
      <button type="button" className="btn btn-icon btn-copy" onClick={() => copy(props.record.id)}>
        <Icon className="copy" />
      </button>
    </ContentHeader.Item>
  );
};
