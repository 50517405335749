import { types } from 'mobx-state-tree';
import { COLORS } from '../colors/Colors';

const defaultLoad = (transport) => {
  return new Promise((resolve, reject) => {
    const onReject = (response, errors) => {
      reject(
        ApiLoadError.create({
          code: response.status,
          status: response.statusText,
          details: errors,
        })
      );
    };

    transport.get({
      url: `/i/api/v1/record/dashboard`,
      onSuccess: (response, responseData) => resolve({ response: response, responseData: responseData }),
      onFailure: onReject,
      onCrash: onReject,
    });
  });
};

const defaultProcessor = ({ responseData }) => {
  const left = [];
  const right = [];

  const apiStat = responseData.data.api_stat;
  const actitvityStat = responseData.data.activity_stat;

  const addMissingModels = (allModels, batchModels) => {
    const batchKeys = new Set(batchModels.map((el) => el.key));
    const missingModels = Array.from(allModels.keys()).filter((key) => !batchKeys.has(key));
    missingModels.forEach((key) => {
      batchModels.push({ key: key, value: 0 });
    });
    return batchModels;
  };

  const apiModels = new Set(apiStat.models.map((el) => el.key));

  apiStat.data.forEach((el) =>
    left.push({
      timestamp: el.timestamp,
      key: el.key,
      value: el.value,
      fill: COLORS[0],
      stacked: addMissingModels(apiModels, el.models),
    })
  );

  const activityModels = new Set(actitvityStat.models.map((el) => el.key));
  actitvityStat.data.forEach((el) =>
    right.push({
      timestamp: el.timestamp,
      key: el.key,
      value: el.value,
      fill: COLORS[1],
      stacked: addMissingModels(activityModels, el.models),
    })
  );

  return {
    left: left,
    leftStackedKeys: apiStat.models.map((el) => `{left-${el.key})`),
    right: right,
    rightStackedKeys: actitvityStat.models.map((el) => `right-${el.key}`),
  };
};

export const ApiLoadError = types.model('TableLoadError', {
  code: types.integer,
  status: types.string,
  details: types.array(types.string),
});

const BarStackedItemData = types.model('BarStackedItemData', {
  key: types.string,
  value: types.integer,
});

const BarItem = types.model('BarItem', {
  timestamp: types.integer,
  key: types.string,
  value: types.integer,
  fill: types.optional(types.maybeNull(types.string), null),
  stacked: types.array(BarStackedItemData),
});

const BarData = types.model('BarData', {
  left: types.array(BarItem),
  leftStackedKeys: types.array(types.string),
  right: types.array(BarItem),
  rightStackedKeys: types.array(types.string),
});

export const BarModel = types
  .model('BarModel', {
    leftLabel: types.string,
    rightLabel: types.string,
    error: types.optional(types.maybeNull(ApiLoadError), null),
    loading: types.optional(types.boolean, true),
    data: types.optional(BarData, {}),
  })
  .views((self) => ({
    get dataOverTime() {
      const data = [];
      const keyToItem = {};
      self.data.left.forEach((el) => {
        data.push({
          date: el.key,
          left: el.value,
          labels: {
            left: self.leftLabel,
            right: self.rightLabel,
          },
          ...Object.fromEntries(el.stacked.map((stacked) => [`left-${stacked.key}`, stacked.value])),
        });
        keyToItem[el.key] = data.at(-1);
      });

      self.data.right.forEach((el) => {
        const item = keyToItem[el.key];
        item.right = el.value;
        el.stacked.forEach((stacked) => {
          item[`right-${stacked.key}`] = stacked.value;
        });
      });
      return data;
    },
  }))
  .volatile(() => ({
    loadData: defaultLoad,
    processor: defaultProcessor,
    transport: null,
  }))
  .actions((self) => ({
    setUp({ transport, processor, labelModifier }) {
      self.transport = transport;
      if (processor) {
        self.processor = processor;
      }
      if (labelModifier) {
        self.labelModifier = labelModifier;
      }
      self.load();
    },
    setLoading: (loading) => {
      self.loading = loading;
    },
    setError: (error) => {
      self.error = error;
    },
    setData: (data) => {
      self.data = data;
    },
    load: async () => {
      self.setLoading(true);
      try {
        self.setData(
          await self.processor({
            ...(await self.loadData(self.transport)),
          })
        );
      } catch (e) {
        console.log(e);
        self.setError(e);
      } finally {
        self.setLoading(false);
      }
    },
  }));
