import React from 'react';
import PropTypes from 'prop-types';

import NewInput, { InputStore } from 'components/forms/Input';

class Input extends React.PureComponent {
  constructor(props) {
    super(props);
    this.store = InputStore.create({
      formItemClassName: this.props.className,
      label: this.props.label,
      labelDescription: this.props.labelDescription,
      required: this.props.required,
      thin: this.props.thin,
      description: this.props.description,
      error: this.props.error,
      downloading: this.props.downloading,
      progress: this.props.progress,
      placeholder: this.props.placeholder,
      inputType: this.props.inputType,
      inputId: this.props.inputId,
      value: this.props.enteredValue || this.props.defaultValue,
      name: this.props.name,
      autocompleteOptions: this.props.autocompleteOptions,
      autoFocus: this.props.autoFocus,
      disabled: this.props.disabled,
      releaseFocusOnEnterPress: this.props.releaseFocusOnEnterPress,
      onChangeHandlingDelay: this.props.changeTimeout,
      onChange: this.props.onChange,
      onEnterPress: this.props.onEnterPress,
      onFocus: this.props.onFocus,
      onFocusOut: this.props.onFocusOut,
    });
    if (this.props.inputRef) {
      this.store.setInputRef(this.props.inputRef);
    }
    if (this.props.onChange) {
      this.store.registerOnChangeHandler(this.props.onChange);
    }
    if (this.props.onEnterPress) {
      this.store.registerOnEnterPressHandler(this.props.onEnterPress);
    }
    if (this.props.onFocus) {
      this.store.registerOnFocusHandler(this.props.onFocus);
    }
    if (this.props.onFocusOut) {
      this.store.registerOnFocusOutHandler(this.props.onFocusOut);
    }
  }

  render() {
    return <NewInput store={this.store}>{this.props.children}</NewInput>;
  }
}

Input.propTypes = {
  // additional class name for input component
  className: PropTypes.string,
  // the label of Input
  label: PropTypes.string,
  // whether the field is required or not (adds a start near label)
  required: PropTypes.bool,
  // if set input is wrapped with <div class="form-item"> element
  thin: PropTypes.bool,
  // description that is placed before input
  labelDescription: PropTypes.string,
  // description that is places after input
  description: PropTypes.string,
  // an error message
  error: PropTypes.string,
  // show progress indication
  downloading: PropTypes.bool,
  // show progress bar
  progress: PropTypes.number,
  // input placeholder
  placeholder: PropTypes.string,
  // type of input (defaults to 'text')
  inputType: PropTypes.string,
  // Id for input component
  inputId: PropTypes.string,
  // reference that can be used to change entered value programmatically
  inputRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  // default value
  defaultValue: PropTypes.string,
  // entered value
  enteredValue: PropTypes.string,
  // name of the control, which is submitted with the form data.
  name: PropTypes.string,
  // additional components that should be places after <input /> component
  children: PropTypes.any,
  autocompleteOptions: PropTypes.arrayOf(PropTypes.string),
  // whether the browser should focus on input component
  autoFocus: PropTypes.bool,
  // whether user can change input field
  disabled: PropTypes.bool,
  // whether trigger blur event (release focus from input) on EnterPress
  releaseFocusOnEnterPress: PropTypes.bool,
  // time to wait before triggering onChange handler
  changeTimeout: PropTypes.number,
  // events handlers
  onChange: PropTypes.func,
  onEnterPress: PropTypes.func,
  onFocus: PropTypes.func,
  onFocusOut: PropTypes.func,
};

Input.defaultProps = {
  changeTimeout: 150,
  inputType: 'text',
};

export default Input;
