import { types } from 'mobx-state-tree';

import { ApplicationStates } from 'Constants';
import { InputStore } from 'components/forms/Input';

export const AgentsListStore = types
  .model('AgentsPage', {
    loaded: types.optional(types.boolean, false),
    stateFilter: types.optional(
      types.map(types.boolean),
      Object.fromEntries(Object.values(ApplicationStates).map((s) => [s, true]))
    ),
    nameFilter: types.optional(InputStore, () =>
      InputStore.create({
        className: 'search',
        type: 'search',
        placeholder: 'Filter',
        label: 'Filter',
        autoFocus: true,
      })
    ),
    sortByField: 'name',
    reverseOrder: false,
    confirmDeleteForID: types.maybeNull(types.string),
  })
  .views((self) => ({
    get queryString() {
      const params = [];
      if (Array.from(self.stateFilter.values()).indexOf(false) !== -1) {
        Array.from(self.stateFilter.entries()).forEach(([key, value]) => {
          if (value) {
            params.push(`state=${key}`);
          }
        });
      }
      if (self.nameFilter.value !== '') {
        params.push(`name=${encodeURIComponent(self.nameFilter.value)}`);
      }
      return params.join('&');
    },
  }))
  .actions((self) => ({
    applyStateFilter(state, value) {
      self.stateFilter.set(state, value);
    },
    applyNameFilter(value) {
      self.nameFilter.setValue(value);
      // temporary hack needed to not rerender table twice
      self.loaded = true;
    },
    sortBy(sortField, reverseOrder) {
      self.sortByField = sortField;
      self.reverseOrder = reverseOrder;
    },
    setConfirmDeleteForID(applicationID) {
      self.confirmDeleteForID = applicationID;
    },
  }));
