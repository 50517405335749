import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { FormItemForInput } from 'components/forms/FormItem';

const AutoCompleteItem = (props) => {
  const item = props.selected ? <b>{props.item}</b> : props.item;
  return <li onClick={props.onClick}>{item}</li>;
};

const AutoCompleteOptionsBlock = observer((props) => {
  if (!props.store.autocompleteOptions || !props.store.focused) {
    return null;
  }

  return (
    <div className="autocomplete-container">
      <div className="autocomplete">
        <ul className="autocomplete-results">
          {props.store.autocompleteOptions.map((item, index) => (
            <AutoCompleteItem
              key={item}
              item={item}
              selected={props.store.selectedAutocompleteItem === index}
              onClick={() => props.store.handleOnAutoCompleteItemClick(index)}
            />
          ))}
        </ul>
      </div>
    </div>
  );
});

export default observer((props) => {
  useEffect(() => {
    props.store.componentDidMount();
  }, []);

  return (
    <FormItemForInput
      label={props.store.formItemLabel}
      required={props.store.required}
      labelDescription={props.store.labelDescription}
      description={!props.store.doNotShowErrors ? props.store.description : undefined}
      error={!props.store.doNotShowDescription ? props.store.error : undefined}
      className={props.store.formItemClassName}
      thin={props.store.thin}
    >
      <div className="item-container">
        <input
          type={props.store.inputType}
          id={props.store.inputId}
          name={props.store.name}
          placeholder={props.store.finalInputPlaceholder}
          defaultValue={props.store.defaultValue}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={props.store.autoFocus !== undefined && props.store.autoFocus}
          disabled={props.store.disabled}
          onChange={props.store.handleOnChange}
          onKeyDown={props.store.handleOnKeyDown}
          className={props.store.finalInputClassName}
          style={props.store.inputStyle}
          ref={props.store.inputRef}
          onFocus={props.store.handleOnFocus}
          onBlur={props.store.handleOnFocusOut}
        />
        {props.store.showPlaceholderAfterInput && (
          <label htmlFor={props.inputId}>
            {props.store.label}
            {props.store.required && <b />}
            {props.store.labelDescription !== undefined && (
              <div className="description">{props.store.labelDescription}</div>
            )}
          </label>
        )}
        {props.children}
      </div>
      <AutoCompleteOptionsBlock store={props.store} />
    </FormItemForInput>
  );
});
